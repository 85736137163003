import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeMovementInputValue, clearFieldForNonBike, clearFieldForTaDa, clearFieldForTaDaForNonBike, GetBillType, GetDa, GetFillingStation, GetPaymentType, GetTa, handleImageDelete, handleListDelete, handleRowCreateforbikecar, handleRowCreateForNonBikeCar, hanleChangeInputforNonBikeCar, imageUpload, imageUploadNonbike, inputHandleConsumeMilage, tadaSubmitForNonBikeCar, tadaSubmitStausRemove } from "../../redux/actions/tada/TadaEntryAction";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component"
import SimpleModal from "./../SimpleModal";
import { showToast } from "../../../master/utils/ToastHelper";
import { getUserInfomation } from "../../redux/actions/Movement/MovementAction";
import { getEmployeData } from "../../../../app/modules/Auth/_redux/authCrud";

const TADAForNonBikeCar = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();
    const history = useHistory();

    const itemDataInputForNonBikeCarEntry = useSelector((state) => state.tada.itemDataInputForNonBikeCarEntry);
    console.log('non itemDataInputForNonBikeCarEntry :>> ', itemDataInputForNonBikeCarEntry);
    const tadabikecarDetails = useSelector((state) => state.tada.tadabikecarDetails);
    const tadaRow = useSelector((state) => state.tada.itemRow);
    console.log('non tadaRow', tadaRow)
    const daType = useSelector((state) => state.tada.ddda);
    const taType = useSelector((state) => state.tada.ddta);
    const paymentType = useSelector((state) => state.tada.ddPaymentType);
    const fillingStation = useSelector((state) => state.tada.ddFillingStation);
    const billType = useSelector((state) => state.tada.ddbillType);
    const input = useSelector((state) => state.billing.itemDataInput);
    const defaultUnit = useSelector((state) => state.billing.defaultUnit);
    const tadaStatus = useSelector((state) => state.tada.tadaSubmitStatus);
    const bikeCarRowSum = useSelector((state) => state.tada.bikeCarRowSum);
    const [disabled, setDisabled] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [enroll, setEnroll] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [itemDetailShow, setItemDetailShow] = useState(false);
    const [imageShow, setImageShow] = useState(null);
    const [image, setImage] = useState(null);
    const [values, setValues] = useState(initialFieldValues)
    const [selectCosttype, setSelectCostType] = useState(null);
    const [selectBilltype, setSelectBillType] = useState(null);

    useEffect(() => {
        if (tadaStatus) {
          dispatch(tadaSubmitStausRemove());
          setTimeout(() => { history.go(0) }, 3000);
        }
      }, [tadaStatus]);

    useEffect(() => {
        dispatch(GetBillType());
        dispatch(GetPaymentType());
        dispatch(GetFillingStation());

    }, [])


    const dispatch = useDispatch();
    useEffect(() => { }, []);

    const onSubmitLeave = () => {
        alert();
    };

    const inputChangeHandle = async (name, value) => {

        dispatch(hanleChangeInputforNonBikeCar(name, value != undefined ? value : ""));
    };
    const handleChange = ({ currentTarget: input }) => {
        dispatch(hanleChangeInputforNonBikeCar(input.name, input.value));
        setStartDate(input.value);
    };
    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };
    const handleModaleItem = (data) => {
        setItemDetailShow(true);
        setImageShow(data);
    }
    const changeMovementInput = (name, value) => {
        dispatch(changeMovementInputValue(name, value));
    }

    const tadaValidation = () => {
       if (startDate == null) {
            alert("Please select Date");
            return false;
        } else if (itemDataInputForNonBikeCarEntry.movementHour == "") {
            alert("Please Input movementHour ");
            return false;
        } else if (itemDataInputForNonBikeCarEntry.fromAddress == "") {
            alert("Please Input fromAddress ");
            return false;
        } else if (itemDataInputForNonBikeCarEntry.toAddress == "") {
            alert("Please Input toAddress ");
            return false;
        } else if (itemDataInputForNonBikeCarEntry.amount <= 0) {
            alert("Please Input amount ");
            return false;
        }
        else if (itemDataInputForNonBikeCarEntry.contactPerson == "") {
            alert("Please Input contactPerson ");
            return false;
        }
        else if (itemDataInputForNonBikeCarEntry.contactNumber == "") {
            alert("Please Input contactNumber ");
            return false;
        }
        else {
            return true;
        }
    }

    const onSubmit = async (e) => {
        const userInfo = await getEmployeData();

        if (tadaRow.length > 0) {
            setDisabled(true);
            dispatch(tadaSubmitForNonBikeCar(userInfo?.employee_ID, startDate, tadaRow));
        }
    };
    const handleAddData = () => {
        const validation = tadaValidation();
        if (validation) {
            dispatch(handleRowCreateForNonBikeCar(itemDataInputForNonBikeCarEntry));
            dispatch(hanleChangeInputforNonBikeCar('attachment', null));
            dispatch(hanleChangeInputforNonBikeCar('filename', null));
            dispatch(clearFieldForNonBike())
            setValue('paymentType', '')
            setValue('vehicleType', '')
            setValue('billType', '')
            setValue('fuelName', '')
            setValue('cngStationName', '')
            setValue('costName', '')
            setValue('paymentName', '')
        }
    }
    // const fuelType = [
    //     { value: "1", label: "Octane" },
    //     { value: "2", label: "Octane" },
    // ];
    // const CostType = [
    //     { value: "T", label: "TA" },
    //     { value: "D", label: "DA" },
    //     { value: "C", label: "Other Cost" },
    // ];

    const handleDeleteItem = (row) => {
        dispatch(handleImageDelete(row.filename));
        dispatch(handleListDelete(row.rowID))
    };

    const defaulImageSrc = '/media/users/blank1.png'
    const initialFieldValues = {
        imageName: '',
        imageSrc: defaulImageSrc,
        imageFile: null
    }

    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            console.log('e.target.files[0])', e.target.files[0]);
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            console.log('reader :>> ', reader);
            reader.onload = x => {
                setValues(
                    {
                        ...values,
                        imageFile,
                        imageSrc: x.target.result
                    }
                )
                dispatch(hanleChangeInputforNonBikeCar('attachment', x.target.result));
            }

            reader.readAsDataURL(imageFile)
        }
        else {
            setValues(
                {
                    ...values,
                    imageFile: null,
                    imageSrc: defaulImageSrc
                }
            )
        }
        // setImageShow(e.target.files[0]);
        // setImage(e.target.files[0]);
        const formData = new FormData();
        formData.append("fileData", e.target.files[0]);
        dispatch(imageUploadNonbike(formData));
        //dispatch(hanleChangeInputforNonBikeCar('FormFile', e.target.files[0]));
        //dispatch(hanleChangeInputforNonBikeCar('fileName', e.target.files[0].name));
    }

    // const showPreview = e => {

    //     console.log('e.target.files[0]',  e.target.files[0]);

    //     const formData = new FormData();
    //     formData.append("FormFile",e.target.files[0])

    //    setImage(e.target.files[0]);
    // if (e.target.files && e.target.files[0]) {
    //     let imageFile = e.target.files[0];
    //     const reader = new FileReader();
    //     console.log('reader :>> ', reader);
    //     reader.onload = x => {
    //         setValues(
    //             {
    //                 ...values,
    //                 imageFile,
    //                 imageSrc: x.target.result
    //             }
    //         )
    //         console.log('reader.FileReader?.result :>> ', reader);
    //         dispatch(hanleChangeInputforNonBikeCar('File', x.target.result));
    //     }

    //     reader.readAsDataURL(imageFile)
    // }
    // else {
    //     setValues(
    //         {
    //             ...values,
    //             imageFile: null,
    //             imageSrc: defaulImageSrc

    //         }
    //     )
    // }


    // }

    const columns = [
        {
            name: "Date",
            selector: (row) => row.startDate,
            sortable: true,
        },

        {
            name: "From Address",
            selector: (row) => row.fromAddress,
            sortable: true,
        },
        {
            name: "Movement Address",
            selector: (row) => row.toAddress,
            sortable: true,
        },
        {
            name: "Movement D(Hour)",
            selector: (row) => row.movementHour,
            sortable: true,
        },

        {
            name: "Bill Type",
            selector: (row) => row.billName,
            sortable: true,
        },
        {
            name: "Cost Type",
            selector: (row) => row.costName,
            sortable: true,
        },
        {
            name: "Payment Type",
            selector: (row) => row.paymentName,
            sortable: true,
        },
        {
            name: "Amount",
            selector: (row) => row.amount,
            sortable: true,
        },
        {
            name: "Contact Person",
            selector: (row) => row.contactPerson,
            sortable: true,
        },
        {
            name: "Phone",
            selector: (row) => row.contactNumber,
            sortable: true,
        },
        {
            name: "Remarks",
            selector: (row) => row.remarks,
            sortable: true,
        },
        // {
        //     name: "FileName",
        //     selector: (row) => row.filename,
        //     sortable: true,
        // },
        {
            name: "Attachment",

            cell: (row) =>
                <img width={50} height={50} src={row.attachment} onClick={() => handleModaleItem(row.attachment)} />
        },

        {
            name: "Action",
            // cell: row => <button className='btn btn-sm btn-primary' onClick={() => alert(row.id)} >Edit</button>
            cell: row => <a href onClick={() => handleDeleteItem(row)} title="Delete" >
                <i className="fas fa-trash-alt editIcon item-list-icon ml-4 text-danger"></i> </a>
        },
    ];


    return (
        <>
            <div className="card card-custom mb-2 pl-5 pr-5  card-top-border p-1  ">
                <div className="row">
                    <div className="col-lg-10 m-3 ">
                        <h3>TA DA Entry(For NON Bike/Car)</h3>
                    </div>
                    {/* <div className="col-lg-12">
                        <button type="button" className="btn amount float-right">
                            Total Amount <span className="badge badge-light">{bikeCarRowSum}</span>
                        </button>
                    </div> */}
                </div>
                <hr></hr>

                <form
                    className="form form-label-right  mb-0 "
                    onSubmit={handleSubmit(onSubmit)}
                    method="post"
                    encType="multipart/form-data"
                    autoComplete="off"
                >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="form-group row ">
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Date<span className="star">*</span></label>
                                        <Form.Control
                                            type="date"
                                            placeholder="Enter Deadweight"
                                            name="startDate"
                                            value={startDate}
                                            className="form-control reffcss"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Movement(Hour)<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="text"
                                            placeholder="Type movementHour"
                                            name="movementHour"
                                            //value={itemDataInputForNonBikeCarEntry?.movementHour}
                                            onChange={(e) => {
                                                inputChangeHandle("movementHour", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">From Address<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="text"
                                            placeholder="Type From Address"
                                            name="fromAddress"
                                            value={itemDataInputForNonBikeCarEntry?.fromAddress}
                                            onChange={(e) => {
                                                inputChangeHandle("fromAddress", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Movemrnt Address<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="text"
                                            placeholder="Movemrnt Address"
                                            name="toAddress"
                                            value={itemDataInputForNonBikeCarEntry?.toAddress}
                                            onChange={(e) => {
                                                inputChangeHandle("toAddress", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Contact Person<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="text"
                                            placeholder="Type Name"
                                            name="contactPerson"
                                            onChange={(e) => {
                                                inputChangeHandle("contactPerson", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Phone<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="number"
                                            placeholder="Type Number"
                                            name="contactNumber"
                                            onChange={(e) => {
                                                inputChangeHandle("contactNumber", e.target.value);
                                            }}
                                            setValue={setValue}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <hr />
                            </div>
                            <div className="row bg-secondary p-3">
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Bill Type<span className="star">*</span></label>
                                        <RHFInput
                                            className="formSelect pt-0 "
                                            as={<Select options={billType} />}
                                            rules={{ required: false }}
                                            name="billType"
                                            register={register}
                                            placeholder="Select Cost type"
                                            onChange={(option) => {
                                                setSelectBillType(option.value)
                                                dispatch(GetTa(option.value));
                                                inputChangeHandle("billName", option?.label);
                                                setValue("vehicleType", "");
                                                setValue("paymentType", "");
                                                setValue("cngStation", "");
                                                setValue("fuelType", "");
                                                setValue("amount", "");
                                                inputChangeHandle('amount', 0);

                                            }}
                                            setValue={setValue}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Cost Type</label>
                                        <RHFInput
                                            className="formSelect pt-0"
                                            as={<Select options={taType} />}
                                            rules={{ required: false }}
                                            name="vehicleType"
                                            register={register}
                                            placeholder="Select Vehicle type"
                                            isClearable
                                            onChange={(option) => {
                                                setSelectCostType(option.value)
                                                inputChangeHandle('vehicleType', option?.value);
                                                inputChangeHandle("costName", option?.label);

                                            }}
                                            setValue={setValue}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Payment Type</label>
                                        <RHFInput
                                            className="formSelect pt-0 "
                                            as={<Select options={paymentType} />}
                                            rules={{ required: false }}
                                            name="paymentType"
                                            register={register}
                                            placeholder="Select Payment type"
                                            isClearable
                                            onChange={(option) => {
                                                inputChangeHandle("paymentType", option?.value);
                                                inputChangeHandle("paymentName", option?.label);

                                            }}
                                            setValue={setValue}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Amount<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="number"
                                            placeholder="Amount"
                                            name="amount"
                                            value={itemDataInputForNonBikeCarEntry?.amount}
                                            onChange={(e) => {
                                                inputChangeHandle("amount", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Attachment</label>
                                        {
                                            <Form.Control
                                                type="file"
                                                placeholder="Type Attachment"
                                                className="form-control forimageinput"
                                                name="attachment"
                                                accept="image/*"
                                                onChange={(e) => showPreview(e)}
                                            />
                                        }
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Remarks</label>
                                        <Form.Control as="textarea" rows="2"
                                            autocomplete="off"
                                            type="textarea"
                                            placeholder="Type Remarks"
                                            name="remarks"
                                            onChange={(e) => {
                                                inputChangeHandle("remarks", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <>
                                <div className="col-lg-12 mt-5 ml-4">
                                    <button type="submit"
                                        disabled={disabled}
                                        className="btn btn-primary btn-sm float-right text-center custome-addnew-btn item-attach mb-1 mr-4 ">
                                        Submit
                                    </button>
                                </div>
                            </>

                        </div>
                    </div>
                </form>
                <div>
                    <button
                        onClick={() => handleAddData()}
                        className="btn btn-primary  btn-sm float-right text-center custome-addnew-btn item-add mb-5 mr-4">
                        Add
                    </button>
                </div>
            </div>

            <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
                <DataTable
                    columns={columns}
                    data={tadaRow}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='450px'
                    selectableRowsHighlight
                    highlightOnHover
                />
                <SimpleModal
                    size="xl"
                    show={itemDetailShow}
                    handleClose={() => setItemDetailShow(false)}
                    handleShow={() => setItemDetailShow(true)}
                    modalTitle={"Image Preview"}
                >
                    <img src={imageShow} width='100%' height='100%' />
                </SimpleModal>
            </div>
        </>
    );
};
export default TADAForNonBikeCar;
