import Axios from "axios";
import * as Types from "../types/Types";


  export const getSection = () => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data;
      responseList.status = false;
    });
    dispatch({ type: Types.GET_SECTION_NAME, payload: responseList });   
  };

  export const getSectionShift = () => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data;
      responseList.status = false;
    });
    dispatch({ type: Types.GET_SECTION_SHIFT, payload: responseList });   
  };

  export const getRemoteAttendanceEmployee = (fakeData) => async (dispatch) => {

    console.log('fakeData', fakeData);
    let responseList = {
      isLoading: true,
      data: fakeData,
      status: true,
    };
   
    // let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
    // await Axios.get(url).then((res) => {
    //   responseList.data = fakeData
    //   responseList.status = false;
    // });
    dispatch({ type: Types.GET_REMOTE_ATTENDANCE_EMPLOYEE, payload: responseList });   
  };
  export const getAttendanceStatus = (fakeData) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: fakeData,
      status: true,
    };
   
    // let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
    // await Axios.get(url).then((res) => {
    //   responseList.data = fakeData
    //   responseList.status = false;
    // });
    dispatch({ type: Types.GET_REMOTE_ATTENDANCE_STATUS, payload: responseList });   
  };

  export const checkboxStatusChange = (item,index) => async (dispatch) => {
    let data={
      item:item,
      index:index
    }
    let responseList = {
      isLoading: true,
      data: data,
      status: true,
    };
    dispatch({ type: Types.REMOTE_CHECKBOX_STATUS, payload: responseList });   
  };
  export const allCheckBoxSelect = () => async (dispatch) => {
     dispatch({ type: Types.REMOTE_CHECKBOX_ALL, payload: null });   
  };