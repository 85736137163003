import React from "react";
import TADABikeCarEntry from "./TADABikeCarEntry";


const TADABikeCarEntryContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <TADABikeCarEntry />
      </div>
    </div>
  );
};
export default TADABikeCarEntryContainer;
