import React from "react";
import TadaApprovedForSuperviser from "./TadaApprovedForSuperviser";

const TadaApprovedForSuperviserContainer = () => {
  return (
    <div className="container-fluid">
        <TadaApprovedForSuperviser />
    </div>
  );
};
export default TadaApprovedForSuperviserContainer;
