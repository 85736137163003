import React from 'react'

const DemoTable = () => {
    return (


        <table className="table table-head-custom borderTableims" >
            <thead>
                <tr>
                   
                </tr>
            </thead>
            <tbody>
            <tr>
                <th rowspan="2" className='text-center'>DATE</th>
                <th colspan="2" className='text-center'>PARTICULAR</th>
                <th rowspan="2" className='text-center'>FAIR</th>
                <th rowspan="2" className='text-center'>AMOUNT</th>
                <th rowspan="2" className='text-center'>REMARKS</th>
            </tr>
            <tr>
                <th className='text-center'>FROM</th>
                <th className='text-center'>TO</th>
            </tr>
            <tr>
            <th rowspan="6" className='text-center datecus'>7-NOV-21</th>
            <td>Chankhar Pool</td>
            <td>Chankhar Pool</td>
            <td>Chankhar Pool</td>
            <td>300</td>
            <td rowspan="6"></td>
            </tr>
            <tr>
            <td>Chankhar Pool</td>
            <td>Chankhar Pool</td>
            <td>Bike Rent</td>
            <td>400</td>
            
            </tr>
            <tr>
            <td>Chankhar Pool</td>
            <td>Chankhar Pool</td>
            <td>Factory Boat</td>
            <td>500</td>
            </tr>
            <tr>
            <td>Chankhar Pool</td>
            <td>Chankhar Pool</td>
            <td>Rickshaw</td>
            <td>600</td>
            </tr>
            <tr>
            <td>Chankhar Pool</td>
            <td>Chankhar Pool</td>
            <td>Rickshaw</td>
            <td>700</td>
            </tr>
            <tr>
            <td>Chankhar Pool</td>
            <td>Chankhar Pool</td>
            <td>Rickshaw</td>
            <td>800</td>
            </tr>
            <tr>
                <th colspan="4">Total Expenses</th>
                <th>1000</th>
                <td></td>
            </tr>
            <tr>
                <th colspan="4">Advance Token</th>
                <th>1000</th>
                <td></td>
            </tr>
            <tr>
                <th colspan="4">Payable Amount</th>
                <th>1000</th>
                <td></td>
            </tr>
            
        </tbody>

        </table >


    )
}

export default DemoTable