import React from "react";
import FuelStationBillReport from "./FuelStationBillReport";

const FuelStationBillReportContainer = () => {
  return (
    <div className="container-fluid">
        <FuelStationBillReport />
    </div>
  );
};
export default FuelStationBillReportContainer;
