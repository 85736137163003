
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory} from "react-router-dom";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import SimpleModal from "../SimpleModal";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { getTaDAApprovedList, tadaDelete } from '../../redux/actions/tada/TadaEntryAction';
import { dateTimeConvertLongString } from "./DateHelper";
import LoadingSpinner from "../../../Billing/Components/LoadingSpinner";
import DataTable from "react-data-table-component";

const ListDetails = () => {

    const [selectedData, setSelectedData] = React.useState();
    const history = useHistory();

    const routeChange = () =>{ 
        let path = `/tada/TaDaUnApprovedList`; 
        history.push(path);
      }

    const dispatch = useDispatch();
    const tadaList = useSelector((state) => state.tada.tadaList);
    const isLoading = useSelector((state) => state.tada.tadaListLoading);


    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectTadaId, setSelectTadaId] = useState(null);

    const [itemDetailShow, setItemDetailShow] = useState(false);

    const componentRef = useRef();
    let ref = React.createRef();
    const inputField = React.useRef(null);


    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };

    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };

    const itemDetail = (item) => {

    };
    const handleDeleteItem = (item) => {
        dispatch(tadaDelete(item, selectTadaId, startDate));
    };


    const showReport = () => {
        //dispatch(getTaDAApprovedList(startDate));
    }

    const columns = [
        {
            name: "Enroll",
            selector: (row) => row.enroll,
            sortable: true,
        },
        {
            name: "Designation",
            selector: (row) => row.designation,
            sortable: true,
        },
        {
            name: "Department",
            selector: (row) => row.department,
            sortable: true,
        },
        {
            name: "Job Station",
            selector: (row) => row.jobStation,
            sortable: true,
        },
        {
            name: "Job Type ",
            selector: (row) => row.jobType,
            sortable: true,
        },
        {
            name: "From Address",
            selector: (row) => row.fromAddress,
            sortable: true,
        },
        {
            name: "Enroll",
            selector: (row) => row.enroll,
            sortable: true,
        },
        {
            name: "Designation",
            selector: (row) => row.designation,
            sortable: true,
        },
        {
            name: "Department",
            selector: (row) => row.department,
            sortable: true,
        },
        {
            name: "Job Station",
            selector: (row) => row.jobStation,
            sortable: true,
        },
        {
            name: "Job Type ",
            selector: (row) => row.jobType,
            sortable: true,
        },
        {
            name: "From Address",
            selector: (row) => row.fromAddress,
            sortable: true,
        },
        {
            name: "Enroll",
            selector: (row) => row.enroll,
            sortable: true,
        },
        {
            name: "Designation",
            selector: (row) => row.designation,
            sortable: true,
        },
        {
            name: "Department",
            selector: (row) => row.department,
            sortable: true,
        },
        {
            name: "Job Station",
            selector: (row) => row.jobStation,
            sortable: true,
        },
        {
            name: "Job Type ",
            selector: (row) => row.jobType,
            sortable: true,
        },
        {
            name: "From Address",
            selector: (row) => row.fromAddress,
            sortable: true,
        },
        {
            name: "Enroll",
            selector: (row) => row.enroll,
            sortable: true,
        },
        {
            name: "Designation",
            selector: (row) => row.designation,
            sortable: true,
        },
        {
            name: "Department",
            selector: (row) => row.department,
            sortable: true,
        },
        {
            name: "Job Station",
            selector: (row) => row.jobStation,
            sortable: true,
        },
        {
            name: "Job Type ",
            selector: (row) => row.jobType,
            sortable: true,
        },
        {
            name: "From Address",
            selector: (row) => row.fromAddress,
            sortable: true,
        },


    ];

    const handleChange1 = (state) => {
        setSelectedData(state.selectedRows);
        console.log(state);
      };


    return (
        <>
            <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
            <div className="row mt-5">
                    <div className="col-md-9">
                        <div className="mt-2 p-2">
                            <h3 className="mb-0 pb-0">TA DA Supervisor UnApproved List</h3>
                        </div>
                    </div>

                </div>
                <hr></hr>
            <div className="form-group row">
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="form-control reffcss"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    {/* <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="form-control reffcss"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div> */}
                    <div className="col mt-6 mb-5">
                        <Button size="sm" onClick={() => showReport()}>Show Report</Button>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={tadaList}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='450px'
                    selectableRowsHighlight
                    highlightOnHover
                    selectableRows
                    onSelectedRowsChange={handleChange1}
                />
            </div>

        </>
    );
};

export default ListDetails;
