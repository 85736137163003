
import moment from "moment/moment";
import { GetDdl } from "../../../../../Global/GlobalDdl";
import * as Types from "../../types/Types";
const initialState = {
  tadaSubmitStatus: false,
  isLoading: false,

  itemDataInputForBikeCarEntry: {
    startDate: "",
    vehicleType: "",
    cngStation:null,
    fuelType:null,
    cartype: null,
    timeFrom: " ",
    timeTo: " ",
    movementHour: " ",
    fromAddress: "Our House",
    toAddress: "Our House",
    startMilage: "0",
    endMilage: "0",
    consumeMilage: "0",
    daType: " ",
    persoanlMilage:"",
    PersonalMilageAmount:0,
    paymentType: "",
    remarks: " ",
    amount: "",
    contactPerson: " ",
    contactNumber: " ",
    FormFile: " ",
    fileName: " ",
  },
  itemDataInputForNonBikeCarEntry: {
    startDate: " ",
    empName:" ",
    vehicleType: "",
    cngStation: " ",
    fuelType: " ",
    cartype: " ",
    timeFrom: " ",
    timeTo: " ",
    movementHour: " ",
    fromAddress: " ",
    toAddress: " ",
    startMilage: " ",
    endMilage: " ",
    consumeMilage: " ",
    daType: " ",
    paymentType: "",
    remarks: " ",
    amount: " ",
    contactPerson: " ",
    contactNumber: " ",
    FormFile: " ",
    fileName: " ",
  },
  empInfo:{
    enroll:null,
    empName:""
  },

  submitData: {
    startDate: " ",
    timeFrom: " ",
    timeTo: " ",
    movementHour: " ",
    fromAddress: " ",
    toAddress: " ",
    startMilage: " ",
    endMilage: " ",
    consumeMilage: " ",
    paymentType: " ",
    cngStation: " ",
    fuelType: " ",
    attachment: " ",
    amount: " ",
    contactPerson: " ",
    contactNumber: " ",
  },

  itemRow: [],
  ddda: [],
  ddta: [],
  ddPaymentType: [],
  ddFillingStation: [],
  tadabikecarDetails: [],
  tadaNonbikecarDetails: [],
  tadaList: [],
  tadaUnapprovedListMonthly: [],
  tadaEndMilage: 0,
  tadaUnapprovedListDaily: [],
  tadaUnapprovedListforsupapproved: [],
  tadaapprovedListforsupervisor: [],
  approvedList:[],
  imgList:[],
  nameList:[],
  areaList:[],
  tadaUnapprovedListMonthlyForHR:[],
  tadaUnapprovedListDailyForHR:[],
  tadaUnapprovedListMonthlyForAudit:[],
  tadaUnapprovedListDailyForAudit:[],
  tadaUnapprovedListforauditapproved:[],
  tadaUnapprovedListforhrapproved:[],
  areaId :null,
  bikeCarRowSum:0,
  nonbikeCarRowSum:0,
  ddbillType:[],
  ddfuelType:[]
  
};
const TaDaReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
   
    case Types.CHANGE_TADA_INPUT_FOR_NON_BIKE_CAR:
      console.log('action.payload :>> ', action.payload);
      const itemDataInputForNonBikeCarEntry = {
        ...state.itemDataInputForNonBikeCarEntry,
      };
      itemDataInputForNonBikeCarEntry[action.payload.name] =
        action.payload.value;
      return {
        ...state,
        itemDataInputForNonBikeCarEntry,
      };
    case Types.CHANGE_TADA_BIKE_CAR_ENTRY:
      const itemDataInputForBikeCarEntry = {
        ...state.itemDataInputForBikeCarEntry,
      };
      console.log('itemDataInputForBikeCarEntry', itemDataInputForBikeCarEntry);
      itemDataInputForBikeCarEntry[action.payload.name] = action.payload.value;
      return {
        ...state,
        itemDataInputForBikeCarEntry,
      };
    case Types.CHANGE_HANDLE_SEARCH_INPUT:
      const SearchInput = {...state.empInfo};
      SearchInput.empName = action.payload.value
      return {
        ...state,
        empInfo:SearchInput,
      };
    case Types.CHANGE_TADA_APPROVED_AMOUNT:
      const tadaApprovedata = state.tadaUnapprovedListforsupapproved.slice();

      for (let i = 0; i < tadaApprovedata.length; i++) {
       if(tadaApprovedata[i].tadaId == action.payload.item.tadaId && tadaApprovedata[i].slNo == action.payload.item.slNo)
       tadaApprovedata[i].amount = action.payload.value
      }
      
      return {
        ...state,
        tadaUnapprovedListforsupapproved:tadaApprovedata
       
      };
      case Types.CHANGE_TADA_APPROVED_AMOUNT_FOR_HR:
        const tadaApprovedataforhr = state.tadaUnapprovedListforhrapproved.slice();
  
        for (let i = 0; i < tadaApprovedataforhr.length; i++) {
         if(tadaApprovedataforhr[i].tadaId == action.payload.item.tadaId && tadaApprovedataforhr[i].slNo == action.payload.item.slNo)
         tadaApprovedataforhr[i].supervisorAmount = action.payload.value
        }
        
        return {
          ...state,
          tadaUnapprovedListforhrapproved:tadaApprovedataforhr
         
        };
        case Types.CHANGE_TADA_APPROVED_AMOUNT_FOR_AUDIT:
          const tadaApprovedataforaudit = state.tadaUnapprovedListforauditapproved.slice();
    
          for (let i = 0; i < tadaApprovedataforaudit.length; i++) {
           if(tadaApprovedataforaudit[i].tadaId == action.payload.item.tadaId && tadaApprovedataforaudit[i].slNo == action.payload.item.slNo)
           tadaApprovedataforaudit[i].hrAmount = action.payload.value
          }
          
          return {
            ...state,
            tadaUnapprovedListforauditapproved:tadaApprovedataforaudit
           
          };
      case Types.CHANGE_MODAL_ENTRY:
        const itemDataInputForModal = {
          ...state.itemDataInputForModal,
        };
        itemDataInputForModal[action.payload.name] = action.payload.value;
        return {
          ...state,
          itemDataInputForModal,
        };
        case Types.CHANGE_TADA_HR_APPROVED_ENTRY:
          const itemDataInputForHRapproved = {
            ...state.itemDataInputForHRapproved,
          };
          itemDataInputForHRapproved[action.payload.name] = action.payload.value;
          return {
            ...state,
            itemDataInputForHRapproved,
            
          };
    case Types.CHANGE_CONSUME_MILAGE:
      const itemConsumeMilage = {
        ...state.itemDataInputForBikeCarEntry,
      };
      let startMilage = 0
      let endMilage = 0
      let consumeMilage = 0;
      if (action.payload.name == "endMilage") {
        endMilage = state.itemDataInputForBikeCarEntry.endMilage
      }
      if (action.payload.name == "startMilage") {
        startMilage = state.itemDataInputForBikeCarEntry.startMilage
      }
      itemConsumeMilage.consumeMilage = state.itemDataInputForBikeCarEntry.endMilage - state.itemDataInputForBikeCarEntry.startMilage
      return {
        ...state,
        itemDataInputForBikeCarEntry: itemConsumeMilage,
      };
    case Types.CHANGE_MOVEMENT_HOUR:
      const getTimeData = { ...state.itemDataInputForBikeCarEntry };
      if (action.payload.name == "timeTo") {
        var startTime = moment(getTimeData.timeFrom, "HH:mm a");
        var endTime = moment(action.payload.value, "HH:mm a");
        var duration = moment.duration(endTime.diff(startTime));
        var hours = parseInt(duration.asHours());
        var positiveTime = 0;
        if (hours < 0) {
          positiveTime = -hours;
        } else {
          positiveTime = hours;
        }
        getTimeData.movementHour = positiveTime;
      }
      return {
        ...state,
        itemDataInputForBikeCarEntry: getTimeData,
      };
    case Types.TADA_ROW_DELETE:
      console.log('state.itemRow', state.itemRow.slice());
      var deleteOp = state.itemRow.slice().filter((item) => 
     
      item.rowID !== action.payload
      );
      let sumRowDelSum = deleteOp.reduce((accumulator,object)=>{
        return accumulator + object.amount
       },0)
      return {
        ...state,
        itemRow: deleteOp,
       bikeCarRowSum:sumRowDelSum,
       bikeCarRowSum:NONsumRow
      };
    
    case Types.TADA_ROW_CREATE_FOR_BIKECAR:
      let bikecareArr = state.itemRow.slice();
       
      let bikecarD = {
        rowID: bikecareArr.length + 1,
        startDate: action.payload.startDate,
        vehicleType: action.payload.vehicleType,
        cngStation: action.payload.cngStation,
        fuelType: action.payload.fuelType,
        timeFrom: action.payload.timeFrom,
        timeTo: action.payload.timeTo,
        movementHour: action.payload.movementHour,
        fromAddress: action.payload.fromAddress,
        toAddress: action.payload.toAddress,
        startMilage: action.payload.startMilage,
        endMilage: action.payload.endMilage,
        consumeMilage: action.payload.consumeMilage,
        daType: action.payload.daType,
        paymentType: action.payload.paymentType,
        remarks: action.payload.remarks,
        amount: parseInt(action.payload.amount),
        persoanlMilage:action.payload.persoanlMilage,
        contactPerson: action.payload.contactPerson,
        contactNumber: action.payload.contactNumber,
        attachment: action.payload.attachment,
        filename: action.payload.filename,
        billType: action.payload.billType,
        vehicleName: action.payload.vehicleName,
        billName: action.payload.billName,
        costName: action.payload.costName,
        paymentName: action.payload.paymentName,
        cngStationName: action.payload.cngStationName,
        fuelName: action.payload.fuelName,
        employeeFullName: action.payload.employeeFullName,
      };
      bikecareArr.push(bikecarD);
      let sumRow = bikecareArr.reduce((accumulator,object)=>{
        return accumulator + object.amount
       },0)
      return {
        ...state,
        itemRow: bikecareArr,
        bikeCarRowSum:sumRow,
        
      };
      case Types.TADA_ROW_CREATE_FOR_NON_BIKECAR:
        let dtaArr = state.itemRow.slice();
        let rowD = {
          rowID: dtaArr.length + 1,
          startDate: action.payload.startDate,
          vehicleType: action.payload.vehicleType,
          cngStation: action.payload.cngStation,
          fuelType: action.payload.fuelType,
          timeFrom: action.payload.timeFrom,
          timeTo: action.payload.timeTo,
          movementHour: action.payload.movementHour,
          fromAddress: action.payload.fromAddress,
          toAddress: action.payload.toAddress,
          startMilage: action.payload.startMilage,
          endMilage: action.payload.endMilage,
          consumeMilage: action.payload.consumeMilage,
          daType: action.payload.daType,
          paymentType: action.payload.paymentType,
          remarks: action.payload.remarks,
          amount: parseInt(action.payload.amount),
          contactPerson: action.payload.contactPerson,
          contactNumber: action.payload.contactNumber,
          attachment: action.payload.attachment,
          filename: action.payload.filename,
          vehicleName: action.payload.vehicleName,
          billName: action.payload.billName,
          costName: action.payload.costName,
          paymentName: action.payload.paymentName,
          cngStationName: action.payload.cngStationName,
          fuelName: action.payload.fuelName,
          employeeFullName: action.payload.employeeFullName,
        };
        dtaArr.push(rowD);
        let NONsumRow = dtaArr.reduce((accumulator,object)=>{
          return accumulator + object.amount
         },0)
        return {
          ...state,
          itemRow: dtaArr,
          bikeCarRowSum:NONsumRow
        };
    case Types.GET_TADA_DATA:
      let submitData = { ...state.submitData };
      //submitData.intCustomer = action.payload.data.cusid;

      return {
        ...state,
        submitData,
      };

    // case Types.GET_DA:
    //   return {
    //     ...state,
    //     ddda: GetDdl(action.payload.data, "vehicleId", "vehicleName"),
    //   };
    case Types.GET_TA:
      console.log('action.payload.data :>> ', action.payload.data);
      return {
        ...state,
        ddta: GetDdl(action.payload.data, "vehicleId", "vehicleName"),
      };
    case Types.GET_PAYMENT_TYPE:
      return {
        ...state,
        ddPaymentType: GetDdl(action.payload.data, "id", "type"),
      };
      case Types.GET_BILL_TYPE:
       
        return {
          ...state,
          ddbillType: GetDdl(action.payload.data, "costHeadId", "name"),
        };
        case Types.GET_FUEL_TYPE:
        
        return {
          ...state,
          ddfuelType: GetDdl(action.payload.data, "id", "name"),
        };
      case Types.GET_AREA_LIST:
        return {
          ...state,
          areaList: GetDdl(action.payload.data, "areaId", "areaName"),
        };
    case Types.GET_FILLING_STATION:
      return {
        ...state,
        ddFillingStation: GetDdl(
          action.payload.data,
          "cngId",
          "cngStationName"
        ),
      };
    case Types.GET_TADA_LIST:
      console.log(' delete action.payload.data.image :>> ',  action.payload.data);
      return {
        ...state,
        tadaList: action.payload.data,
        tadaListLoading: action.payload.isLoading,
        imgList: action.payload.data.image,
      };
      case Types.TADA_BIKE_CAR_SUBMIT:
        console.log('bikecar action.payload.data :>> ', action.payload.data);
        return {
          ...state,
          submitLoading: false,
          tadaSubmitStatus: true,
        };
      case Types.SUBMIT_STATUS_REMOVE:
      return {
        ...state,
        tadaSubmitStatus: false,
      };
    case Types.GET_TADA_UNAPPROVED_LIST:
      return {
        ...state,
        tadaUnApprovedList: action.payload.data,
      };
    case Types.DELETE_TADA_LIST:
      return {
        ...state,
        // isLoading: action.payload,
      };
    case Types.GET_END_MILAGE:
      console.log('action.payload.data', action.payload.data);
      const endMilageData = {
        ...state.itemDataInputForBikeCarEntry,
      };
      endMilageData.startMilage = action.payload.data
      return {
        ...state,
        itemDataInputForBikeCarEntry: endMilageData,
      };
    case Types.GET_TADA_UNAPPROVED_LIST_MONTHLY:
      console.log('details - action.payload.data :>> ', action.payload.data);
      return {
        ...state,
        tadaUnapprovedListMonthly: action.payload.data,
      };
      case Types.GET_TADA_UNAPPROVED_LIST_MONTHLY_FOR_HR:
      console.log('HR monthly -action.payload.data :>> ', action.payload.data);
      return {
        ...state,
        tadaUnapprovedListMonthlyForHR: action.payload.data,
      };
      case Types.GET_TADA_UNAPPROVED_LIST_MONTHLY_FOR_AUDIT:
        return {
          ...state,
          tadaUnapprovedListMonthlyForAudit: action.payload.data,
        };
      case Types.GET_TADA_UNAPPROVED_LIST_DAILY_FOR_HR:
        return {
          ...state,
          tadaUnapprovedListDailyForHR: action.payload.data,
        };
    case Types.GET_TADA_UNAPPROVED_LIST_DAILY:
      console.log('daily - action.payload.data :>> ', action.payload.data);
      return {
        ...state,
        tadaUnapprovedListDaily: action.payload.data,
      };
      case Types.GET_TADA_UNAPPROVED_LIST_MONTHLY_FOR_AUDIT:
        return {
          ...state,
          tadaUnapprovedListMonthlyForAudit: action.payload.data,
        };
      case Types.GET_TADA_UNAPPROVED_LIST_DAILY_FOR_AUDIT:
        return {
          ...state,
          tadaUnapprovedListDailyForAudit: action.payload.data,
        };
    case Types.GET_TADA_UNAPPROVED_LIST_FOR_SUP_APPROVED:
     
      return {
        ...state,
        tadaUnapprovedListforsupapproved: action.payload.data,
      };
    case Types.GET_TADA_APPROVED_FOR_SUPERVISER:
      return {
        ...state,
      };
      case Types.GET_TADA_APPROVED_FOR_HR:
        return {
          ...state,
        };
      case Types.GET_NAME_LIST:
        console.log('name- action.payload.data :>> ', action.payload.data);
        return {
          ...state,
          nameList: action.payload.data,
        };
        case Types.GET_EMP_NAME_SET:
          console.log('action.payload', action.payload);
          const empInfo = {...state.empInfo};
          empInfo.empName = action.payload.empName;
          empInfo.enroll = action.payload.enroll
          return{
            ...state,
            empInfo,
            nameList:[],
          }
        case Types.CLEAR_TADA_LIST:
        return {
          ...state,
          itemRow: [],
          ddda: [],
          ddta: [],
          ddPaymentType: [],
          ddFillingStation: [],
          tadabikecarDetails: [],
          tadaNonbikecarDetails: [],
          tadaList: [],
          tadaUnapprovedListMonthly: [],
          tadaEndMilage: 0,
          tadaUnapprovedListDaily: [],
          tadaUnapprovedListforsupapproved: [],
          tadaapprovedListforsupervisor: [],
          approvedList:[],
          imgList:[],
          nameList:[],
         
        };
        case Types.CLEAR_FIELD_FOR_TADA:
          const changedvalue = {
            ...state.itemDataInputForBikeCarEntry,
          };
          changedvalue.vehicleType= ""
          changedvalue.cngStation= ""
          changedvalue.fuelType= null
          changedvalue.startMilage= "0"
          changedvalue.endMilage= "0"
          changedvalue.consumeMilage= "0"
          changedvalue.PersonalMilageAmount= 0
          changedvalue.paymentType=null
          // changedvalue.remarks= ""
          changedvalue.amount= ""
          changedvalue.billType= ""
          changedvalue.fuelName= ""
          changedvalue.cngStationName= ""
          changedvalue.costName= ""
          changedvalue.paymentName= ""
          changedvalue.persoanlMilage= ""
          return {
            ...state,
            itemDataInputForBikeCarEntry:changedvalue,
          };
          case Types.CLEAR_FIELD_FOR_NONBIKE:
            const changedvaluefornonbike = {
              ...state.itemDataInputForNonBikeCarEntry,
            };
            changedvaluefornonbike.vehicleType= ""
            // changedvaluefornonbike.fromAddress= ""
            // changedvaluefornonbike.toAddress= ""
            changedvaluefornonbike.amount= ""
            changedvaluefornonbike.billType= ""
            return {
              ...state,
              itemDataInputForNonBikeCarEntry:changedvaluefornonbike,
            };
            case Types.CLEAR_FIELD_FOR_NONBIKE_PUBLIC:
              const changedvaluefornonbikepublic = {
                ...state.itemDataInputForNonBikeCarEntry,
              };
              changedvaluefornonbikepublic.vehicleType= ""
              changedvaluefornonbikepublic.fromAddress= ""
              changedvaluefornonbikepublic.toAddress= ""
              changedvaluefornonbikepublic.amount= ""
              changedvaluefornonbikepublic.billType= ""
              changedvaluefornonbikepublic.paymentType= ""
              changedvaluefornonbikepublic.fuelName= ""
              changedvaluefornonbikepublic.cngStationName= ""
              changedvaluefornonbikepublic.costName= ""
              changedvaluefornonbikepublic.paymentName= ""
              return {
                ...state,
                itemDataInputForNonBikeCarEntry:changedvaluefornonbikepublic,
              };
             
        case Types.GET_TADA_UNAPPROVED_LIST_FOR_AUDIT_APPROVED:
     
      return {
        ...state,
        tadaUnapprovedListforauditapproved: action.payload.data,
      };
      case Types.GET_TADA_UNAPPROVED_LIST_FOR_HR_APPROVED:
     
      return {
        ...state,
        tadaUnapprovedListforhrapproved: action.payload.data,
      };
      case Types.GET_TADA_APPROVED_FOR_AUDIT:
        return {
          ...state,
        };


    default:
      break;
  }
  return newState;
};

export default TaDaReducer;
