import React from "react";
import TadaApprovedByAudit from "./TadaApprovedByAudit";

const TadaApprovedByAuditContainer = () => {
  return (
    <div className="container-fluid">
       <TadaApprovedByAudit />
    </div>
  );
};
export default TadaApprovedByAuditContainer;
