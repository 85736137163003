import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeMovementInputValue, clearFieldForTaDa, GetBillType, GetDa, GetEndMilage, GetFillingStation, GetFuelType, getNameList, GetPaymentType, GetTa, handleImageDelete, handleListDelete, handleRowCreateforbikecar, handleSearchInput, hanleChangeInputforbikecarentry, hanlesetInputforempname, imageUpload, inputHandleConsumeMilage, tadaSubmitForBikeCar, tadaSubmitStausRemove } from "../../redux/actions/tada/TadaEntryAction";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component"
import SimpleModal from "./../SimpleModal";
import { showToast } from "../../../master/utils/ToastHelper";
import { getEmployeData, getUserByToken } from "../../../../app/modules/Auth/_redux/authCrud";

const TADABikeCarEntryPublic = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();
    const history = useHistory();

    const itemDataInputForBikeCarEntry = useSelector((state) => state.tada.itemDataInputForBikeCarEntry);
    const tadaEndMilage = useSelector((state) => state.tada.tadaEndMilage);
    const tadabikecarDetails = useSelector((state) => state.tada.tadabikecarDetails);
    const tadaRow = useSelector((state) => state.tada.itemRow);
    const daType = useSelector((state) => state.tada.ddda);
    const taType = useSelector((state) => state.tada.ddta);
    const paymentType = useSelector((state) => state.tada.ddPaymentType);
    const fillingStation = useSelector((state) => state.tada.ddFillingStation);
    const input = useSelector((state) => state.billing.itemDataInput);
    const defaultUnit = useSelector((state) => state.billing.defaultUnit);
    const tadaStatus = useSelector((state) => state.tada.tadaSubmitStatus);
    const nameList = useSelector((state) => state.tada.nameList);
    const bikeCarRowSum = useSelector((state) => state.tada.bikeCarRowSum);
    const billType = useSelector((state) => state.tada.ddbillType);
    console.log('billType :>> ', billType);
    const fuelType = useSelector((state) => state.tada.ddfuelType);

    const [disabled, setDisabled] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [enroll, setEnroll] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [itemDetailShow, setItemDetailShow] = useState(false);
    const [imageShow, setImageShow] = useState(null);
    const [image, setImage] = useState(null);
    const [selectCosttype, setSelectCostType] = useState(null);
    const [selectBilltype, setSelectBillType] = useState(null);

    const [values, setValues] = useState(initialFieldValues);
    const empInfo = useSelector((state) => state.tada.empInfo);
    const [selectExpenseType, setSelectExpenseType] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);


    useEffect(() => {
        if (tadaStatus) {
            dispatch(tadaSubmitStausRemove());
            setTimeout(() => { history.go(0) }, 3000);
        }
    }, [tadaStatus]);

    useEffect(() => {
        dispatch(GetBillType());
        dispatch(GetFuelType());
        dispatch(GetPaymentType());
        dispatch(GetFillingStation());
        dispatch(GetEndMilage());
    }, [])


    const dispatch = useDispatch();
    useEffect(() => { }, []);

    const onSubmitLeave = () => {
        alert();
    };

    const inputChangeHandle = (name, value) => {
        dispatch(hanleChangeInputforbikecarentry(name, value != undefined ? value : ""));
    };
    const handleChange = ({ currentTarget: input }) => {
        dispatch(hanleChangeInputforbikecarentry(input.name, input.value));
        setStartDate(input.value);
    };
    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };
    const handleModaleItem = (data) => {
        setItemDetailShow(true);
        setImageShow(data);
    }
    const changeMovementInput = (name, value) => {
        dispatch(changeMovementInputValue(name, value));
    }

    const tadaValidation = () => {
        if (startDate == null) {
            alert("Please select Date");
            return false;
        } else if (itemDataInputForBikeCarEntry.timeFrom == null) {
            alert("Please Input startTime ");
            return false;
        } else if (itemDataInputForBikeCarEntry.timeTo == null) {
            alert("Please Input endTime ");
            return false;
        } else if (itemDataInputForBikeCarEntry.fromAddress == 0) {
            alert("Please Input fromAddress ");
            return false;
        } else if (itemDataInputForBikeCarEntry.toAddress == 0) {
            alert("Please Input toAddress ");
            return false;
        } else if (itemDataInputForBikeCarEntry.amount == 0) {
            alert("Please Input amount ");
            return false;
        }
        else if (itemDataInputForBikeCarEntry.contactPerson == 0) {
            alert("Please Input contactPerson ");
            return false;
        }
        else if (itemDataInputForBikeCarEntry.contactNumber == 0) {
            alert("Please Input contactNumber ");
            return false;
        }
        else {
            return true;
        }
    }

    const handleAddData = () => {

        const validation = tadaValidation();
        if (validation) {

            dispatch(handleRowCreateforbikecar(itemDataInputForBikeCarEntry));
            dispatch(hanleChangeInputforbikecarentry('attachment', null));
            dispatch(hanleChangeInputforbikecarentry('filename', null));
            dispatch(clearFieldForTaDa())
            setValue('paymentType', '')
            setValue('vehicleType', '')
            setValue('billType', '')
            setValue('cngStation', '')
            setValue('fuelType', '')
            setValue('fuelName', '')
            setValue('cngStationName', '')
            setValue('costName', '')
            setValue('paymentName', '')
            setValue('endMilage', '')

        }
    }


    const onSubmit = async () => {
        // const validation = tadaValidation();
        // const userInfo = await getEmployeData();

        if (tadaRow.length > 0) {
            setDisabled(true);
            dispatch(tadaSubmitForBikeCar(empInfo.enroll, startDate, tadaRow));
        }
    };

    const handleDeleteItem = (row) => {
        dispatch(handleImageDelete(row.filename));
        dispatch(handleListDelete(row.rowID))
    };

    //const defaulImageSrc = '/media/users/blank1.png'
    const initialFieldValues = {
        imageName: '',
        //imageSrc: defaulImageSrc,
        imageFile: null
    }

    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            console.log('e.target.files[0])', e.target.files[0]);
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            console.log('reader :>> ', reader);
            reader.onload = x => {
                setValues(
                    {
                        ...values,
                        imageFile,
                        imageSrc: x.target.result
                    }
                )
                dispatch(hanleChangeInputforbikecarentry('attachment', x.target.result));

            }

            reader.readAsDataURL(imageFile)
        }
        else {
            setValues(
                {
                    ...values,
                    imageFile: null,
                    //imageSrc: defaulImageSrc

                }
            )
        }

        const formData = new FormData();
        formData.append("fileData", e.target.files[0]);
        dispatch(imageUpload(formData));

    }
    const handleemplist = (item) => {
        dispatch(hanlesetInputforempname(item));
    };
    const handleChangeEmpName = (name, value) => {
        dispatch(handleSearchInput(name, value))
        if (value.length > 2) {
            dispatch(getNameList(value));
        }
    }

    const columns = [
        {
            name: "Date",
            selector: (row) => row.startDate,
            sortable: true,
        },
        {
            name: "Start Time",
            selector: (row) => row.timeFrom,
            sortable: true,
        },
        {
            name: "End Time",
            selector: (row) => row.timeTo,
            sortable: true,
        },
        {
            name: "Movement D(Hour)",
            selector: (row) => row.movementHour,
            sortable: true,
        },
        {
            name: "From Address",
            selector: (row) => row.fromAddress,
            sortable: true,
        },
        {
            name: "Movement Address",
            selector: (row) => row.toAddress,
            sortable: true,
        },
        {
            name: "Start Milage",
            selector: (row) => row.startMilage,
            sortable: true,
        },
        {
            name: "End Milage",
            selector: (row) => row.endMilage,
            sortable: true,
        },
        {
            name: "Consume Milage",
            selector: (row) => row.consumeMilage,
            sortable: true,
        },
        {
            name: "Bill Type",
            selector: (row) => row.billName,
            sortable: true,
        },
        {
            name: "Cost Type",
            selector: (row) => row.costName,
            sortable: true,
        },
        {
            name: "Payment Type",
            selector: (row) => row.paymentName,
            sortable: true,
        },
        {
            name: "CNG Station",
            selector: (row) => row.cngStationName,
            sortable: true,
        },
        {
            name: "Fuel Type",
            selector: (row) => row.fuelName,
            sortable: true,
        },

        {
            name: "Contact Person",
            selector: (row) => row.contactPerson,
            sortable: true,
        },
        {
            name: "Phone",
            selector: (row) => row.contactNumber,
            sortable: true,
        },
        {
            name: "Attachment",

            cell: (row) =>
                <img width={50} height={50} src={row.attachment} onClick={() => handleModaleItem(row.attachment)} />
        },
        {
            name: "Amount",
            selector: (row) => row.amount,
            sortable: true,
        },
        {
            name: "Remarks",
            selector: (row) => row.remarks,
            sortable: true,
        },
        {
            name: "Action",
            // cell: row => <button className='btn btn-sm btn-primary' onClick={() => alert(row.id)} >Edit</button>
            cell: row => <a href onClick={() => handleDeleteItem(row)} title="Delete" >
                <i className="fas fa-trash-alt editIcon item-list-icon ml-4 text-danger"></i> </a>
        },
    ];


    return (
        <>
            <div className="card card-custom mb-2 pl-5 pr-5  card-top-border p-1 ">
                <div className="row">
                    <div className="col-lg-10 m-3 ">
                        <h3>TA DA Entry Public(For Bike/Car)</h3>
                    </div>
                    {/* <div className="col-lg-12">
                        <button type="button" className="btn amount float-right">
                            Total Amount <span className="badge badge-light">{bikeCarRowSum}</span>
                        </button>
                    </div> */}
                </div>

                <hr></hr>

                <form
                    className="form form-label-right  mb-0 "
                    onSubmit={handleSubmit(onSubmit)}
                    method="post"
                    encType="multipart/form-data"
                    autoComplete="off"
                >
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="form-group row">
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Employee Name</label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss zaxiscustom"
                                            type="text"
                                            placeholder="Type Name"
                                            name="empName"
                                            value={empInfo?.empName}
                                            onChange={(e) => {

                                                handleChangeEmpName('empName', e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                    <div className="empname">
                                        <>
                                            {nameList.map((item, index) => (
                                                <ul className="uldotremove">

                                                    <li onClick={() => handleemplist(item)}>{item.empName}</li>
                                                </ul>
                                            ))}
                                        </>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Date<span className="star">*</span></label>
                                        <Form.Control
                                            type="date"
                                            placeholder="Enter Deadweight"
                                            name="startDate"
                                            value={startDate}
                                            className="form-control reffcss"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Start Time<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="time"
                                            placeholder="Type Start Time"
                                            name="timeFrom"
                                            value={itemDataInputForBikeCarEntry?.timeFrom}
                                            onChange={(e) => {
                                                inputChangeHandle("timeFrom", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">End Time<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="time"
                                            placeholder="Type End Time"
                                            name="timeTo"

                                            value={itemDataInputForBikeCarEntry?.timeTo}
                                            onChange={(e) => {
                                                inputChangeHandle("timeTo", e.target.value);
                                                changeMovementInput("timeTo", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Movement D(Hour)</label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="number"
                                            placeholder="Movemrnt D(Hour)"
                                            name="movementHour"
                                            disabled
                                            value={itemDataInputForBikeCarEntry?.movementHour}
                                            onChange={(e) => {
                                                // inputChangeHandle("movementDhour", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">From Address<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="text"
                                            placeholder="Type From Address"
                                            name="fromAddress"
                                            value={itemDataInputForBikeCarEntry?.fromAddress}
                                            onChange={(e) => {
                                                inputChangeHandle("fromAddress", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Movemrnt Address<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="text"
                                            placeholder="Movemrnt Address"
                                            name="toAddress"
                                            value={itemDataInputForBikeCarEntry?.toAddress}
                                            onChange={(e) => {
                                                inputChangeHandle("toAddress", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Contact Person<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="text"
                                            placeholder="Type Name"
                                            name="contactPerson"
                                            onChange={(e) => {
                                                inputChangeHandle("contactPerson", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Phone<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="number"
                                            placeholder="Type Number"
                                            name="contactNumber"
                                            onChange={(e) => {
                                                inputChangeHandle("contactNumber", e.target.value);
                                            }}
                                            setValue={setValue}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <hr />
                            </div>

                            <div className="row bg-secondary p-3">
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Bill Type<span className="star">*</span></label>
                                        <RHFInput
                                            className="formSelect pt-0 "
                                            as={<Select options={billType} />}
                                            rules={{ required: false }}
                                            name="billType"
                                            register={register}
                                            placeholder="Select bill type"
                                            onChange={(option) => {
                                                setSelectCostType(option.value)
                                                dispatch(GetTa(option.value));
                                                inputChangeHandle("billName", option?.label);
                                                setValue("vehicleType", "");
                                                setValue("paymentType", "");
                                                setValue("cngStation", "");
                                                setValue("fuelType", "");
                                                inputChangeHandle('amount', 0);

                                            }}
                                            setValue={setValue}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Cost Type<span className="star">*</span></label>
                                        <RHFInput
                                            className="formSelect pt-0 costType"
                                            as={<Select options={taType} />}
                                            rules={{ required: false }}
                                            name="vehicleType"
                                            register={register}
                                            placeholder="Select Vehicle type"
                                            isClearable
                                            onChange={(option) => {
                                                
                                                inputChangeHandle('vehicleType', option?.value);
                                                setSelectExpenseType(option);
                                                inputChangeHandle("costName", option?.label);

                                            }}
                                            setValue={setValue}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Payment Type<span className="star">*</span></label>
                                        <RHFInput
                                            className="formSelect pt-0 "
                                            as={<Select options={paymentType} />}
                                            rules={{ required: false }}
                                            name="paymentType"
                                            register={register}
                                            placeholder="Select Payment type"
                                            //isClearable
                                            value={itemDataInputForBikeCarEntry?.paymentType}
                                            onChange={(option) => {
                                                inputChangeHandle("paymentType", option?.value);
                                                inputChangeHandle("paymentName", option?.label);
                                            }}
                                            setValue={setValue}
                                        />
                                    </Form.Group>
                                </div>

                                {
                                    selectCosttype == '1' &&

                                    <>
                                        <div className="col-lg-3 ">
                                            <Form.Group as={Col} controlId="formGridState">
                                                <label className="form-label">Start Milage</label>
                                                <Form.Control
                                                    autocomplete="off"
                                                    className="form-control reffcss"
                                                    type="number"
                                                    placeholder="Start Milage"
                                                    name="startMilage"
                                                    value={itemDataInputForBikeCarEntry?.startMilage}
                                                    onChange={(e) => {
                                                        inputChangeHandle("startMilage", e.target.value);
                                                        dispatch(inputHandleConsumeMilage("startMilage", e.target.value));
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-3 ">
                                            <Form.Group as={Col} controlId="formGridState">
                                                <label className="form-label">End Milage</label>
                                                <Form.Control
                                                    autocomplete="off"
                                                    className="form-control reffcss"
                                                    type="number"
                                                    placeholder="End Milage"
                                                    name="endMilage"
                                                    value={itemDataInputForBikeCarEntry?.endMilage}
                                                    // disabled
                                                    onChange={(e) => {
                                                        inputChangeHandle("endMilage", e.target.value);
                                                        dispatch(inputHandleConsumeMilage("endMilage", e.target.value));
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-3 ">
                                            <Form.Group as={Col} controlId="formGridState">
                                                <label className="form-label">Consume Milage</label>
                                                <Form.Control
                                                    autocomplete="off"
                                                    className="form-control reffcss"
                                                    type="number"
                                                    disabled
                                                    placeholder="Consume Milage"
                                                    name="consumeMilage"
                                                    value={itemDataInputForBikeCarEntry?.consumeMilage}
                                                    onChange={(e) => {
                                                        inputChangeHandle("consumeMilage", e.target.value);
                                                    }}

                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-3 ">
                                            <Form.Group as={Col} controlId="formGridState">
                                                <label className="form-label">Personal Milage</label>
                                                <Form.Control
                                                    autocomplete="off"
                                                    className="form-control reffcss"
                                                    type="number"
                                                    placeholder="Personal Milage"
                                                    name="persoanlMilage"
                                                    value={itemDataInputForBikeCarEntry?.persoanlMilage}
                                                    onChange={(e) => {
                                                        inputChangeHandle("persoanlMilage", e.target.value);

                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        {
                                            itemDataInputForBikeCarEntry?.paymentType == "1"
                                            && selectExpenseType.label == "Filling Station"
                                            &&
                                            <>
                                                <div className="col-lg-3">
                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <label className="form-label">Filling Station</label>
                                                        <RHFInput
                                                            className="formSelect pt-0 costType"
                                                            as={<Select options={fillingStation} />}
                                                            rules={{ required: false }}
                                                            name="cngStation"
                                                            register={register}
                                                            placeholder="Select Filling station"
                                                            isClearable
                                                            onChange={(option) => {
                                                                inputChangeHandle("cngStation", option?.value);
                                                                inputChangeHandle("cngStationName", option?.label);

                                                            }}
                                                            setValue={setValue}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <label className="form-label">Fuel Type</label>
                                                        <RHFInput
                                                            className="formSelect pt-0 "
                                                            as={<Select options={fuelType} />}
                                                            rules={{ required: false }}
                                                            name="fuelType"
                                                            register={register}
                                                            placeholder="Select Fuel type"
                                                            isClearable
                                                            onChange={(option) => {
                                                                inputChangeHandle("fuelType", option?.value);
                                                                inputChangeHandle("fuelName", option?.label);
                                                            }}
                                                            setValue={setValue}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </>

                                        }
                                        {
                                            itemDataInputForBikeCarEntry?.paymentType == "2"
                                            && selectExpenseType.label == "Filling Station"
                                            &&
                                            <div className="col-lg-3">
                                                <Form.Group as={Col} controlId="formGridState">
                                                    <label className="form-label">Fuel Type</label>
                                                    <RHFInput
                                                        className="formSelect pt-0"
                                                        as={<Select options={fuelType} />}
                                                        rules={{ required: false }}
                                                        name="fuelType"
                                                        register={register}
                                                        placeholder="Select Fuel type"
                                                        isClearable
                                                        onChange={(option) => {
                                                            inputChangeHandle("fuelType", option?.value);
                                                            inputChangeHandle("fuelName", option?.label);
                                                        }}
                                                        setValue={setValue}
                                                    />
                                                </Form.Group>
                                            </div>
                                        }
                                    </>
                                }
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Amount<span className="star">*</span></label>
                                        <Form.Control
                                            autocomplete="off"
                                            className="form-control reffcss"
                                            type="number"
                                            placeholder="Amount"
                                            name="amount"
                                            value={itemDataInputForBikeCarEntry?.amount}
                                            onChange={(e) => {
                                                inputChangeHandle("amount", e.target.value);

                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Attachment</label>
                                        {
                                            <Form.Control
                                                type="file"
                                                placeholder="Type Attachment"
                                                className="form-control forimageinput"
                                                name="attachment"
                                                accept="image/*"
                                                onChange={(e) => showPreview(e)}
                                            />
                                        }
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Remarks</label>
                                        <Form.Control as="textarea" rows="2"
                                            autocomplete="off"
                                            type="textarea"
                                            placeholder="Type Remarks"
                                            name="remarks"
                                            onChange={(e) => {
                                                inputChangeHandle("remarks", e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <>
                                <div className="col-lg-12 mt-5 ml-4">
                                    <button type="submit"
                                        // disabled={disabled}
                                        className="btn btn-primary btn-sm float-right text-center custome-addnew-btn item-attach mb-1 mr-4 ">
                                        Submit
                                    </button>
                                </div>
                            </>

                        </div>
                    </div>
                </form>
                <div>
                    <button
                        onClick={() => handleAddData()}
                        className="btn btn-primary  btn-sm float-right text-center custome-addnew-btn item-add mb-5 mr-4">
                        Add
                    </button>
                </div>
            </div>

            <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
                <DataTable
                    columns={columns}
                    data={tadaRow}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='450px'
                    selectableRowsHighlight
                    highlightOnHover
                />
                <SimpleModal
                    size="xl"
                    show={itemDetailShow}
                    handleClose={() => setItemDetailShow(false)}
                    handleShow={() => setItemDetailShow(true)}
                    modalTitle={"Image Preview"}
                >
                    <img src={imageShow} width='100%' height='100%' />
                </SimpleModal>
            </div>
        </>
    );
};
export default TADABikeCarEntryPublic;
