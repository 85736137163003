import React from "react";
import TADASupervisorUnApprovedList from "./TADASupervisorUnApprovedList";

const TADASupervisorUnApprovedListContainer = () => {
  return (
    <div className="container-fluid">
        <TADASupervisorUnApprovedList />
    </div>
  );
};
export default TADASupervisorUnApprovedListContainer;
