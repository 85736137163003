import React from "react";
import MonthlyBasisVehicleReport from "./MonthlyBasisVehicleReport";

const MonthlyBasisVehicleReportContainer = () => {
  return (
    <div className="container-fluid">
        <MonthlyBasisVehicleReport />
    </div>
  );
};
export default MonthlyBasisVehicleReportContainer;
