
import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useMemo, useState, useEffect } from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import events from './events';
import moment from 'moment';

function AttendanceCalender() {
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [myEventsList, setMyEventsList] = React.useState({
    title: "Hello",
    start: '2022-12-01',
    end: '2022-12-01',
    slug: 'Hello',
  });
  const fetchIdRef = React.useRef(0);
  const localizer = momentLocalizer(moment);

  const eventStyleGetter =(event, start, end, isSelected)=> {
    var backgroundColor = '#' + event.hexColor;
    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '6px',
        opacity: 0.9,
        color: '#fff',
        border: '0px',
        display: 'block'
    }
    return {
      style: style
  };
   
}


  return (
    <div className="card card-custom gutter-b p-5 mb-5">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        step={60}
        style={{ height: 400 }}
        defaultDate={new Date(2022, 2, 1)}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
}
export default AttendanceCalender;