import React from "react";
import TADAEntry from "./TADAEntry";


const TADAEntryContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <TADAEntry />
      </div>
    </div>
  );
};
export default TADAEntryContainer;
