
import React from "react";
import TADAForNonBikeCarEntryPublic from "./TADAForNonBikeCarEntryPublic";



const TADAForNonBikeCarPublicContainer = () => {
  return (
    <div className="container-fluid">
        <TADAForNonBikeCarEntryPublic />
    </div>
  );
};
export default TADAForNonBikeCarPublicContainer;
