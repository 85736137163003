
import moment from "moment/moment";
import { GetDdl } from "../../../../../Global/GlobalDdl";
import * as Types from "../../types/Types";
const initialState = {

  tadareportataglance: [],
  costdetails: [],
  personalInfo: [],
  totalExpense: 0,
  paybleAmount: 0,
  fuelstationreport:[],

};
const TadaReportReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_TADA_REPORT_AT_A_GLANCE:
      console.log('tada report action.payload.data :>> ', action.payload.data);
      return {
        ...state,
        tadareportataglance: action.payload.data,
      };
    case Types.GET_TADA_COST_DETAILS:
      console.log('cost details action.payload.data :>> ', action.payload.data);

      return {
        ...state,
        costdetails: action.payload.data,
        personalInfo: action.payload.data.personalInfo,
        totalExpense: action.payload.data.totalExpense,
        paybleAmount: action.payload.data.paybleAmount,
      };
      case Types.GET_FUEL_STATION_BILL_REPORT:
        console.log('fuel station report action.payload.data :>> ', action.payload.data);
        return {
          ...state,
          fuelstationreport: action.payload.data,
        };
    default:
      break;
  }
  return newState;
};



export default TadaReportReducer;

