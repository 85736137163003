import React from "react";
import TadaUserCostDetailsReport from "./TadaUserCostDetailsReport";


const TadaUserCostDetailsReportContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <TadaUserCostDetailsReport />
      </div>
    </div>
  );
};
export default TadaUserCostDetailsReportContainer;
