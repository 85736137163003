import React from "react";
import { Route } from "react-router-dom";
import AddBilling from "../../Billing/AddBiling/AddBilling";
import LeaveApplicationList from "../components/remoteAttendance/leaveApplication/LeaveApplicationList";
import DemoContainer from "../components/tada/DemoContainer";
import TADABikeCarEntryContainer from "../components/tada/TADABikeCarEntryContainer";
import TADAEntryContainer from "../components/tada/TADAEntryContainer";
import TADAForNonBikeCarContainer from "../components/tada/TADAForNonBikeCarContainer";
import TaDaListContainer from "../components/tada/TaDaListContainer";
import TADAUnApprovedListContainer from "../components/tada/TADASupervisorUnApprovedListDetailsContainer";
import AttendanceStatusContainer from "../views/remoteAttendance/attendanceStatus/AttendanceStatusContainer";
import AttendanceSummeryContainer from "../views/remoteAttendance/attendanceSummery/AttendanceSummeryContainer";
import MovementsContainer from "../views/remoteAttendance/movements/MovementsContainer";
import RemoteAttendanceContainer from "../views/remoteAttendance/RemoteAttendanceContainer";
import TADASupervisorUnApprovedListContainer from "../components/tada/TADASupervisorUnApprovedListContainer";
import ListDetailsContainer from "../components/tada/ListDetailsContainer";
import TADASupervisorUnApprovedListDetailsContainer from "../components/tada/TADASupervisorUnApprovedListDetailsContainer";
import TadaApprovedForSuperviserContainer from "../components/tada/TadaApprovedForSuperviserContainer";
import TadaApprovedByHRContainer from "../components/tada/TadaApprovedByHRContainer";
import TadaApprovedByAuditContainer from "../components/tada/TadaApprovedByAuditContainer";
import TadaReportATGlanceContainer from "../components/tada/TadaReportATGlanceContainer";
import StandVehicleDetailsContainer from "../components/tada/StandVehicleDetailsContainer";
import TADABikeCarEntryPublicContainer from "../components/tada/TADABikeCarEntryPublicContainer";
import TADAForNonBikeCarPublicContainer from "../components/tada/TADAForNonBikeCarPublicContainer";
import DemoTableContainer from "../components/tada/DemoTableContainer";
import TadaUserCostDetailsReportContainer from "../components/tada/TadaUserCostDetailsReportContainer";
import DailyBasisVehicleReportContainer from "../components/tada/DailyBasisVehicleReportContainer";
import MonthlyBasisVehicleReportContainer from "../components/tada/MonthlyBasisVehicleReportContainer";
import FuelStationBillReportContainer from "../components/tada/FuelStationBillReportContainer";


const routeItems = [

  {
    path: "/hr/calender",
    name: "zone list",
    component: RemoteAttendanceContainer,
    exact: true,
  },
  {
    path: "/hr/remoteAttendance",
    name: "zone list",
    component: AttendanceSummeryContainer,
    exact: true,
  },
  {
    path: "/hr/attendanceStatus",
    name: "attendance status",
    component: AttendanceStatusContainer,
    exact: true,
  },
  {
    path: "/hr/movement",
    name: "movement attendance",
    component: MovementsContainer,
    exact: true,
  },
  {
    path: "/hr/leaveapplication",
    name: "Leave Application",
    component: LeaveApplicationList,
    exact: true,
  },
  {
    path: "/billing/addBilling",
    name: "Leave Application",
    component: AddBilling,
    exact: true,
  },
  {
    path: "/tada/TADAEntry",
    name: "TADAEntry",
    component: TADAEntryContainer,
    exact: true,
  },
  {
    path: "/tada/TADABikeCarEntry",
    name: "TADABikeCarEntry",
    component: TADABikeCarEntryContainer,
    exact: true,
  },
  {
    path: "/tada/demo",
    name: "Demo",
    component: DemoContainer,
    exact: true,
  },
  {
    path: "/tada/TADAForNonBikeCar",
    name: "Demo",
    component: TADAForNonBikeCarContainer,
    exact: true,
  },
  {
    path: "/tada/TaDaList",
    name: "Demo",
    component: TaDaListContainer,
    exact: true,
  },
 
  {
    path: "/tada/TADASupervisorUnApprovedList",
    name: "ApprovedList",
    component: TADASupervisorUnApprovedListContainer,
    exact: true,
  },
  {
    path: "/TADASupervisorUnApprovedListDetails",
    name: "UnApprovedListDetails",
    component: TADASupervisorUnApprovedListDetailsContainer,
    exact: true,
  },
  {
    path: "/tada/ListDetails",
    name: "ApprovedList",
    component: ListDetailsContainer,
    exact: true,
  },
  {
    path: "/tada/TadaApprovedForSuperviser",
    name: "ApprovedList",
    component: TadaApprovedForSuperviserContainer,
    exact: true,
  },
  {
    path: "/tada/TadaApprovedForHR",
    name: "HRApprovedList",
    component: TadaApprovedByHRContainer,
    exact: true,
  },
  {
    path: "/tada/TadaApprovedForAudit",
    name: "AuditUnapprovedApprovedList",
    component: TadaApprovedByAuditContainer,
    exact: true,
  },
  {
    path: "/tada/TadaReport",
    name: "TADAReport",
    component: TadaReportATGlanceContainer ,
    exact: true,
  },
  {
    path: "/tada/StandVehicleDetails",
    name: "StandVehicleDetails",
    component: StandVehicleDetailsContainer ,
    exact: true,
  },
  {
    path: "/tada/TADABikeCarEntryPublic",
    name: "TADABikeCarEntryPublic",
    component: TADABikeCarEntryPublicContainer ,
    exact: true,
  },
  {
    path: "/tada/TADANonBikeCarEntryPublic",
    name: "TADANonBikeCarEntryPublic",
    component: TADAForNonBikeCarPublicContainer ,
    exact: true,
  },
  {
    path: "/tada/demotable",
    name: "demotable",
    component: DemoTableContainer ,
    exact: true,
  },
  {
    path: "/tada/TadaUserCostDetailsReport",
    name: "TadaUserCostDetailsReport",
    component: TadaUserCostDetailsReportContainer ,
    exact: true,
  },
  {
    path: "/tada/DailyBasisVehicleReport",
    name: "DailyBasisVehicleReport",
    component: DailyBasisVehicleReportContainer ,
    exact: true,
  },
  {
    path: "/tada/MonthlyBasisVehicleReport",
    name: "MonthlyBasisVehicleReport",
    component: MonthlyBasisVehicleReportContainer ,
    exact: true,
  },
  {
    path: "/tada/FuelStationBillReport",
    name: "FuelStationBillReport",
    component: FuelStationBillReportContainer ,
    exact: true,
  },
];

function getHrRoutes() {
  {
    return routeItems.map((route, index) => (
      <Route
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getHrRoutes;
