
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { RHFInput } from "react-hook-form-input";
import { useForm } from "react-hook-form";
import Select from "react-select";
import SimpleModal from "./../SimpleModal";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import DataTable from "react-data-table-component"
import { clearTadaList, getNameList,hanleChangeInputforHRapproved, tadaDelete, GetAreaListForHR, getMonthlyTadaAmountForHR, hanlesetInputforempname, handleSearchInput} from '../../redux/actions/tada/TadaEntryAction';
import { getDayWiseTadaAmountForHr, getTadaApprovedForHR} from '../../redux/actions/tada/TadaEntryAction';
import TadaDetails from "./TadaDetails";
import TADADetailsForHR from "./TADADetailsForHR";
const TadaApprovedByHR = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();

    const history = useHistory();
    const [selectedData, setSelectedData] = React.useState([]);

    const dispatch = useDispatch();
    const tadaUnapprovedListMonthlyForHR = useSelector((state) => state.tada.tadaUnapprovedListMonthlyForHR);
    console.log('img tadaUnapprovedListMonthlyForHR :>> ', tadaUnapprovedListMonthlyForHR);
    const tadaUnapprovedListDailyForHR = useSelector((state) => state.tada.tadaUnapprovedListDailyForHR);
    console.log('img tadaUnapprovedListDailyForHR', tadaUnapprovedListDailyForHR);
    const nameList = useSelector((state) => state.tada.nameList);
    const areaList = useSelector((state) => state.tada.areaList);
    const isLoading = useSelector((state) => state.tada.tadaListLoading);
    const empInfo = useSelector((state) => state.tada.empInfo);
    console.log('empInfo', empInfo);


    useEffect(() => {
        dispatch(GetAreaListForHR());
    }, [])

    useEffect(() => {
        dispatch(clearTadaList());
    }, []);


    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [enroll, setEnroll] = useState(null);
    const [areaID, setAreaID] = useState(null);
    const [selectTadaId, setSelectTadaId] = useState(null);
    const [itemDetailShow, setItemDetailShow] = useState(false);
    const [itemID, setItemID] = useState(null);
    const [itemifo, setItemifo] = useState(null);
    const [userEnroll, setuserEnroll] = useState(null);
    const [selectAreaName, setSelectAreaName] = useState(null);

    const componentRef = useRef();
    let ref = React.createRef();
    const inputField = React.useRef(null);

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };

    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };
    const handleChangeName = (input) => {

        if (input.length > 2) {
            dispatch(getNameList(input));
        }
    };

    const itemDetail = (item) => {
        setItemifo(item);
        setItemDetailShow(true);
    };
    const handleDeleteItem = (item) => {
        dispatch(tadaDelete(item, selectTadaId, startDate));
    };
    const handleItemDetails = (item) => {
        setuserEnroll(item);
        dispatch(getDayWiseTadaAmountForHr(item.enroll,startDate, toDate));
      };
    const handleIApproved = () => {
        //dispatch(getTadaApprovedForHR(selectedData));
        dispatch(getTadaApprovedForHR(selectedData, startDate, toDate, userEnroll));
        setItemDetailShow(false);

    };
    const handleemplist = (item) => {
        dispatch(hanlesetInputforempname(item));
    };
    const showReport = () => {
        dispatch(getMonthlyTadaAmountForHR(empInfo.enroll,selectAreaName,startDate, toDate));
    }
    const dateSet = {
        fromDate: startDate,
        toDate: toDate
      }


    const monthlydata = [
        {
            name: "ENROLL",
            selector: (row) => row.enroll,
            sortable: true,
        },
        {
            name: "EMPLOYEE NAME",
            selector: (row) => row.employeeName,
            sortable: true,
        },
        {
            name: "DESIGNATION",
            selector: (row) => row.designation,
            sortable: true,
        },
        {
            name: "AMOUNT",
            selector: (row) => row.userAmount,
            sortable: true,
        },
        {
            name: "SUPERVISER AMOUNT",
            selector: (row) => row.superviserAmount,
            sortable: true,
        },
        
        {
            name: "Action",
            cell: row => <a href onClick={() => handleItemDetails(row)} title="Details">
                <i className="fa fa-th-list editIcon item-list-icon"></i>
            </a>
        },

        // {
        //   name: "Action",
        //   cell: row => <a href onClick={routeChange } title="Details">
        //     <i className="far fa-eye editIcon item-list-icon"></i>
        //   </a>
        // },

    ];
    const dailydata = [
        {
            name: "DATE",
            selector: (row) => row.tadaDate,
            sortable: true,
        },
        {
            name: "ENROLL",
            selector: (row) => row.enroll,
            sortable: true,
        },
        {
            name: "EMPLOYEE NAME",
            selector: (row) => row.employeeName,
            sortable: true,
        },
        {
            name: "JOB STATION ",
            selector: (row) => row.jobStation,
            sortable: true,
        },
        {
            name: "AMOUNT",
            selector: (row) => row.amount,
            sortable: true,
        },
        {
            name: "Superviser Amount",
            selector: (row) => row.superviserAprvAmount,
            sortable: true,
        },
        // {
        //     name: "Attachment",

        //     cell: (row) =>
        //         <img width={50} height={50}
        //             //src="https://localhost:44373/User_Files/Articles/Images/User_Files/8cb5d58a-bea5-460b-8b93-59d5595219f4foodPand.PNG" 
        //             src={`https://localhost:44373/User_Files/Articles/Images/User_Files/${row.image}`}
        //         />

        // },
        // {
        //   name: "Action",
        //   cell: row => <a href onClick={routeChange } title="Details">
        //     <i className="far fa-eye editIcon item-list-icon"></i>
        //   </a>
        // },

        {
            name: "Action",
            cell: row => <a href onClick={() => itemDetail(row)} title="Details">
                <i className="far fa-eye editIcon item-list-icon"></i>
            </a>
        },

    ];

    const handleChange1 = (state) => {
        setSelectedData(state.selectedRows);
        console.log('state.selectedRows', state.selectedRows);
    };
    const inputChangeHandle = (name, value) => {
        dispatch(hanleChangeInputforHRapproved(name, value != undefined ? value : ""));
    };

    const handleChangeEmpName =(name,value)=>{
        dispatch(handleSearchInput(name,value))
        if(value.length>2){
            dispatch(getNameList(value));
        }
    }

    const demo = [
        { value: "1", label: "Demo-1" },
        { value: "2", label: "Demo-2" },
    ];


    return (
        <>
            <div className="card card-custom card-top-border p-5 mb-3">
                <div className="row">
                    <div className="col-md-9">
                        <div className="">
                            <h3 className="mb-0 pb-0">TA DA APPROVED BY HR (BOTH CATEGORY USER)</h3>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="form-group row">
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="form-control reffcss"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label"> To Date</label>

                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                value={toDate}
                                className="form-control reffcss"
                                onChange={handleChangeTodate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 ">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Employee Name</label>
                            <Form.Control
                                autocomplete="off"
                                className="form-control reffcss zaxiscustom"
                                type="text"
                                placeholder="Type Name"
                                name="empName"
                                value={empInfo?.empName}
                                 onChange={(e) => {
                                   
                                    handleChangeEmpName('empName',e.target.value);
                                }}
                            />
                        </Form.Group>
                        <div className="empname">
                            <>
                            {nameList.map((item, index) => (
                                <ul className="uldotremove">
                                   
                                    <li onClick={()=>handleemplist(item)}>{item.empName}</li>
                                </ul>
                            ))}
                            </>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Area</label>
                            <RHFInput
                                className="formSelect pt-0 "
                                as={<Select options={areaList} />}
                                rules={{ required: false }}
                                name="areaType"
                                register={register}
                                placeholder="Select Area"
                                isClearable
                                onChange={(option) => {
                                    //handleChangeEmpName("areaType", option?.value);
                                    setSelectAreaName(option?.value)
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col mt-6 mb-5">
                        <Button size="sm" onClick={() => showReport()}>Show Report</Button>
                    </div>
                </div>
            </div>
            <div className="card text-center bg-secondary "><h5>Monthly TA DA Details</h5></div>
            <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">

                <DataTable
                    columns={monthlydata}
                    data={tadaUnapprovedListMonthlyForHR}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='450px'
                    selectableRowsHighlight
                    highlightOnHover
                // selectableRows
                // onSelectedRowsChange={handleChange1}
                />
            </div>


            {/* Ta da details information */}
            <div className="card text-center bg-secondary "><h5>Daily TA DA Details</h5></div>
            <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
                <DataTable
                    columns={dailydata}
                    data={tadaUnapprovedListDailyForHR}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='300px'
                    selectableRowsHighlight
                    highlightOnHover
                    selectableRows
                    onSelectedRowsChange={handleChange1}
                    actions={<button className="btn btn-primary btn-sm rdcustom" onClick={handleIApproved}>Approved</button>}
                />

                {/* <div className="col-lg-12">
        <button className="btn btn-primary btn-sm float-right mb-5" onClick={() => handleIApproved(selectedData)}>Approved</button>
        </div> */}

            </div>

            <SimpleModal
                size="xl"
                show={itemDetailShow}
                handleClose={() => setItemDetailShow(false)}
                handleShow={() => setItemDetailShow(true)}
                modalTitle={"Details"}
            >
                <TADADetailsForHR
                    handleClose={() => setItemDetailShow(false)}
                    itemID={dateSet}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>


        </>
    );
};

export default TadaApprovedByHR;
