import React from "react";
import TaDaListEdit from "./TaDaListEdit";


const TaDaListContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <TaDaListEdit />
      </div>
    </div>
  );
};
export default TaDaListContainer;
