import React from "react";
import TadaApprovedByHR from "./TadaApprovedByHR";

const TadaApprovedByHRContainer = () => {
  return (
    <div className="container-fluid">
       <TadaApprovedByHR />
    </div>
  );
};
export default TadaApprovedByHRContainer;
