import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Barcode from "react-barcode";

import {
  GetPartyLedger,
  GetPartyName,
  GetPartyType,
  GetPoDetails,
  GetUnit,
  InputHandle,
} from "../../../redux/Billing/action/AddBillingAction";

import LoadingSpinner from "../Components/LoadingSpinner";

var address = require("address");
console.log(address.ip());

const AddBilling = () => {
  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const ddlUnitDrop = useSelector((state) => state.billing.ddlUnit);
  const ddlPartyType = useSelector((state) => state.billing.ddlPartyType);
  const ddlPartyName = useSelector((state) => state.billing.ddlPartyName);
  const ddlPartyLedger = useSelector((state) => state.billing.ddlPartyLedger);
  const input = useSelector((state) => state.billing.itemDataInput);
  const po = useSelector((state) => state.billing.poDetails);
  const defaultUnit = useSelector((state) => state.billing.defaultUnit);

  const [rhDisable, setRhDisabled] = useState(true);


  const mrrList = useSelector((state) => state.billing.mrrInfo);
  const refLoading = useSelector((state) => state.billing.refLoading);
  const ledger = useSelector((state) => state.billing.ledger);




  console.log("refLoading", refLoading);
  console.log("po", po);
  console.log("mrrList", mrrList);
  console.log("ddlUnitDrop", ddlUnitDrop);

  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const onSubmitLeave = () => {
    alert();
  };

  const goToPoDetails = () => {
    dispatch(GetPoDetails(input.referenceNo));
  };

  const inputChangeHandle = (name, value) => {
  

    dispatch(InputHandle(name, value));
  };

  const onSubmit = async (e) => {};



  return (
    <>
      <div className="card card-custom mb-2 pl-5 pr-5  card-top-border p-1 ">
        <div className="row">
          <div className="col-lg-12 mt-3">
            <h3>Bill Registration</h3>
          </div>
        </div>
        {/* <hr></hr> */}

        <form
          className="form form-label-right  mb-6 "
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          encType="multipart/form-data"
          autoComplete="off"
        >
          <div className="row">
            <div className="col-lg-6 square border border-1">
              <div className="form-group row">
                {/* <div className="col-lg-4">
                                        <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Print Copies
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter Print value"
                                            name="printValue"
                                            className="fromStyle"
                                        /></label>
                                        </Form.Group>
                                    </div> */}

                {/* <div className="col-lg-4 mt-1 ">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label ">Reference</label>
                                <RHFInput
                                className="fromStyle"
                                as={<Select options={ddlUnitDrop} />}
                                rules={{ required: false }}
                                name="referenceData"
                                register={register}
                                value=""
                                onChange={(option) => {
                                    inputChangeHandle("referenceId", option.label);
                                }}
                                setValue={setValue}
                                />
                            </Form.Group>
                            </div> */}

                <div className="col-lg-4 mt-1 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Referrence</label>
                    <Form.Control
                      className="form-control reffcss"
                      type="text"
                      placeholder="Referrence"
                      name="referenceData"
                      onChange={(e) => {
                        //inputChangeHandle("referenceId", option.label);
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-4 mt-1 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Ref No.</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Ref No"
                     
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-2 mt-8 ">
                  <button
                    className="btn btdesign btn-sm float-right text-center text-white custome-addnew-btn item-go mr-4 "
                    onClick={() => goToPoDetails()}
                  >
                    Go
                  </button>
                </div>
                <div className="col-lg-2"></div>

                <>
                  {!refLoading && (
                    <>
                      {/* <div className="col-lg-4 mt-1 ">
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">Billing Unit</label>
                          <Form.Control
                            autocomplete="off"
                            className="form-control reffcss bg-secondary "
                            type="text"
                            placeholder="Billing Unit"
                            name="itemName"
                            value={po.unitName}
                            disabled
                            onChange={(e) => {
                              //inputChangeHandle("unitId", option.value);
                            }}
                          />
                        </Form.Group>
                      </div> */}
                      <div className="col-lg-4">
                      {/* isDisabled={rhDisable} */}
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">Billing Unit</label>
                          <RHFInput
                            className="formSelect pt-0"
                            as={<Select options={ddlUnitDrop}  isDisabled={rhDisable}/>}
                            rules={{ required: false }}
                            name="referenceData"
                            register={register}
                            defaultValue={defaultUnit}
                            onChange={(option) => {
                              inputChangeHandle("unitId", option.value);
                            }}
                            // defaultValue={po.unit}
                            //  setValue={("referenceData",selected)}
                            setValue={setValue}
                          />
                        </Form.Group>
                      </div>

                      {/* <div className="col-lg-8">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Party Type</label>
                                <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={ddlPartyType} />}
                                rules={{ required: false }}
                                name="referenceData"
                                register={register}
                                value=""
                                onChange={(option) => {
                                    inputChangeHandle("partyType", option.value);
                                }}
                                setValue={setValue}
                                />
                            </Form.Group>
                            </div> */}
                      <div className="col-lg-8">
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">Party Type</label>
                          <Form.Control
                            autocomplete="off"
                            className="form-control reffcss bg-secondary "
                            type="text"
                            placeholder="Party Name"
                            name="itemName"
                            disabled
                            onChange={(e) => {
                              //inputChangeHandle("partyType", option.value);
                            }}
                          />
                        </Form.Group>
                      </div>

                      {/* <div className="col-lg-12">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Party Name</label>
                                <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={ddlPartyName} />}
                                rules={{ required: false }}
                                name="referenceData"
                                register={register}
                                value=""
                                onChange={(option) => {
                                    inputChangeHandle("partyName", option.value);
                                }}
                                setValue={setValue}
                                />
                            </Form.Group>
                            </div> */}
                      <div className="col-lg-12">
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">Party Name</label>
                          <Form.Control
                            autocomplete="off"
                            className="form-control reffcss bg-secondary  "
                            type="text"
                            placeholder="Party Type"
                            name="itemName"
                            value={po.supplierName}
                            disabled
                            onChange={(e) => {
                              //inputChangeHandle("partyName", option.value);
                            }}
                          />
                        </Form.Group>
                      </div>

                      <div className="col-lg-4">
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">Bill No.</label>
                          <Form.Control
                            autocomplete="off"
                            className="form-control reffcss "
                            type="text"
                            placeholder="Bill No"
                            name="itemName"
                            onChange={(e) => {
                              inputChangeHandle("billNo", e.target.value);
                            }}
                          />
                        </Form.Group>
                      </div>

                      <div className="col-lg-4">
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">Bill Date</label>
                          {
                            <Form.Control
                              type="date"
                              placeholder="Enter Deadweight"
                              name="fromDate"
                              className="form-control reffcss"
                            />
                          }
                        </Form.Group>
                      </div>

                      <div className="col-lg-4">
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">Amount</label>
                          <Form.Control
                            autocomplete="off"
                            className="form-control reffcss"
                            type="text"
                            placeholder="Amount"
                            name="itemName"
                            onChange={(e) => {
                              inputChangeHandle("amount", e.target.value);
                            }}
                          />
                        </Form.Group>
                      </div>

                      <div className="col-lg-12">
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">Remarks</label>
                          <Form.Control
                            autocomplete="off"
                            className="form-control reffcss"
                            type="text"
                            placeholder="Remarks"
                            name="itemName"
                            onChange={(e) => {
                              inputChangeHandle("remarks", e.target.value);
                            }}
                          />
                        </Form.Group>
                      </div>

                      <div className="col-lg-12 mt-5">
                        <button
                          type="submit"
                          className="btn btn-primary  btn-sm float-right text-center custome-addnew-btn item-attach mb-5 mr-4 "
                        >
                          Document View
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary  btn-sm float-right text-center custome-addnew-btn item-attach mb-5 mr-3"
                        >
                          Save
                        </button>
                      </div>
                    </>
                  )}
                  {refLoading && (
                    <LoadingSpinner text="Loading Items List..." />
                  )}
                </>
              </div>
            </div>

            <div className="col-lg-6 square border border-1">
              {/* <form>
                        <div class="form-group row mt-5  ">
                        <label className="col-lg-8 col-form-label flbl text-muted ">
                            Total PO Amount :
                        </label>
                        <div class="col-lg-4">
                            <input
                            type="text"
                            className="form-control fiptdgn bg-secondary "
                            placeholder="Total PO Amount"
                            value={po.monPoAmount}
                            disabled
                            />
                        </div>
                        </div>

                        <div class="form-group row">
                        <label className="col-lg-8 col-form-label flbl text-muted">
                            Total Advance :
                        </label>
                        <div class="col-lg-4">
                            <input
                            type="text"
                            className="form-control fiptdgn"
                            placeholder="Total Advance"
                            />
                        </div>
                        </div>

                        <div class="form-group row">
                        <label className="col-lg-8 col-form-label flbl text-muted">
                            Ledger Balance :
                        </label>
                        <div class="col-lg-4">
                            <input
                            type="text"
                            className="form-control fiptdgn"
                            placeholder="Ledger Balance"
                            />
                        </div>
                        </div>
                    </form> */}

              <div className="form-group row mt-1 ">
                <div className="col-lg-4">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Total PO Amount</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss bg-secondary "
                      type="text"
                      placeholder="Total PO Amount"
                      name="itemName"
                      disabled
                      value={po.monPoAmount}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-4">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Total Advance</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss bg-secondary"
                      type="text"
                      placeholder="Total Advance"
                      disabled
                      name="itemName"
                      value={ledger?.monAmount}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-4">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Ledger Balance</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss bg-secondary"
                      type="text"
                      placeholder="Ledger Balance"
                      disabled
                      name="itemName"
                      value={po.monPoAmount}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="form-group row ">
                <div className="col-lg-4">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Total Advance</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Total Advance"
                      name="itemName"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Ledger Balance</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Ledger Balance"
                      name="itemName"
                      value={ledger?.monLedgerBalance}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-6">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Adjust Amount</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Adjust Amount"
                      name="itemName"
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-4">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Search</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Search"
                      name="itemName"
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-2 mt-7">
                  <button className="btn btdesign  btn-sm float-right text-center text-white custome-addnew-btn item-go mr-4 ">
                    Go
                  </button>
                </div>

                <div className="col-lg-2"></div>

                {/* <div className="col-lg-12">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label  ">Party Ledgure</label>
                            <RHFInput
                            className="formSelect pt-0"
                            as={<Select options={ddlPartyLedger} />}
                            rules={{ required: false }}
                            name="referenceData"
                            register={register}
                            value=""
                            onChange={(option) => {}}
                            setValue={setValue}
                            />
                        </Form.Group>
                        </div> */}
                <div className="col-lg-12">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Party Ledgure</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Party Ledgure"
                      name="itemName"
                      onChange={(e) => {
                        //inputChangeHandle("partyLedgure", option.value);
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-4 mt-3">
                  <div className="card card-custom cdesign m-3 p-0">
                    <Barcode value="01961122027" />
                  </div>
                </div>
                {/* <div className='col-lg-8'>
                                    <form>
                                        <div className="form-group row mt-5  ">
                                            <label  className="col-lg-4 billcode col-form-label flbl text-danger "><h6>Bill Code :</h6></label>
                                            <div className="col-lg-7 mr-1">
                                            <input type="text" className="form-control fiptdgn"  placeholder="" />
                                            </div>
                                            </div> 
                                    </form>
                                </div> */}
                <div className="col-lg-8 mt-6">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label text-danger ">
                      <h6>Bill Code :</h6>
                    </label>
                    <Form.Control
                      className="form-control reffcss"
                      type="text"
                      placeholder=""
                      name="itemName"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className=" col-lg-12 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary  btn-sm float-right text-center custome-addnew-btn item-attach mb-5 mr-3"
                  >
                    Attach
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="row">
        <div className="col-lg-5 col-md-4">
          <h6>Challan And MRR</h6>
          <div className="card card-custom  p-0-">
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>Challan</th>
                  <th>MRR</th>
                  <th>Amount</th>
                  <th>Remove</th>
                </thead>
                <tbody>
                  {mrrList?.map((item, index) => (
                    <tr>
                      <td>{item.strExtnlReff}</td>
                      <td>{item.mrrID}</td>
                      <td>{item.monBDTTotal}</td>
                      <td>
                        <i className="fa fa-trash delete " />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-8">
          <h6>Pending Bill</h6>
          <div className="card card-custom cdesigncustom  p-0">
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>Bill Reg Code</th>
                  <th>Bill Rcv Date</th>
                  <th>Party Name</th>
                  <th>Bill Amount</th>
                  <th>RePrint</th>
                  <th>SPrint</th>
                  <th>Remove</th>
                </thead>

                <tbody>
                  <tr>
                    <td>Md. Khaledur Rahman</td>
                    <td>Permanent</td>
                    <td>Finance</td>
                    <td>Rahman</td>
                    <td>Permanent</td>
                    <td> Finance</td>
                  </tr>
                  <tr>
                    <td>1036</td>
                    <td>Md. Khaledur Rahman</td>
                    <td>Permanent</td>
                    <td>Accounts</td>
                    <td>Rahman</td>
                    <td>Permanent</td>
                    <td> Finance</td>
                  </tr>
                  <tr>
                    <td>1036</td>
                    <td>Md. Khaledur Rahman</td>
                    <td>Permanent</td>
                    <td>Finance</td>
                    <td>Rahman</td>
                    <td>Permanent</td>
                    <td>
                      <i className="fa fa-trash deletecss  " />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddBilling;
