
import * as Types from "../types/Types";
const initialState = {
    userInfo: {},
    employeeSearch: [],
};
const MovementReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_USER_INFORMATION:
            return {
                ...state,
                userInfo: action.payload.data
            };
        case Types.GET_INPUT_HANDLE:
            const inputData = { ...state.userInfo };
            inputData.employee_Name = action.payload.value;
            return {
                ...state,
                userInfo: inputData,
                employeeSearch: action.payload.value.length === 0 ? [] : action.payload.data
            };
        case Types.GET_FORM_RESET:
            const userData = { ...state.userInfo };
            userData.employee_Name = "";
            userData.unit_Name = "";
            userData.departmentName = "";
            return {
                ...state,
                userInfo: userData,
            };
        case Types.SELECT_HANDLE_SEARCH:
            const searchData = { ...state.userInfo };
            searchData.employee_Name = action.payload.zoneName;
            return {
                ...state,
                userInfo: searchData,
                employeeSearch: []
            };
        default:
            break;
    }
    return newState;
};

export default MovementReducer;

