
import { GetDdl } from "../../../../Global/GlobalDdl";
import * as Types from "../types/Types";
const initialState = {
    ddlSection: [],
    ddlSectionShift: [],
    isLoading: false,
    allCheckbox: false,
    remoteEmployee: [],
    attendanceStatus:[]

};
const RemoteAttendanceReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_SECTION_NAME:
            return {
                ...state,
                ddlSection: GetDdl(action.payload.data, 'zoneID', 'zoneName')
            };
        case Types.GET_SECTION_SHIFT:
            return {
                ...state,
                ddlSectionShift: GetDdl(action.payload.data, 'zoneID', 'zoneName')
            };
        case Types.GET_REMOTE_ATTENDANCE_EMPLOYEE:
            let newData = action.payload.data.slice();
            for (let i = 0; i < newData.length; i++) {
                newData[i].isPresent = false
            }
            return {
                ...state,
                remoteEmployee: newData
            };
        case Types.REMOTE_CHECKBOX_STATUS:
            let checkboxEm = state.remoteEmployee.slice();
            let allCheck = false;
            for (let i = 0; i < checkboxEm.length; i++) {
                if (checkboxEm[i].id == action.payload.data.item.id) {
                    checkboxEm[i].isPresent = !checkboxEm[i].isPresent

                }
            }
            return {
                ...state,
                remoteEmployee: checkboxEm,
                allCheckbox: allCheck
            };
        case Types.REMOTE_CHECKBOX_ALL:

            let chekboxData = state.remoteEmployee.slice();
            
            for (let i = 0; i < chekboxData.length; i++) {
                if (state.allCheckbox == true) {
                    chekboxData[i].isPresent = false
                } else {
                    chekboxData[i].isPresent = true
                }
            }
            return {
                ...state,
                allCheckbox: !state.allCheckbox,
                remoteEmployee: chekboxData,

            };
        case Types.GET_REMOTE_ATTENDANCE_STATUS:
            return {
                ...state,
                attendanceStatus:action.payload.data
            };
        default:
            break;
    }
    return newState;
};

export default RemoteAttendanceReducer;

