
import * as Types from "../../types/Types";
const initialState = {
    userInfo: {
        employeeName:null,
        jobStatus:null,
        designation:null,
        contactNo:null,
        fromDate:null,
        toDate:null,
        reason:null

    },
    employeeSearch:[],
};
const LeaveReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        
        case Types.HANDLE_LEAVE_INPUT:
            const inputData = { ...state.userInfo };
            inputData[action.payload.name] = action.payload.value;
            return {
                ...state,
                userInfo: inputData,
            };
        case Types.GET_FORM_RESET:
            const userData = { ...state.userInfo };
            userData.employee_Name = "";
            userData.unit_Name = "";
            userData.departmentName = "";
            return {
                ...state,
                userInfo: userData, 
            };
        case Types.SELECT_HANDLE_SEARCH:
         const searchData = { ...state.userInfo };
         searchData.employee_Name = action.payload.zoneName;
            return {
                ...state,
                userInfo: searchData, 
                employeeSearch:[]
            };
        default:
            break;
    }
    return newState;
};

export default LeaveReducer;

