import Axios from "axios";

import * as Types from "../../types/Types";

export const getUserInfomation = () => async (dispatch) => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    let responseList = {
        isLoading: true,
        data: user,
        status: true,
    };
    dispatch({ type: Types.GET_USER_INFORMATION, payload: responseList });
};
export const resetForm = () => async (dispatch) => {
    dispatch({ type: Types.GET_FORM_RESET, payload: null });
};
export const handleEmployeeInput = (name, value) => async (dispatch) => {
    let responseList = {
        name: name,
        value: value,
        data:{},
        status:false
    }
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
    await Axios.get(url).then((res) => {
        responseList.data = res.data.data;
        responseList.status = false;
    });
    console.log('responseList', responseList);
    dispatch({ type: Types.GET_INPUT_HANDLE, payload: responseList });
};

export const handleClickSearchItem = (item) => async (dispatch) => {
    dispatch({ type: Types.SELECT_HANDLE_SEARCH, payload: item });
};
