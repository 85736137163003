import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import RemoteAttendanceReducer from "../modules/hr/redux/reducers/RemoteAttendanceReducer";
import MovementReducer from "../modules/hr/redux/reducers/MovementReducer";
import LeaveReducer from "../modules/hr/redux/reducers/Leave/LeaveReducer";
import ZoneReducer from "../modules/hr/redux/reducers/zone/ZoneReducer";
import BillingReducer from "./Billing/reducers/BillingReducer";
import TaDaReducer from "../modules/hr/redux/reducers/tada/TaDaReducer";
import TadaReportReducer from "../modules/hr/redux/reducers/tada/TadaReportReducer";
import StandVehiclesReducer from "../modules/hr/redux/reducers/tada/StandVehiclesReducer";


export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  remoteAttendance:RemoteAttendanceReducer,
  movement:MovementReducer,
  leave:LeaveReducer,
  zone:ZoneReducer,
  billing:BillingReducer,
  tada:TaDaReducer,
  tadareport: TadaReportReducer,
  standvehicle: StandVehiclesReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
