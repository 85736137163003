import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Card } from "react-bootstrap";
import { getTadasingleApproved, getTaDAUnapprovedApprovedListDetails, getTaDAUnapprovedApprovedListForSupApproved,handleApprovedAmount } from "../../redux/actions/tada/TadaEntryAction";
import DataTable from "react-data-table-component"
import { toDate } from "date-fns";
import ModalImage from "react-modal-image";
import { dateTimeConvertLongString } from "./DateHelper";

const TadaDetails = ({ handleClose, itemID, itemDetailnIfo }) => {
  const ItemDetails = useSelector((state) => state.tada.tadaUnapprovedListforsupapproved);
  console.log('sup ItemDetails :>> ', ItemDetails);
  const ref = React.createRef();
  const [selectedData, setSelectedData] = React.useState([]);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaDAUnapprovedApprovedListForSupApproved(itemDetailnIfo.tadaId));
  }, []);

  const handleTadaUpdat=(item,value)=>{
    console.log('item', item);
    console.log('value', value);
    dispatch(handleApprovedAmount(item,value));
  }

  const amountwarning = (row,item) => {
    if (row.amount < item.value) {
      alert("Please select Date");
      return false;
    }else {
          return true;
      }
  }

  const tadadata = [
  
    {
      name: "TADA DATE",
      selector: (row) => dateTimeConvertLongString(row.tadaDate),
      sortable: true,
    },
    {
      name: "Amount",
      cell: (row) => <input type="number" 
      className="tadaC" 
      max={row.amount}
      value={row.amount} 
      onChange={(e)=>handleTadaUpdat(row,e.target.value)}

      />
    },
    {
      name: "VEHICLE NAME",
      selector: (row) => row.vehicleName,
      sortable: true,
    },
    {
      name: "FROM ADDRESS",
      selector: (row) => row.fromAddress,
      sortable: true,
    },
    {
      name: "TO ADDRESS",
      selector: (row) => row.toAddress,
      sortable: true,
    },
    {
      name: "CNG STATION ",
      selector: (row) => row.cngStationName,
      sortable: true,
    },
    {
      name: "FUEL TYPE",
      selector: (row) => row.fuelType,
      sortable: true,
    },
    {
      name: "PAYMENT TYPE",
      selector: (row) => row.payment,
      sortable: true,
    },
    {
      name: "TIME FROM",
      selector: (row) => row.timeFrom,
      sortable: true,
    },
    {
      name: "TIME TO",
      selector: (row) => row.timeTo,
      sortable: true,
    },
    {
      name: "MOVEMENT HOUR",
      selector: (row) => row.movementHour,
      sortable: true,
    },
    {
      name: "START MILAGE",
      selector: (row) => row.startMilage,
      sortable: true,
    },  
    {
      name: "END MILAGE",
      selector: (row) => row.endMilage,
      sortable: true,
    },  
    {
      name: "CONSUME MILAGE",
      selector: (row) => row.consumeMilage,
      sortable: true,
    },
    {
      name: "CONTACT PERSON",
      selector: (row) => row.contactPerson,
      sortable: true,
    },  
    {
      name: "CONTACT NUMBER",
      selector: (row) => row.contactNumber,
      sortable: true,
    },  
    {
      name: "REMARKS",
      selector: (row) => row.remarks,
      sortable: true,
    }, 
    {
      name: "Attachment",
      cell: (row) =>
          <ModalImage
            small={`${process.env.REACT_APP_API_PUBLIC_URL}User_Files/Articles/Images/User_Files/${row.file}`}
            large={`${process.env.REACT_APP_API_PUBLIC_URL}User_Files/Articles/Images/User_Files/${row.file}`}
          />
   },   
    
];

const handleChange1 = (state) => {
    setSelectedData(state.selectedRows);
    console.log('state.selectedRows',state.selectedRows);
  };
const handleSingleApproveForSuperviser =()=>{

  dispatch(getTadasingleApproved(ItemDetails,itemID));
  handleClose(true);
  
}

// const dateSet = {
//   fromDate:startDate,
//   toDate :toDate
// }
// console.log('dateSet', dateSet);



  return (
    <>
    {/* <div className="card card-custom mb-2 pl-5 pr-5 p-1 "> */}
         <DataTable
          columns={tadadata}
          data={ItemDetails}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='500px'
          selectableRowsHighlight
          highlightOnHover
          //selectableRows
          //onSelectedRowsChange={handleChange1}
          // actions

        />
        {/* </div> */}
        <div className="col-lg-12">
        <button className="btn btn-primary btn-sm float-right mb-5" onClick={()=>handleSingleApproveForSuperviser()}>Approved</button>
        </div>
     
      <Button
        variant="secondary"
        classNameNameName="float-right"
        onClick={handleClose}
      >
        Close
      </Button>
    </>
  );
};

export default TadaDetails;
