import Axios from "axios";
import * as Types from '../../types/Types';


export const handleLeaveInput=(value,name)=>(dispatch)=>{
    const formData = {
        name: name,
        value: value,
      };
    dispatch({ type: Types.HANDLE_LEAVE_INPUT, payload: formData })
}


