import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Barcode from "react-barcode";
import { GetPartyLedger, GetPoDetails, InputHandle } from "../../../../redux/Billing/action/AddBillingAction";
import LoadingSpinner from "../../../../modules/Billing/Components/LoadingSpinner";
var address = require("address");
console.log(address.ip());

const TADAEntry = () => {
  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  
  const ddlUnitDrop = useSelector((state) => state.billing.ddlUnit);
  const ddlPartyType = useSelector((state) => state.billing.ddlPartyType);
  const ddlPartyName = useSelector((state) => state.billing.ddlPartyName);
  const ddlPartyLedger = useSelector((state) => state.billing.ddlPartyLedger);
  const input = useSelector((state) => state.billing.itemDataInput);
  const po = useSelector((state) => state.billing.poDetails);
  const defaultUnit = useSelector((state) => state.billing.defaultUnit);
  const [rhDisable, setRhDisabled] = useState(true);
  const [startDate, setStartDate] = useState(null);

  const refLoading = useSelector((state) => state.billing.refLoading);

  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const onSubmitLeave = () => {
    alert();
  };

  const goToPoDetails = () => {
    dispatch(GetPoDetails(input.referenceNo));
  };

  const inputChangeHandle = (name, value) => {
  

    dispatch(InputHandle(name, value));
  };

  const onSubmit = async (e) => {};



  return (
    <>
      <div className="card card-custom mb-2 pl-5 pr-5  card-top-border p-1 ">
        <div className="row">
          <div className="col-lg-12 m-4">
            <h3>TA DA Entry (Employees whom have no offical Email address)</h3>
          </div>
        </div>
        {/* <hr></hr> */}

        <form
          className="form form-label-right  mb-6 "
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          encType="multipart/form-data"
          autoComplete="off"
        >
          <div className="row">
            <div className="col-lg-12 square border border-1">
              <div className="form-group row">

              <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">From Date</label>
                          {
                            <Form.Control
                              type="date"
                              placeholder="Enter Deadweight"
                              name="fromDate"
                              className="form-control reffcss"
                            />
                          }
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Movement Duration(Hour)</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Fill by digit"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Designation</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Designation"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Employee Name</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Type Name"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Code</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Department</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Department"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Present Address</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Type Present Adsdress"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Movement Area</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Designation"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">To Address</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Type To Address"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Bus</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Bus"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Rickshaw</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Rickshaw"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">CNG</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="CNG"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Train</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Train"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Boat</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Boat"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Another Transport</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Another Transport"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Own DA</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Own DA"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Entertainment</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Entertainment"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Hotel</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Hotel"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Other Cost</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Other Cost"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Remarks</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Remarks"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Total Cost</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Total Cost"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Contact Person</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Name"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Phone</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Number"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Visited Place</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Address"
                      name="itemName"
                      onChange={(e) => {
                        inputChangeHandle("referenceNo", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>


                <>
                  {!refLoading && (
                    <>
                      
                

                      <div className="col-lg-12 mt-5">
                        {/* <button
                          type="submit"
                          className="btn btn-primary  btn-sm float-right text-center custome-addnew-btn item-attach mb-5 mr-4 "
                        >
                          Document View
                        </button> */}
                        <button
                          type="submit"
                          className="btn btn-primary  btn-sm float-right text-center custome-addnew-btn item-attach mb-5 mr-3"
                        >
                          Save
                        </button>
                      </div>
                    </>
                  )}
                  {refLoading && (
                    <LoadingSpinner text="Loading Items List..." />
                  )}
                </>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* <div className="row">
        <div className="col-lg-5 col-md-4">
          <h6>Challan And MRR</h6>
          <div className="card card-custom  p-0-">
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>Challan</th>
                  <th>MRR</th>
                  <th>Amount</th>
                  <th>Remove</th>
                </thead>
                <tbody>
                  {mrrList?.map((item, index) => (
                    <tr>
                      <td>{item.strExtnlReff}</td>
                      <td>{item.mrrID}</td>
                      <td>{item.monBDTTotal}</td>
                      <td>
                        <i className="fa fa-trash delete " />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-8">
          <h6>Pending Bill</h6>
          <div className="card card-custom cdesigncustom  p-0">
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>Bill Reg Code</th>
                  <th>Bill Rcv Date</th>
                  <th>Party Name</th>
                  <th>Bill Amount</th>
                  <th>RePrint</th>
                  <th>SPrint</th>
                  <th>Remove</th>
                </thead>

                <tbody>
                  <tr>
                    <td>Md. Khaledur Rahman</td>
                    <td>Permanent</td>
                    <td>Finance</td>
                    <td>Rahman</td>
                    <td>Permanent</td>
                    <td> Finance</td>
                  </tr>
                  <tr>
                    <td>1036</td>
                    <td>Md. Khaledur Rahman</td>
                    <td>Permanent</td>
                    <td>Accounts</td>
                    <td>Rahman</td>
                    <td>Permanent</td>
                    <td> Finance</td>
                  </tr>
                  <tr>
                    <td>1036</td>
                    <td>Md. Khaledur Rahman</td>
                    <td>Permanent</td>
                    <td>Finance</td>
                    <td>Rahman</td>
                    <td>Permanent</td>
                    <td>
                      <i className="fa fa-trash deletecss  " />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default TADAEntry;
