import React from "react";
import StandVehicleDetails from "./StandVehicleDetails";


const StandVehicleDetailsContainer = () => {
  return (
    <div className="container-fluid">
        <StandVehicleDetails />
    </div>
  );
};
export default StandVehicleDetailsContainer;
