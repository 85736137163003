import React from "react";
import DailyBasisVehicleReport from "./DailyBasisVehicleReport";

const DailyBasisVehicleReportContainer = () => {
  return (
    <div className="container-fluid">
        <DailyBasisVehicleReport />
    </div>
  );
};
export default DailyBasisVehicleReportContainer;
