import React, { useRef  } from 'react';
import { Button, Dropdown, Card } from "react-bootstrap";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactToPrint from 'react-to-print-advanced';
import { currentdate } from '../../../hr/components/tada/DateHelper';

const DownloadDropdown = (props) => {

    console.log('props', props);


    const current = currentdate();
    const excelName = props.excelname ==undefined?"TableExcel":props.excelname+" "+ current
    const componentRef = props.refvalue;

   
    return (
        <>
            <Dropdown className="d-inline mr-2">
                <Dropdown.Toggle
                    className="btn btn-sm "
                    variant="success"
                    id="dropdown-basic"
                >
                    Export
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-2">
                        <i class="far fa-file-excel mt-1"></i>
                        {
                             <ReactHTMLTableToExcel
                             id="test-table-xls-button"
                             className="excelBtn ml-1"
                             table="table-to-xls"
                             filename={excelName}
                             sheet="tablexls"
                             buttonText="XLS download"/>
                        }
                    </Dropdown.Item>
                    <Dropdown.Item >
                        <i class="far fa-file-excel mt-1"></i>
                        {
                            <ReactToPrint
                              trigger={() => <button>Print</button>}
                              content={() => componentRef.current}
                              pageStyle="marging:10"
                            />
                        }
                    </Dropdown.Item>
                    
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}
export default DownloadDropdown;