import React from "react";
import TADASupervisorUnApprovedListDetails from "./TADASupervisorUnApprovedListDetails";



const TADASupervisorUnApprovedListDetailsContainer = () => {
  return (
    <div className="container-fluid">
     
        <TADASupervisorUnApprovedListDetails />
      
    </div>
  );
};
export default TADASupervisorUnApprovedListDetailsContainer;
