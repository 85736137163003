
import moment from "moment/moment";
import { GetDdl } from "../../../../../Global/GlobalDdl";
import * as Types from "../../types/Types";
const initialState = {
  tadaSubmitStatus: false,

  itemDataInputForStandVehicleEntry: {
    startDate: "",
    vehicleType: "",
    employeeName:"",
    cngStation: null,
    fuelType: null,
    timeFrom: "",
    timeTo: "",
    movementHour: "0",
    fromAddress: "",
    toAddress: "",
    startMilage: "0",
    endMilage: "0",
    consumeMilage: "0",
    daType: "",
    paymentType: "",
    remarks: "",
    amount: "",
    contactPerson: "",
    contactNumber: "",
    fileName: "",
    attachment: " ",
    vehicleID: "",
    persoanlMilage:"",
    PersonalMilageAmount:0,

  },
  submitData: {
    startDate: " ",
    vehicleType: "",
    employeeName:"",
    cngStation: "",
    fuelType: "",
    timeFrom: "",
    timeTo: "",
    movementHour: "",
    fromAddress: "",
    toAddress: "",
    startMilage: "",
    endMilage: "",
    consumeMilage: "",
    daType: "",
    paymentType: "",
    remarks: "",
    amount: " ",
    contactPerson: "",
    contactNumber: "",
    attachment: " ",
    vehicleID: "",
  },

  ddFuleLogVehicle: [],
  ddDriverName: [],
  itemRow: [],
  dailybasislist:[],
  monthlybasislist:[],
};

const StandVehiclesReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {

    case Types.CHANGE_TADA_STAND_VEHICLE_ENTRY:
      const itemDataInputForStandVehicleEntry = {
        ...state.itemDataInputForStandVehicleEntry,
      };
      itemDataInputForStandVehicleEntry[action.payload.name] = action.payload.value;
      return {
        ...state,
        itemDataInputForStandVehicleEntry,
      };

    case Types.GET_FUEL_LOG_VEHICLE:
      return {
        ...state,
        ddFuleLogVehicle: GetDdl(action.payload.data, "vehicleId", "vehicleNo"),
      };
    case Types.GET_DIVER_NAME:
      return {
        ...state,
        ddDriverName: GetDdl(action.payload.data, "enroll", "empName"),
      };

      case Types.CHANGE_CONSUME_MILAGE:
      const itemConsumeMilage = {
        ...state.itemDataInputForStandVehicleEntry,
      };
      let startMilage = 0
      let endMilage = 0
      let consumeMilage = 0;
      if (action.payload.name == "endMilage") {
        endMilage = state.itemDataInputForStandVehicleEntry.endMilage
      }
      if (action.payload.name == "startMilage") {
        startMilage = state.itemDataInputForStandVehicleEntry.startMilage
      }
      itemConsumeMilage.consumeMilage = state.itemDataInputForStandVehicleEntry.endMilage - state.itemDataInputForStandVehicleEntry.startMilage
      return {
        ...state,
        itemDataInputForStandVehicleEntry: itemConsumeMilage,
      };

    case Types.TADA_ROW_CREATE_FOR_STAND_VEHICLE:
      let standvehicleArr = state.itemRow.slice();
      let standvehicleD = {
        rowID: standvehicleArr.length + 1,
        startDate: action.payload.startDate,
        vehicleType: action.payload.vehicleType,
        cngStation: action.payload.cngStation,
        employeeName: action.payload.employeeName,
        vehicleName: action.payload.vehicleName,
        billName: action.payload.billName,
        costName: action.payload.costName,
        paymentName: action.payload.paymentName,
        cngStationName: action.payload.cngStationName,
        fuelName: action.payload.fuelName,
        employeeFullName: action.payload.employeeFullName,
        fuelType: action.payload.fuelType,
        timeFrom: action.payload.timeFrom,
        timeTo: action.payload.timeTo,
        movementHour: action.payload.movementHour,
        fromAddress: action.payload.fromAddress,
        toAddress: action.payload.toAddress,
        startMilage: action.payload.startMilage,
        endMilage: action.payload.endMilage,
        consumeMilage: action.payload.consumeMilage,
        daType: action.payload.daType,
        paymentType: action.payload.paymentType,
        remarks: action.payload.remarks,
        amount: parseInt(action.payload.amount),
        contactPerson: action.payload.contactPerson,
        contactNumber: action.payload.contactNumber,
        attachment: action.payload.attachment,
        fileName: action.payload.fileName,
        vehicleID: action.payload.vehicleID,
        persoanlMilage: action.payload.persoanlMilage,

      };
      standvehicleArr.push(standvehicleD);
      return {
        ...state,
        itemRow: standvehicleArr,
      };

      case Types.TADA_STAND_VEHICLE_SUBMIT:
        return {
          ...state,
          submitLoading: false,
          tadaSubmitStatus: true,
        };
      case Types.SUBMIT_STATUS_REMOVE:
      return {
        ...state,
        tadaSubmitStatus: false,
      };
      case Types.TADA_ROW_DELETE:
        return {
          ...state,
          itemRow: state.itemRow.filter((item) => item.rowID !== action.payload),
        };
        case Types.CLEAR_FIELD_FOR_STAND_VEHICLE:
          const changedvalueforstandvehicle = {
            ...state.itemDataInputForStandVehicleEntry,
          };
          changedvalueforstandvehicle.vehicleType= ""
          changedvalueforstandvehicle.amount= ""
          changedvalueforstandvehicle.billType= ""
          changedvalueforstandvehicle.paymentType= ""
          changedvalueforstandvehicle.fuelType= ""
          changedvalueforstandvehicle.persoanlMilage= ""
          changedvalueforstandvehicle.startMilage= "0"
          changedvalueforstandvehicle.endMilage= "0"
          changedvalueforstandvehicle.consumeMilage= "0"
          changedvalueforstandvehicle.paymentType=null
          changedvalueforstandvehicle.remarks= ""
          changedvalueforstandvehicle.amount= ""
          changedvalueforstandvehicle.billType= ""
          changedvalueforstandvehicle.fuelName= ""
          changedvalueforstandvehicle.cngStationName= ""
          changedvalueforstandvehicle.costName= ""
          changedvalueforstandvehicle.paymentName= ""
          
          return {
            ...state,
            itemDataInputForStandVehicleEntry:changedvalueforstandvehicle,
          };
          case Types.GET_DAILY_BASIS_REPORT:
            return {
              ...state,
              dailybasislist: action.payload.data,
              
            };
            case Types.GET_MONTHLY_BASIS_REPORT:
              console.log('monthly action.payload.data :>> ', action.payload.data);
            return {
              ...state,
              monthlybasislist: action.payload.data,
              
            };


    default:
      break;
  }
  return newState;
};

export default StandVehiclesReducer;
