import axios from "axios";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export async function login(username, password) {
  const data = {
    username: username,
    password: password,
  };

  const app_version = "1.0.0";
  // let username = data.username;
  let loginResponse = {
    status: false,
    message: "",
    isLoading: true,
    access_token: "",
    userData: null,
    shipUser: null,
    moduleLists: [],
    data: null
  };
    const url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Auth/UserLoginWeb`;
   //const url = `https://remotesales.akijinsaf.com/api/OAuth/UserLoginWeb`;
   console.log('url', url);

 

  try {
    await axios
      .post(url, data)
      .then(async (res) => {

        const { status, data, message,code,info } = res.data;
        
       

       
        // return false;

    
        let { user } = data;
      
       
        // loginResponse.data = data;
        // loginResponse.status = true;
        if (code==200) {
          loginResponse.access_token = data.token;
          loginResponse.message = message;
          loginResponse.status = true;
          loginResponse.userData = info;
          localStorage.setItem('access_token', data.token);

        }
      })
      .catch(async (error) => {
        console.log('error', error);
        loginResponse.message = "Invalid Username and password";
        loginResponse.status = false;
      });

    
      // return false;
    // Do More..

    
    // localStorage.setItem("userData", JSON.stringify(loginResponse.userData));
    localStorage.setItem("userData", JSON.stringify(loginResponse.userData));
    loginResponse.isLoading = false;
    return loginResponse;
  } catch (error) {
    loginResponse.isLoading = false;
    loginResponse.message =
      "❌ Network Not Available !\nConnect to Wifi or Internet Connection";
    return loginResponse;
  }
}


export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getEmployeData() {
  // Authorization head should be fulfilled in interceptor.
  let userData = localStorage.getItem("userData");
  let employeeData = JSON.parse(userData);
  return employeeData;
}

export function getVesselId() {
  let data = getEmployeData();
  let intVesselId = null;
  if (typeof data !== "undefined" && data != null) {
    intVesselId = data.intVesselId;
  }
  if (typeof intVesselId !== "undefined" && intVesselId != null)
    return intVesselId;
  return "";
}

export function getCargoId() {
  let data = getEmployeData();
  let intCargoId = null;
  if (typeof data !== "undefined" && data != null) {
    intCargoId = data.intCargoId;
  }
  if (typeof intCargoId !== "undefined" && intCargoId != null)
    return intCargoId;
  return "";
}

export function getEmployeeId() {
  let data = getEmployeData();
  if (typeof data !== "undefined" && data != null) return data.intEmployeeId;
}
