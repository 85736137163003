
export const GET_SECTION_NAME = "GET_SECTION_NAME";
export const GET_SECTION_SHIFT = "GET_SECTION_SHIFT";
export const GET_REMOTE_ATTENDANCE_EMPLOYEE = "GET_REMOTE_ATTENDANCE_EMPLOYEE";
export const REMOTE_CHECKBOX_STATUS = "REMOTE_CHECKBOX_STATUS";
export const REMOTE_CHECKBOX_ALL = "REMOTE_CHECKBOX_ALL";
export const GET_REMOTE_ATTENDANCE_STATUS = "GET_REMOTE_ATTENDANCE_STATUS";

/*
 * Movement Types
*/
export const GET_USER_INFORMATION = "GET_USER_INFORMATION";
export const GET_FORM_RESET = "GET_FORM_RESET";
export const GET_INPUT_HANDLE = "GET_INPUT_HANDLE";
export const SELECT_HANDLE_SEARCH = "SELECT_HANDLE_SEARCH";


/*
 * Leave
*/
export const HANDLE_LEAVE_INPUT = "HANDLE_LEAVE_INPUT";
export const GET_ZONE_DATA = "GET_ZONE_DATA";
export const GET_AREA_DATA_BY_ZONE = "GET_AREA_DATA_BY_ZONE";
export const GET_AREA_LIST_BY_AREA = "GET_AREA_LIST_BY_AREA";

// TADA
export const CHANGE_BIKE_CAR_TADA_INPUT = "CHANGE_BIKE_CAR_TADA_INPUT";
export const PRODUCT_SUBMIT_STATUS_REMOVE = "PRODUCT_SUBMIT_STATUS_REMOVE";
export const CHANGE_TADA_INPUT_FOR_NON_BIKE_CAR = "CHANGE_TADA_INPUT_FOR_NON_BIKE_CAR";
export const GET_TADA_DATA = "GET_TADA_DATA";
export const TADA_ROW_CREATE_FOR_NON_BIKECAR = "TADA_ROW_CREATE_FOR_NON_BIKECAR";
export const TADA_ROW_DELETE = "TADA_ROW_DELETE";
export const CHANGE_TADA_BIKE_CAR_ENTRY = "CHANGE_TADA_BIKE_CAR_ENTRY";
export const TADA_ROW_CREATE_FOR_BIKECAR = "TADA_ROW_CREATE_FOR_BIKECAR";
export const GET_DA = "GET_DA";
export const GET_TA = "GET_TA";
export const GET_PAYMENT_TYPE = "GET_PAYMENT_TYPE";
export const GET_FILLING_STATION = "GET_FILLING_STATION";
export const TADA_BIKE_CAR_SUBMIT = "TADA_BIKE_CAR_SUBMIT";
export const TADA_NON_BIKE_CAR_SUBMIT = "TADA_NON_BIKE_CAR_SUBMIT";
export const GET_TADA_LIST = "GET_TADA_LIST";
export const CHANGE_MOVEMENT_HOUR = "CHANGE_MOVEMENT_HOUR";
export const CHANGE_CONSUME_MILAGE = "CHANGE_CONSUME_MILAGE";
export const DELETE_TADA_LIST = "DELETE_TADA_LIST";
export const GET_TADA_UNAPPROVED_LIST = "GET_TADA_UNAPPROVED_LIST";
export const GET_END_MILAGE = "GET_END_MILAGE";
export const GET_TADA_UNAPPROVED_LIST_MONTHLY = "GET_TADA_UNAPPROVED_LIST_MONTHLY";
export const GET_TADA_UNAPPROVED_LIST_DAILY = "GET_TADA_UNAPPROVED_LIST_DAILY";
export const GET_TADA_UNAPPROVED_LIST_FOR_SUP_APPROVED = "GET_TADA_UNAPPROVED_LIST_FOR_SUP_APPROVED";
export const GET_TADA_APPROVED_FOR_SUPERVISER = "GET_TADA_APPROVED_FOR_SUPERVISER";
export const CHANGE_MODAL_ENTRY = "CHANGE_MODAL_ENTRY";
export const CHANGE_TADA_APPROVED_AMOUNT = "CHANGE_TADA_APPROVED_AMOUNT";
export const CHANGE_TADA_HR_APPROVED_ENTRY = "CHANGE_TADA_HR_APPROVED_ENTRY";
export const GET_NAME_LIST = "GET_NAME_LIST";
export const SUBMIT_STATUS_REMOVE = "SUBMIT_STATUS_REMOVE";
export const CLEAR_TADA_LIST = "CLEAR_TADA_LIST";
export const GET_AREA_LIST = "GET_AREA_LIST";
export const GET_TADA_UNAPPROVED_LIST_MONTHLY_FOR_HR = "GET_TADA_UNAPPROVED_LIST_MONTHLY_FOR_HR";
export const GET_EMP_NAME_SET = "GET_EMP_NAME_SET";
export const CHANGE_HANDLE_SEARCH_INPUT = "CHANGE_HANDLE_SEARCH_INPUT";

export const GET_TADA_UNAPPROVED_LIST_DAILY_FOR_HR = "GET_TADA_UNAPPROVED_LIST_DAILY_FOR_HR";
export const GET_TADA_APPROVED_FOR_HR = "GET_TADA_APPROVED_FOR_HR";
export const GET_TADA_UNAPPROVED_LIST_MONTHLY_FOR_AUDIT = "GET_TADA_UNAPPROVED_LIST_MONTHLY_FOR_AUDIT";
export const GET_TADA_UNAPPROVED_LIST_DAILY_FOR_AUDIT = "GET_TADA_UNAPPROVED_LIST_DAILY_FOR_AUDIT";
export const GET_TADA_UNAPPROVED_LIST_FOR_AUDIT_APPROVED = "GET_TADA_UNAPPROVED_LIST_FOR_AUDIT_APPROVED";
export const GET_TADA_UNAPPROVED_LIST_FOR_HR_APPROVED = "GET_TADA_UNAPPROVED_LIST_FOR_HR_APPROVED";
export const GET_TADA_APPROVED_FOR_AUDIT = "GET_TADA_APPROVED_FOR_AUDIT";
export const CHANGE_TADA_APPROVED_AMOUNT_FOR_HR = "CHANGE_TADA_APPROVED_AMOUNT_FOR_HR";
export const CHANGE_TADA_APPROVED_AMOUNT_FOR_AUDIT = "CHANGE_TADA_APPROVED_AMOUNT_FOR_AUDIT";
// reports
export const GET_TADA_REPORT_AT_A_GLANCE = "GET_TADA_REPORT_AT_A_GLANCE";

//fuel log vehicle
export const GET_FUEL_LOG_VEHICLE = "GET_FUEL_LOG_VEHICLE";
export const CHANGE_TADA_STAND_VEHICLE_ENTRY = "CHANGE_TADA_STAND_VEHICLE_ENTRY";
export const TADA_ROW_CREATE_FOR_STAND_VEHICLE = "TADA_ROW_CREATE_FOR_STAND_VEHICLE";
export const GET_DIVER_NAME = "GET_DIVER_NAME";
export const TADA_STAND_VEHICLE_SUBMIT = "TADA_STAND_VEHICLE_SUBMIT";
export const CLEAR_FIELD_FOR_TADA = "CLEAR_FIELD_FOR_TADA";
export const CLEAR_FIELD_FOR_TADA_FOR_NON_BIKE = "CLEAR_FIELD_FOR_TADA_FOR_NON_BIKE";
export const GET_TADA_COST_DETAILS = "GET_TADA_COST_DETAILS";
export const CLEAR_FIELD_FOR_NONBIKE = "CLEAR_FIELD_FOR_NONBIKE";
export const CLEAR_FIELD_FOR_NONBIKE_PUBLIC = "CLEAR_FIELD_FOR_NONBIKE_PUBLIC";
export const GET_BILL_TYPE = "GET_BILL_TYPE";
export const GET_FUEL_TYPE = "GET_FUEL_TYPE";
export const CLEAR_FIELD_FOR_STAND_VEHICLE = "CLEAR_FIELD_FOR_STAND_VEHICLE";
export const GET_DAILY_BASIS_REPORT = "GET_DAILY_BASIS_REPORT";
export const GET_MONTHLY_BASIS_REPORT = "GET_MONTHLY_BASIS_REPORT";
export const GET_FUEL_STATION_BILL_REPORT = "GET_FUEL_STATION_BILL_REPORT";





