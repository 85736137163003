import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { RHFInput } from 'react-hook-form-input';
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
    allCheckBoxSelect,
    checkboxStatusChange,
    getRemoteAttendanceEmployee,
    getSection,
    getSectionShift
} from '../../../redux/actions/RemoteAttendanceAction';
import { Checkbox } from '../../../../../_metronic/_partials/controls';
import { getZoneList, getDivisionListByZone, getAreaListByDivision } from '../../../redux/actions/zone/ZoneAction';



const AttendanceSummeryList = () => {
    const { handleSubmit, register, setValue, reset } = useForm();
    const dispatch = useDispatch();

    const [fakeData, setFakeData] = useState([
        {
            id: 1,
            name: "farid uddin",
            designation: 'Officer'
        },
        {
            id: 2,
            name: "farid uddin",
            designation: 'Officer'
        },
        {
            id: 3,
            name: "farid uddin",
            designation: 'Officer'
        },
        {
            id: 4,
            name: "farid uddin",
            designation: 'Officer'
        },
    ]);

    const ddlZone = useSelector((state) => state.zone.ddlZone);
    const ddlDivision = useSelector((state) => state.zone.ddlDivision);
    const ddlSectionShift = useSelector((state) => state.remoteAttendance.ddlSectionShift);
    const employeeList = useSelector((state) => state.remoteAttendance.remoteEmployee);
    const allCheckbox = useSelector((state) => state.remoteAttendance.allCheckbox);

    const [selectZone, setSelectZone] = useState(null);
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);


    useEffect(() => {
        dispatch(getSection());
        dispatch(getSectionShift());
        dispatch(getZoneList())
    }, []);

    const inputChangeHandle = (name, value) => {
        console.log('name', name);
        console.log('value', value);
    }
    const handleCheckBox = (item, index) => {
        dispatch(checkboxStatusChange(item, index));
    }
    const handleAllCheckBox = (e) => {
        dispatch(allCheckBoxSelect());
    }

    return (
        <>
            <div className="card card-custom mb-2 pl-5 pr-5  card-top-border p-3">
                <div className="row">
                    <div className="col-md-9">
                        <div className="p-2">
                            <h3>Remote Attendance</h3>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <Form>

                    <div className="form-group row">
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Zones</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={ddlZone} />}
                                    rules={{ required: false }}
                                    name="zoneData"
                                    register={register}
                                    value={selectZone}
                                    value=""
                                    onChange={(option) => {
                                        setSelectZone(option.value);
                                        dispatch(getDivisionListByZone(option.value));
                                    }}
                                    setValue={setValue}
                                />

                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Division</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={ddlDivision} />}
                                    rules={{ required: false }}
                                    name="divisionData"
                                    register={register}
                                    value=""
                                    // value={CertificateMasterInput.isActive}
                                    onChange={(option) => {
                                        setSelectDivision(option.value)
                                        dispatch(getAreaListByDivision(option.value))
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Area</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={ddlDivision} />}
                                    rules={{ required: false }}
                                    name="areaData"
                                    register={register}
                                    value=""
                                    onChange={(option) => {
                                        setSelectArea(option.value);
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>

                        {/* ststt */}
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Section Name</label>
                                <RHFInput
                                    as={<Select options={ddlZone} />}
                                    rules={{ required: true }}
                                    name="reactSelect"
                                    register={register}
                                    setValue={setValue}
                                    onChange={(option) => {
                                        inputChangeHandle("distributorName", option.label);
                                        inputChangeHandle("intDistributorId", option.value);
                                        dispatch(getRemoteAttendanceEmployee(fakeData));
                                    }}
                                
                                />
                            </Form.Group>
                        </div>
                        
                    </div>
                </Form>
            </div>
            <div className="card card-custom  p-5">
                <div className="react-bootstrap-table table-responsive">
                    {employeeList && employeeList.length === 0 && (
                        <div class="alert alert-danger" role="alert">
                            Sorry! No Data Found.
                        </div>
                    )}
                    {employeeList && employeeList.length > 0 && (
                        <table className="table table table-head-custom table-vertical-center">
                            <thead>
                                <th><Checkbox isSelected={allCheckbox} onChange={(e) => handleAllCheckBox(e)} /></th>
                                <th>Enroll</th>
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Job Type</th>
                                <th>Address</th>
                            </thead>
                            <tbody>
                                {employeeList.map((item, index) => (
                                    <tr>
                                        <td>
                                            <Checkbox isSelected={item.isPresent} onChange={() => handleCheckBox(item, index)} />
                                        </td>
                                        <td>R658</td>
                                        <td>Md. Khaledur Rahman</td>
                                        <td>Permanent</td>
                                        <td>Accounts And Finance</td>
                                        <td> Finance	Assistant Manager</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    )
}
export default AttendanceSummeryList;
