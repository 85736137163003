

import { GetDdl } from "../../../../../Global/GlobalDdl";
import * as Types from "../../types/Types";
const initialState = {
    ddlZone: [],
    ddlDivision: [],
    ddlArea: [],
    ddlSectionShift: [],
    isLoading: false,
    allCheckbox: false,
    remoteEmployee: [],
    attendanceStatus:[]

};
const ZoneReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_ZONE_DATA:
            return {
                ...state,
                ddlZone: GetDdl(action.payload.data, 'zoneID', 'zoneName')
            };
        case Types.GET_AREA_DATA_BY_ZONE:
            return {
                ...state,
                ddlDivision: GetDdl(action.payload.data, 'divisionID', 'divisionName')
            };
        case Types.GET_AREA_LIST_BY_AREA:
            return {
                ...state,
                ddlArea: GetDdl(action.payload.data, 'areaID', 'areaName')
            };
        default:
            break;
    }
    return newState;
};

export default ZoneReducer;

