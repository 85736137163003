import React from 'react';
import MovementList from '../../../components/remoteAttendance/movements/MovementList';



const MovementsContainer = () => {

    return (
        <div className="container">
            <div className="">
                <MovementList />
            </div>
        </div>
    )

}
export default MovementsContainer;
