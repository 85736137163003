/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">HR</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/hr/remoteAttendance">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Remote Attendance</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/hr/attendanceStatus">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Attendance Status</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/hr/calender">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Attendance Calender</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/hr/leaveapplication">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Leave Application</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/area/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Leave Approval</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/hr/movement">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Movement</span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>

            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Billing</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/billing/addBilling">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Add Billing</span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>

            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">TA DA</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/tada/TADABikeCarEntry">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TA DA Bike Car Entry</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/TADABikeCarEntryPublic">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TADA Bike Car Entry Public</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/TADAForNonBikeCar">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TA DA Entry(For Non Bike/Car)</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/TADANonBikeCarEntryPublic">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TADA NON Bike Car Entry Public</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/TaDaList">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TA DA Delete</span>
                  <i className="menu-arrow" />
                </NavLink>
                
                <NavLink className="menu-link menu-toggle" to="/tada/TADASupervisorUnApprovedList">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TA DA Supervisor Unapproved List</span>
                  <i className="menu-arrow" />
                </NavLink>
                {/* <NavLink className="menu-link menu-toggle" to="/TADASupervisorUnApprovedListDetails">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TA DA Supervisor UnApproved List Details</span>
                  <i className="menu-arrow" />
                </NavLink> */}
                <NavLink className="menu-link menu-toggle" to="/tada/TadaApprovedForHR">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TA DA Approved By HR</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/TadaApprovedForAudit">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TA DA Approved By Audit</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/TadaReport">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TA DA Approved List</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/StandVehicleDetails">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Stand Vehicle Entry</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/TadaUserCostDetailsReport">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">TADA User Cost Details Report</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/DailyBasisVehicleReport">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Daily Basis Vehicle Report</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/MonthlyBasisVehicleReport">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Monthly Basis Vehicle Report</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/tada/FuelStationBillReport">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Fuel Station Bill Report</span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>

            </ul>
          </div>
        </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
