import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import SimpleModal from "./../SimpleModal";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { clearTadaList, GetAreaListForHR, getNameList, getTaDAList, handleImageDelete, handleSearchInput, hanleChangeInputforbikecarentry, hanlesetInputforempname, imageUpload, tadaDelete } from '../../redux/actions/tada/TadaEntryAction';
import { dateTimeConvertLongString } from "./DateHelper";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import DataTable from "react-data-table-component"
import AlertConfirm from "react-alert-confirm";
import "react-alert-confirm/lib/style.css";
import { Lightbox } from "react-modal-image";
import ModalImage from "react-modal-image";
import { getDayBasisVehicleReport, getMonthlyBasisVehicleReport, getTADAAtaGlancee, getTadaUserCostDetailsReport } from "../../redux/actions/tada/TadaReportAction";
import { getEmployeData } from "../../../../app/modules/Auth/_redux/authCrud";
import DownloadDropdown from "../../../master/components/PrintDwopdown/DownloadDropdown";
import { GetStandVehicle, hanleChangeInputforStandVehicleEntry } from "../../redux/actions/tada/StandVehiclesAction";


const MonthlyBasisVehicleReport = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();

    const history = useHistory();
    const dispatch = useDispatch();
    const [selectedData, setSelectedData] = React.useState();

    const monthlybasislist = useSelector((state) => state.standvehicle.monthlybasislist);
    const ddFuleLogVehicle = useSelector((state) => state.standvehicle.ddFuleLogVehicle);
    const areaList = useSelector((state) => state.tada.areaList);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectVehicleName, setSelectVehicleName] = useState(null);
    const [selectJobStation, setSelectJobStation] = useState(null);
    const [selectReportType, setSelectReportType] = useState(null);


    useEffect(() => {
        dispatch(GetStandVehicle());
        dispatch(GetAreaListForHR());

    }, [])


    const componentRef = useRef();
    console.log('componentRef', componentRef);
    let ref = React.createRef();
    const inputField = React.useRef(null);

    console.log('inputField :>> ', inputField);


    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };

    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };


    const showReport = () => {
        dispatch(getMonthlyBasisVehicleReport(selectVehicleName?.value, startDate, toDate, selectJobStation));

    }

    const tbodies = monthlybasislist.result?.map((state, index) => {
        const cityValues = Object.values(state);
        // const cityRows = cityValues.map((city, i) => {
        // const stateName = i === 0 ? <td rowSpan={cityValues.length + 1}>{dateTimeConvertLongString(state.tadaDate)}</td> : null
        return (
            <tbody key={index} className={state.tadaDate}>
                <tr>
                    <td>{index+1}</td>
                    <td>{dateTimeConvertLongString(state.tadaDate)}</td>
                    <td>{state.totalConsumeMileage}</td>
                    <td>{state.totalMonthCNG}</td>
                    <td>{state.totalMonthOIL}</td>
                    <td>{state.totalMonthCNGandOIL}</td>
                    <td>{state.cngPerKM}</td>
                    <td>{state.oilPerKM}</td>
                    <td>{state.cngAndOilKm}</td>
                    <td>{state.tadaMonth}</td>
                    <td>{state.tadaYear}</td>
                    <td>{state.employeeName}</td>
                    <td>{state.designation}</td>

                </tr>
            </tbody>
        )
        // })
        return (
            <tbody key={index} className={state.tadaDate}>
                {/* {tbodies} */}

            </tbody>

        )
    })

    const handleemplist = (item) => {
        dispatch(hanlesetInputforempname(item));
    };
    const handleChangeEmpName = (name, value) => {
        dispatch(handleSearchInput(name, value))
        if (value.length > 2) {
            dispatch(getNameList(value));
        }
    }

    const inputChangeHandle = (name, value) => {
        dispatch(hanleChangeInputforStandVehicleEntry(name, value != undefined ? value : ""));
    };
    const reportType = [
        { value: "1", label: "Daily Basis TADA Details" },
        { value: "2", label: "Monthly Basis TADA Details" },
    ];



    return (
        <>

            <div className="card card-custom card-top-border  p-5 mb-3">
                <div className="row">
                    <div className="col-lg-9 col-md-9">
                        <div className="">
                            <h3 className="mb-0 pb-0">Monthly Basis Fuel Consumption Report</h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <DownloadDropdown excelname='Monthly Basis Fuel Consumption Report' refvalue={componentRef} />
                    </div>

                </div>
                <hr></hr>

                <div className="row">
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Vehicle Name</label>
                            <RHFInput
                                className="formSelect pt-0 "
                                as={<Select options={ddFuleLogVehicle} />}
                                rules={{ required: false }}
                                name="vehicleID"
                                register={register}
                                placeholder="Select Vehicle Name"
                                isClearable
                                onChange={(option) => {
                                    inputChangeHandle("vehicleID", option?.value);
                                    inputChangeHandle("vehicleName", option?.label);
                                    setSelectVehicleName(option);


                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="form-control reffcss"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label"> To Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                value={toDate}
                                className="form-control reffcss"
                                onChange={handleChangeTodate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Job Station</label>
                            <RHFInput
                                className="formSelect pt-0 "
                                as={<Select options={areaList} />}
                                rules={{ required: false }}
                                name="areaType"
                                register={register}
                                placeholder="Select Area"
                                isClearable
                                onChange={(option) => {
                                    //handleChangeEmpName("areaType", option?.value);
                                    setSelectJobStation(option?.value)
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 mt-6 mb-5">
                        <Button size="sm" onClick={() => showReport()}>Show Report</Button>
                    </div>

                </div>


            </div>

            {
                monthlybasislist.result?.length > 0
                &&
                <div className="card card-custom  p-5 mb-3 " ref={componentRef} >
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h1 className="mb-0 pb-0 text-center text-bold">Monthly Basis Fuel Consumption Report</h1>
                            <h6 className="text-center text-bold">Fuel Log Report Of Vehicle No: {selectVehicleName?.label} </h6>
                            <h3 className="text-center text-bold mb-3 ">For The Month Of {startDate} To {toDate} </h3>
                        </div>
                    </div>
                    <div>
                        <table className="table table-head-custom dailybasistable" id="table-to-xls">
                            <thead>
                                <tr className="dailybasis">
                                    <th>SL</th>
                                    <th>BILL MONTH</th>
                                    <th>TOTAL CONSUME(KM)</th>
                                    <th>TOTAL CNG(TK)</th>
                                    <th>TOTAL OIL(TK)</th>
                                    <th>TOTAL(CNG+OIL)TK</th>
                                    <th>CNG PER KM</th>
                                    <th>OIL PER KM</th>
                                    <th>(CNG+OIL) PER KM</th>
                                    <th>MONTH</th>
                                    <th>YEAR</th>
                                    <th>EMPLOYEE NAME</th>
                                    <th>DESIGNATION</th>
                                </tr>
                            </thead>
                            {tbodies}
                        </table>
                    </div>
                </div>
            }
        </>
    );
};

export default MonthlyBasisVehicleReport
