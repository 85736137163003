import React from 'react';
import AttendanceStatus from '../../../components/remoteAttendance/attendanceStatus/AttendanceStatus';



const AttendanceStatusContainer = () => {

    return (
        <div className="container">
            <div className="">
                <AttendanceStatus />
            </div>
        </div>
    )

}
export default AttendanceStatusContainer;
