import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SimpleModal from "./../SimpleModal";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { clearTadaList, getNameList, getTaDAList, handleImageDelete, handleSearchInput, hanleChangeInputforbikecarentry, hanlesetInputforempname, imageUpload, tadaDelete } from '../../redux/actions/tada/TadaEntryAction';
import { dateTimeConvertLongString } from "./DateHelper";
import DataTable from "react-data-table-component"
import AlertConfirm from "react-alert-confirm";
import "react-alert-confirm/lib/style.css";
import { Lightbox } from "react-modal-image";
import ModalImage from "react-modal-image";
import { getTADAAtaGlancee, getTadaUserCostDetailsReport } from "../../redux/actions/tada/TadaReportAction";
import { getEmployeData } from "../../../../app/modules/Auth/_redux/authCrud";
import DownloadDropdown from "../../../master/components/PrintDwopdown/DownloadDropdown";


const TadaUserCostDetailsReport = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [selectedData, setSelectedData] = React.useState();

    const costdetails = useSelector((state) => state.tadareport.costdetails);
    const personalInfo = useSelector((state) => state.tadareport.personalInfo);
    const totalExpense = useSelector((state) => state.tadareport.totalExpense);
    const paybleAmount = useSelector((state) => state.tadareport.paybleAmount);
    console.log('personalInfo :>> ', personalInfo);
    console.log('report -costdetails :>> ', costdetails.result);
    console.log('totalExpense :>> ', totalExpense);
    console.log('paybleAmount :>> ', paybleAmount);
    const isLoading = useSelector((state) => state.tada.tadaListLoading);
    const empInfo = useSelector((state) => state.tada.empInfo);
    const nameList = useSelector((state) => state.tada.nameList);
  

    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [enroll, setEnroll] = useState(null);

    // useEffect(() => {
    //     dispatch(getTadaUserCostDetailsReport());
    // }, []);


    const componentRef = useRef();
    console.log('componentRef', componentRef);
    let ref = React.createRef();
    const inputField = React.useRef(null);

    console.log('inputField :>> ', inputField);


    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };

    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };


    const showReport = async () => {
        console.log('enroll :>> ', enroll);
        const userInfo = await getEmployeData();
        dispatch(getTadaUserCostDetailsReport(enroll));
    }

    const tbodies = costdetails.result?.map((state, index) => {
        const cityValues = Object.values(state.details);
        const cityRows = cityValues.map((city, i) => {
            const stateName = i === 0 ? <td rowSpan={cityValues.length + 1}>{dateTimeConvertLongString(state.tadaDate)}</td> : null
            //const stateAbbreviation = i === 0 ? <td rowSpan={cityValues.length + 1}>{state.fromAddress}</td> : null
            return (
                <tr key={i}>
                    {stateName}
                    <td>{city.fromAddress}</td>
                    <td>{city.toAdress}</td>
                    <td>{city.costType}</td>
                    <td>{city.amount}</td>
                    <td>{city.paymentType}</td>
                    <td></td>

                </tr>
            )
        })
        return (
            <tbody key={index} className={state.tadaDate}>
                {cityRows}

            </tbody>

        )
    })

    const handleemplist = (item) => {
        console.log('item :>> ', item);
        setEnroll(item.enroll);
        dispatch(hanlesetInputforempname(item));
    };
    const handleChangeEmpName = (name, value) => {
        dispatch(handleSearchInput(name, value))
        if (value.length > 2) {
            dispatch(getNameList(value));
        }
    }



    return (
        <>

            <div className="card card-custom card-top-border  p-5 mb-3">
                <div className="row">
                    <div className="col-md-9">
                        <div className="">
                            <h3 className="mb-0 pb-0">TA DA Report Details</h3>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <DownloadDropdown excelname='Tada Details' refvalue={componentRef} />
                    </div>

                </div>
                <hr></hr>


                <div className="form-group row">
                    <div className="col-lg-3 ">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Employee Name</label>
                            <Form.Control
                                autocomplete="off"
                                className="form-control reffcss zaxiscustom"
                                type="text"
                                placeholder="Type Name"
                                name="empName"
                                value={empInfo?.empName}
                                onChange={(e) => {

                                    handleChangeEmpName('empName', e.target.value);
                                    

                                }}
                            />
                        </Form.Group>
                        <div className="empname">
                            <>
                                {nameList.map((item, index) => (
                                    <ul className="uldotremove">

                                        <li onClick={() => handleemplist(item)}>{item.empName}</li>
                                    </ul>
                                ))}
                            </>
                        </div>
                    </div>
                    <div className="col mt-6 mb-5">
                        <Button size="sm" onClick={() => showReport()}>Show Report</Button>
                    </div>
                </div>
            </div>

            {
                costdetails.result?.length > 0
                && <div className="card card-custom  p-5 mb-3 " ref={componentRef} >
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="mb-0 pb-0 text-center text-bold">Akij Flour Mills Limited</h1>
                            <h6 className="text-center text-bold">Kadam Rasul, Bandar,Narayanganj</h6>
                            <h3 className="text-center text-bold tadabill">TA/DA Bill</h3>
                        </div>
                        <div className="col-lg-9 col-md-9 text-left tadabilltext mb-3">
                            <h6>Name:  {personalInfo.name}</h6>
                            <h6>Designation: {personalInfo.desinationName}</h6>
                            <h6>Department: {personalInfo.departName}</h6>
                            <h6>Enroll: {personalInfo.enroll}</h6>
                            <h6>Employee ID: {personalInfo.enroll} </h6>
                        </div>
                        {/* <div className="col-lg-3 col-md-3 text-left tadabilltext">
                            <h6>Tour Dtae: </h6>
                            <h6>Bill Date: </h6>

                        </div> */}

                    </div>

                    <div>
                        <table className="table table-head-custom borderTableims" id="table-to-xls">
                            <thead>
                                <tr>
                                    <th rowSpan="2" className="text-center">Date</th>
                                    <th colSpan="2" className="text-center">Particular</th>
                                    <th rowSpan="2" className="text-center">Fair</th>
                                    <th rowSpan="2" className="text-center">Amount</th>
                                    <th rowSpan="2" className="text-center">Payment type</th>
                                    <th rowSpan="2" className="text-center">Remarks</th>
                                </tr>
                                <tr>
                                    <th>From Address</th>
                                    <th>To Address</th>
                                </tr>
                            </thead>
                            {tbodies}
                            <tr className="trcus">
                                <th colSpan="4" className="text-center trcus ">Total Expenses</th>
                                <th className="text-center trcus">{totalExpense}</th>
                                <th className="text-center trcus"></th>
                                <th className="text-center trcus"></th>
                            </tr>
                            <tr>
                                <th colSpan="4" className="text-center trcus">Advance Taken</th>
                                <th className="text-center trcus">0</th>
                                <th className="text-center trcus"></th>
                                <th className="text-center trcus"></th>
                            </tr>
                            <tr>
                                <th colSpan="4" className="text-center trcus">Payable Amount</th>
                                <th className="text-center trcus">{paybleAmount}</th>
                                <th className="text-center trcus"></th>
                                <th className="text-center trcus"></th>
                            </tr>
                        </table>

                        {/* <h6>In wards: </h6> */}

                    </div>
                    {/* <div className="row mt-4 mb-5">
                        <div className="col-lg-3 col-md-3 col-sm-3 signature text-center">
                            Signature
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 signature text-center">
                            Signature(HOD)
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 signature text-center">
                            Signature(Audit)
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 signature text-center ">
                            Signature(A/C)
                        </div>
                    </div> */}

                </div>

            }







        </>
    );
};



export default TadaUserCostDetailsReport
