import React from "react";
import Demo from "./Demo";



const DemoContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <Demo />
      </div>
    </div>
  );
};
export default DemoContainer;
