
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SimpleModal from "./../SimpleModal";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { clearTadaList, getTaDAList, handleImageDelete, hanleChangeInputforbikecarentry, imageUpload, tadaDelete } from '../../redux/actions/tada/TadaEntryAction';
import { dateTimeConvertLongString } from "./DateHelper";
import DataTable from "react-data-table-component"
import AlertConfirm from "react-alert-confirm";
import "react-alert-confirm/lib/style.css";
import { Lightbox } from "react-modal-image";
import ModalImage from "react-modal-image";

const TaDaListEdit = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = React.useState();

  const tadaList = useSelector((state) => state.tada.tadaList);
  console.log('Delete - tadaList :>> ', tadaList);
  const isLoading = useSelector((state) => state.tada.tadaListLoading);

  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectTadaId, setSelectTadaId] = useState(null);
  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [imageShow, setImageShow] = useState(null);
  const [image, setImage] = useState(null);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    dispatch(clearTadaList());

  }, []);

  const componentRef = useRef();
  let ref = React.createRef();
  const inputField = React.useRef(null);


  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const itemDetail = (item) => {

  };
  const handleDeleteItem = (item) => {
    AlertConfirm({
      title: 'Are you sure?',
      desc: 'You want to Delete Tada',
      onOk: () => {
        dispatch(tadaDelete(item, selectTadaId, startDate))
        dispatch(handleImageDelete(item.filename));
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };
  const showReport = () => {
    dispatch(getTaDAList(startDate));
  }

  const columns = [
    {
      name: "DATE",
      selector: (row) => dateTimeConvertLongString(row.tadaDate),
      sortable: true,
    },
    {
      name: "ENROLL",
      selector: (row) => row.enroll,
      sortable: true,
    },
    {
      name: "DESIGNATION",
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: "DEPARTMENT",
      selector: (row) => row.department,
      sortable: true,
    },
    {
      name: "JOB STATION ",
      selector: (row) => row.jobStation,
      sortable: true,
    },
    {
      name: "JOB TYPE",
      selector: (row) => row.jobType,
      sortable: true,
    },
    {
      name: "FROM ADDRESS",
      selector: (row) => row.fromAddress,
      sortable: true,
    },
    {
      name: "TO ADDRESS",
      selector: (row) => row.toAddress,
      sortable: true,
    },
    {
      name: "FROM TIME",
      selector: (row) => row.timeFrom,
      sortable: true,
    },
    {
      name: "TO TIME",
      selector: (row) => row.timeTo,
      sortable: true,
    },
    {
      name: "CONTACT PERSON",
      selector: (row) => row.contactPerson,
      sortable: true,
    },
    {
      name: "CONTACT PERSON ",
      selector: (row) => row.contactNumber,
      sortable: true,
    },
    {
      name: "CONTACT NUMBER",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Attachment",
      cell: (row) =>
          <ModalImage
            small={`${process.env.REACT_APP_API_PUBLIC_URL}User_Files/Articles/Images/User_Files/${row.image}`}
            large={`${process.env.REACT_APP_API_PUBLIC_URL}User_Files/Articles/Images/User_Files/${row.image}`}
          />
   },
    {
      name: "Action",
      cell: row => <a href onClick={() => handleDeleteItem(row)} title="Delete">
        <i className="fas fa-trash-alt editIcon item-list-icon text-danger ml-4"></i>
      </a>
    },

  ];

  const handleChange1 = (state) => {
    setSelectedData(state.selectedRows);
    console.log(state);
  };

  return (
    <>
      <div className="card card-custom card-top-border p-5 mb-3">
        <div className="row">
          <div className="col-md-9">
            <div className="">
              <h3 className="mb-0 pb-0">TA DA DELETE</h3>
            </div>
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="form-control reffcss"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          {/* <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="form-control reffcss"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div> */}
          <div className="col mt-6 mb-5">
            <Button size="sm" onClick={() => showReport()}>Show Report</Button>
          </div>
        </div>
      </div>
      <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
        <DataTable
          columns={columns}
          data={tadaList}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='450px'
          selectableRowsHighlight
          highlightOnHover
          selectableRows
          onSelectedRowsChange={handleChange1}
        />
        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Image Preview"}
        >
          <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/User_Files/Articles/Images/User_Files/${tadaList.image}`} width='100%' height='100%' />
        </SimpleModal>
      </div>


    </>
  );
};



export default TaDaListEdit
