import React from 'react';
import { useForm } from "react-hook-form";
import { RHFInput } from 'react-hook-form-input';
import Select from 'react-select';
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { handleLeaveInput } from '../../../redux/actions/Leave/LeaveAction';


const LeaveApplicationList = () => {
    const { handleSubmit, register, setValue, reset } = useForm();

    const dispatch = useDispatch();
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
    ];

    const userInfoData = useSelector((state) => state.leave.userInfo);

    console.log('userInfoData', userInfoData);
    



    const onSubmitLeave = () => {
        alert();
    }

    const handleInputChange =(value,name)=>{
        dispatch(handleLeaveInput(value,name));
    }




    return (
        <>
            <div className="card card-custom mb-2 pl-5 pr-5  card-top-border p-3">
                <div className="row">
                    <div className="col-md-9">
                        <div className="p-2">
                            <h3>Leave Application</h3>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div>
                    {/* <div className="form-group row rowColor">
                        <div className="col-lg-1 col-md-1">
                            <div className="form-check radio__button  Promotion-radio-button ">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="option1"
                                    checked
                                />
                                <label className="form-check-label" for="exampleRadios1">
                                    Private
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1">
                            <div className="form-check radio__button  Promotion-radio-button ">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="option1"
                                    checked
                                />
                                <label className="form-check-label" for="exampleRadios1">
                                    Public
                                </label>
                            </div>
                        </div>
                    </div> */}
                </div>
                <form
                    className="form form-label-right"
                    onSubmit={handleSubmit(onSubmitLeave)}
                    method="post"
                    encType="multipart/form-data"
                    autoComplete="off">
                    <div className="form-group row">
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Employee Name</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Employee Name"
                                    name="itemName"
                                    value={userInfoData.employeeName}
                                    onChange={(e)=>handleInputChange(e.target.value,'employeeName')}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Job Status</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Job Status"
                                    name="itemName"
                                   value={userInfoData.jobStatus}
                                    onChange={(e)=>handleInputChange(e.target.value,'jobStatus')}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Designation</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Designation"
                                    name="itemName"
                                   value={userInfoData.designation}
                                    onChange={(e)=>handleInputChange(e.target.value,'designation')}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Contact No</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Contact No"
                                    name="itemName"
                                   value={userInfoData.contactNo}
                                    onChange={(e)=>handleInputChange(e.target.value,'contactNo')}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">From Date</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="date"
                                    placeholder="From Date"
                                    name="itemName"
                                   value={userInfoData.fromDate}
                                    onChange={(e)=>handleInputChange(e.target.value,'fromDate')}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">To Date</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="date"
                                    placeholder="To Date"
                                    name="itemName"
                                   value={userInfoData.toDate}
                                    onChange={(e)=>handleInputChange(e.target.value,'toDate')}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Reason</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="date"
                                    placeholder="Reason"
                                    name="itemName"
                                   value={userInfoData.reason}
                                    onChange={(e)=>handleInputChange(e.target.value,'reason')}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Leave Type</label>
                                <RHFInput
                                    as={<Select options={options} />}
                                    rules={{ required: false }}
                                    name="reactSelect"
                                    register={register}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-12 mt-5">
                            <button type="submit" className="btn btn-primary  btn-sm float-right text-center custome-addnew-btn item-add-save mb-5"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>

            </div>
            <div className="card card-custom  p-5">
                <div className="react-bootstrap-table table-responsive">
                    <table className="table table table-head-custom table-vertical-center">
                        <thead>
                            <th>SL</th>
                            <th>Item ID</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default LeaveApplicationList;
