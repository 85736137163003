import Axios from "axios";
import * as Types from '../../types/Types';

export const getZoneList =  (name, value) =>async (dispatch)=> {
    let responseList = {
      isLoading: true,
      data: {},
      status: false,
    }
    try {
      let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
      await Axios
        .get(geturl)
        .then(function(response) {
          console.log('response', response);
          responseList.data = response.data.data;
        })
        
        .catch(function(error) {
          
        });
    } catch (error) {
     
    }
    dispatch({ type: Types.GET_ZONE_DATA, payload: responseList });
  };

  export const getDivisionListByZone =  (zoneId) => async (dispatch)=> {
    let responseList = {
      isLoading: true,
      data: {},
      status: false,
    };
    try {
      let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllRegionByZone?zoneId=${zoneId}`;
      await Axios
        .get(geturl)
        .then(function(response) {
            console.log('response', response);
          responseList.data = response.data.data;
        })
        .catch(function(error) {
          
        });
    } catch (error) {
      
    }
    dispatch({ type: Types.GET_AREA_DATA_BY_ZONE, payload: responseList });
  };
  export const getAreaListByDivision =  (divisinId) =>async (dispatch)=> {
    let responseList = {
      isLoading: true,
      data: {},
      status: false,
    };
    try {
      let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllAreaByDivision?divisionId=${divisinId}`;
      await Axios
        .get(geturl)
        .then(function(response) {
            console.log('response', response);
          responseList.data = response.data.data;
        })
        .catch(function(error) {
          
        });
    } catch (error) {
      
    }
    dispatch({ type: Types.GET_AREA_LIST_BY_AREA, payload: responseList });
  };