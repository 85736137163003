
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import "react-alert-confirm/lib/style.css";
import DownloadDropdown from "../../../master/components/PrintDwopdown/DownloadDropdown";
import { getFuelStationBillReport, getTADAAtaGlancee } from "../../redux/actions/tada/TadaReportAction";
import { GetStandVehicle, hanleChangeInputforStandVehicleEntry } from "../../redux/actions/tada/StandVehiclesAction";
import { GetAreaListForHR, GetFillingStation } from "../../redux/actions/tada/TadaEntryAction";

const FuelStationBillReport = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();

    const history = useHistory();
    const dispatch = useDispatch();
    const fuelstationreport = useSelector((state) => state.tadareport.fuelstationreport);
    console.log('fuelstationreport :>> ', fuelstationreport);
    const ddFuleLogVehicle = useSelector((state) => state.standvehicle.ddFuleLogVehicle);
    const fillingStation = useSelector((state) => state.tada.ddFillingStation);
    const areaList = useSelector((state) => state.tada.areaList);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectVehicle, setSelectVehicle] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [selectFillingStation, setSelectFillingStation] = useState(null);


    const componentRef = useRef();
    let ref = React.createRef();
    const inputField = React.useRef(null);

    useEffect(() => {
        dispatch(GetStandVehicle());
        dispatch(GetAreaListForHR());
        dispatch(GetFillingStation());

    }, [])


    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };

    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };
    const inputChangeHandle = (name, value) => {
        dispatch(hanleChangeInputforStandVehicleEntry(name, value != undefined ? value : ""));
    };

    const showReport = () => {
        dispatch(getFuelStationBillReport(selectVehicle,startDate,toDate,selectArea,selectFillingStation));
    }

    const tbodies = fuelstationreport.result?.map((state, index) => {
        const cityValues = Object.values(state);
        return (
            <tbody key={index} className={state.tadaDate}>
                <tr>
                    <td>{index + 1}</td>
                    <td>{state.vehicleNum}</td>
                    <td>{state.paymentType}</td>
                    <td>{state.cashAmount}</td>
                    <td>{state.creditAmount}</td>
                    <td>{state.vehicleID}</td>
                </tr>
            </tbody>
        )
    })

    return (
        <>

            <div className="card card-custom card-top-border  p-5 mb-3">
                <div className="row">
                    <div className="col-md-9">
                        <div className="">
                            <h3 className="mb-0 pb-0">Fuel Station Bill Report</h3>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <DownloadDropdown excelname='Fuel Station Bill Report' refvalue={componentRef} />
                    </div>
                </div>
                <hr></hr>

                <div className="row">
                <div className="col-lg-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Vehicle Name<span className="star">*</span></label>
                    <RHFInput
                      className="formSelect pt-0 "
                      as={<Select options={ddFuleLogVehicle} />}
                      rules={{ required: false }}
                      name="vehicleID"
                      register={register}
                      placeholder="Select Vehicle Name"
                      isClearable
                      onChange={(option) => {
                        inputChangeHandle("vehicleID", option?.value);
                        inputChangeHandle("vehicleName", option?.label);
                      }}
                      setValue={setValue}
                    />
                  </Form.Group>
                </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">From Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="form-control reffcss"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label"> To Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                value={toDate}
                                className="form-control reffcss"
                                onChange={handleChangeTodate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Area</label>
                            <RHFInput
                                className="formSelect pt-0 "
                                as={<Select options={areaList} />}
                                rules={{ required: false }}
                                name="areaType"
                                register={register}
                                placeholder="Select Area"
                                isClearable
                                onChange={(option) => {
                                    //handleChangeEmpName("areaType", option?.value);
                                    // setSelectAreaName(option?.value)
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Filling Station</label>
                            <RHFInput
                                className="formSelect pt-0 customindex"
                                as={<Select options={fillingStation} />}
                                rules={{ required: false }}
                                name="cngStation"
                                register={register}
                                placeholder="Select Filling station"
                                isClearable
                                onChange={(option) => {
                                    inputChangeHandle("cngStation", option?.value);
                                    inputChangeHandle("cngStationName", option?.label);

                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 mt-6 mb-5">
                        <Button size="sm" onClick={() => showReport()}>Show Report</Button>
                    </div>
                </div>
            </div>

            {
                fuelstationreport.result?.length > 0
                &&
                <div className="card card-custom  p-5 mb-3 " ref={componentRef} >
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="mb-0 pb-0 text-center text-bold">Fuel Station Bill Report</h2>
                            <h3 className="text-center text-bold mb-3 ">For The Month Of {startDate} To {toDate} </h3>
                        </div>
                    </div>
                    <div>
                        <table className="table table-head-custom dailybasistable" id="table-to-xls">
                            <thead>
                                <tr className="dailybasis">
                                    <th>SL</th>
                                    <th>VEHICLE</th>
                                    <th>PAYMENT TYPE</th>
                                    <th>CASH AMOUNT</th>
                                    <th>CREDIT AMOUNT</th>
                                    <th>VEHICLE ID</th>
                                </tr>
                            </thead>
                            {tbodies}
                        </table>
                    </div>
                </div>
            }
        </>
    );
};

export default FuelStationBillReport
