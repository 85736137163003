
import React from "react";
import TADAForNonBikeCar from "./TADAForNonBikeCar";


const TADAForNonBikeCarContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <TADAForNonBikeCar />
      </div>
    </div>
  );
};
export default TADAForNonBikeCarContainer;
