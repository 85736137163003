import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import "react-alert-confirm/lib/style.css";
import DownloadDropdown from "../../../master/components/PrintDwopdown/DownloadDropdown";
import { getTADAAtaGlancee } from "../../redux/actions/tada/TadaReportAction";

const TadaApprovedList = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();

    const history = useHistory();
    const dispatch = useDispatch();
    const tadaList = useSelector((state) => state.tadareport.tadareportataglance);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const componentRef = useRef();
    let ref = React.createRef();
    const inputField = React.useRef(null);


    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };

    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };

    const showReport = () => {
        dispatch(getTADAAtaGlancee(startDate, toDate));
    }

    const tbodies = tadaList?.map((state, index) => {
        const cityValues = Object.values(state);
        return (
            <tbody key={index} className={state.tadaDate}>
                <tr>
                    <td>{index + 1}</td>
                    <td>{state.enroll}</td>
                    <td>{state.employeeName}</td>
                    <td>{state.amount}</td>
                    <td>{state.superviserAprvAmount}</td>
                    <td>{state.hrAprvAmount}</td>
                    <td>{state.auditApproveAmount}</td>
                    <td>{state.branchCode}</td>
                    <td>{state.branchName}</td>
                    <td>{state.bankName}</td>
                    <td>{state.routingNumber}</td>
                </tr>
            </tbody>
        )
    })

    return (
        <>

            <div className="card card-custom card-top-border  p-5 mb-3">
                <div className="row">
                    <div className="col-md-9">
                        <div className="">
                            <h3 className="mb-0 pb-0">TADA Approved List</h3>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <DownloadDropdown excelname='TADA Approved List' refvalue={componentRef} />
                    </div>
                </div>
                <hr></hr>

                <div className="row">
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">From Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="form-control reffcss"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label"> To Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                value={toDate}
                                className="form-control reffcss"
                                onChange={handleChangeTodate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 mt-6 mb-5">
                        <Button size="sm" onClick={() => showReport()}>Show Report</Button>
                    </div>
                </div>
            </div>

            {
                tadaList?.length > 0
                &&
                <div className="card card-custom  p-5 mb-3 " ref={componentRef} >
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="mb-0 pb-0 text-center text-bold">TADA APPROVED LIST</h2>
                        </div>
                    </div>
                    <div>
                        <table className="table table-head-custom dailybasistable" id="table-to-xls">
                            <thead>
                                <tr className="dailybasis">
                                    <th>SL</th>
                                    <th>ENROLL</th>
                                    <th>EMPLOYEE NAME</th>
                                    <th>AMOUNT</th>
                                    <th>SUPERVISOR APPROVED AMOUNT</th>
                                    <th>HR APPROVED AMOUNT</th>
                                    <th>AUDIT APPROVED AMOUNT</th>
                                    <th>BRANCE CODE</th>
                                    <th>BRANCE NAME</th>
                                    <th>BANK NAME</th>
                                    <th>ROUTING NUMBER</th>
                                </tr>
                            </thead>
                            {tbodies}
                        </table>
                    </div>
                </div>
            }
        </>
    );
};

export default TadaApprovedList
