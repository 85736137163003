import Axios from "axios";
import * as Types from "../../types/Types";
import { showToast } from "../../../../master/utils/ToastHelper";

export const hanleChangeInputforNonBikeCar = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_TADA_INPUT_FOR_NON_BIKE_CAR, payload: data });
};
export const hanlesetInputforempname = (item) => (dispatch) => {

  dispatch({ type: Types.GET_EMP_NAME_SET, payload: item });
};
export const handleApprovedAmount  = (item, value) => (dispatch) => {
  let data = {
    item: item,
    value: value,
  };
  dispatch({ type: Types.CHANGE_TADA_APPROVED_AMOUNT, payload: data });
};
export const handleApprovedAmountforhr  = (item, value) => (dispatch) => {
  let data = {
    item: item,
    value: value,
  };
  dispatch({ type: Types.CHANGE_TADA_APPROVED_AMOUNT_FOR_HR, payload: data });
};
export const handleApprovedAmountforaudit  = (item, value) => (dispatch) => {
  let data = {
    item: item,
    value: value,
  };
  dispatch({ type: Types.CHANGE_TADA_APPROVED_AMOUNT_FOR_AUDIT, payload: data });
};
export const changeMovementInputValue = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_MOVEMENT_HOUR, payload: data });
};
export const inputHandleConsumeMilage = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_CONSUME_MILAGE, payload: data });
};

export const hanleChangeInputforbikecarentry = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_TADA_BIKE_CAR_ENTRY, payload: data });
};
export const hanleChangeInputforModal = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_MODAL_ENTRY, payload: data });
};
export const hanleChangeInputforHRapproved = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_TADA_HR_APPROVED_ENTRY, payload: data });
};
export const handleSearchInput = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_HANDLE_SEARCH_INPUT, payload: data });
};

export const handleRowCreateForNonBikeCar = (itemDataInputForNonBikeCarEntry) => (
  dispatch
) => {
  dispatch({
    type: Types.TADA_ROW_CREATE_FOR_NON_BIKECAR,
    payload: itemDataInputForNonBikeCarEntry,
  });
};

export const handleRowCreateforbikecar = (itemDataInputForBikeCarEntry) => (
  dispatch
) => {
  dispatch({
    type: Types.TADA_ROW_CREATE_FOR_BIKECAR,
    payload: itemDataInputForBikeCarEntry,
  });
};
export const handleListDelete = (id) => (dispatch) => {
  dispatch({ type: Types.TADA_ROW_DELETE, payload: id });
};
export const GetDa = (name, value) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetDAType`;

    await Axios.get(geturl)
      .then(function(response) {
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
    dispatch({ type: Types.GET_DA, payload: responseList });
  } catch (error) {}
  return responseList;
};
export const GetTa = (Type) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetTaType?Type=${Type}`;
    await Axios.get(geturl)

      .then(function(response) {
        console.log('response :>> ', response);
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
    dispatch({ type: Types.GET_TA, payload: responseList });
  } catch (error) {}
  return responseList;
};
export const GetBillType = (name, value) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetTadaCostHead`;
    await Axios.get(geturl)

      .then(function(response) {
        console.log('bill response :>> ', response.data.data);
        responseList.data = response.data.data;
      })
      .catch(function(error) {});
    dispatch({ type: Types.GET_BILL_TYPE, payload: responseList });
  } catch (error) {}
  return responseList;
};
export const GetFuelType = (name, value) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetFuleType`;
    await Axios.get(geturl)

      .then(function(response) {
        console.log('bill response :>> ', response.data.data);
        responseList.data = response.data.data;
      })
      .catch(function(error) {});
    dispatch({ type: Types.GET_FUEL_TYPE, payload: responseList });
  } catch (error) {}
  return responseList;
};
export const GetPaymentType = (name, value) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetPaymentType`;
    await Axios.get(geturl)

      .then(function(response) {
        responseList.data = response.data.data;
      })
      .catch(function(error) {});
    dispatch({ type: Types.GET_PAYMENT_TYPE, payload: responseList });
  } catch (error) {}
  return responseList;
};
export const GetFillingStation = (name, value) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetFillingStation`;
    await Axios.get(geturl)

      .then(function(response) {
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
    dispatch({ type: Types.GET_FILLING_STATION, payload: responseList });
  } catch (error) {}
  return responseList;
};
export const GetAreaListForHR = (name, value) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetAreaList`;
    await Axios.get(geturl)
      .then(function(response) {
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
    dispatch({ type: Types.GET_AREA_LIST, payload: responseList });
  } catch (error) {}
  return responseList;
};

export const imageUpload = (imageData) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/ImageUpload/Post`;
    await Axios.post(geturl, imageData)

      .then(function(response) {
        dispatch(hanleChangeInputforbikecarentry("filename", response.data));
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
  } catch (error) {}
  return responseList;
};
export const imageUploadNonbike = (imageData) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/ImageUpload/Post`;
    await Axios.post(geturl, imageData)

      .then(function(response) {
        console.log('response :>> ', response);
        dispatch(hanleChangeInputforNonBikeCar("filename", response.data));
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
  } catch (error) {}
  return responseList;
};
export const handleImageDelete = (imageData) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/ImageUpload/ImageDelete?imageName=${imageData}`;
    await Axios.delete(geturl)
      .then(function(response) {
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
  } catch (error) {}
  return responseList;
};

export const tadaSubmitForBikeCar = (
  enroll,
  Tada_Date,
  tadabikecarDetails
) => async (dispatch) => {
  console.log('tadabikecarDetails', tadabikecarDetails);
  let newList = [];
  const formData = new FormData();
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/InsertTadaForBiker?enroll=${enroll}&Tada_Date=${Tada_Date}`;
    
    for (let i = 0; i < tadabikecarDetails.length; i++) {
      let taDaData = {
        vehicleType: "",
        cngStation:"",
        fuelType: "",
        timeFrom: "",
        timeTo: "",
        movementHour: "",
        fromAddress: "",
        toAddress: "",
        startMilage: "0",
        endMilage: "0",
        consumeMilage:"0",
        daType: "",
        paymentType: "",
        remarks: "",
        amount: 0,
        persoanlMilage:0,
        contactPerson: "",
        contactNumber: "",
        fileName:" ",
      };

      if (tadabikecarDetails.length > 0) {
        taDaData.vehicleType = tadabikecarDetails[i].vehicleType.toString();
        taDaData.cngStation = tadabikecarDetails[i].cngStation !=null?tadabikecarDetails[i].cngStation.toString():null;
        taDaData.fuelType = tadabikecarDetails[i].fuelType !=null?tadabikecarDetails[i].fuelType.toString():null;
        taDaData.timeFrom = tadabikecarDetails[i].timeFrom;
        taDaData.timeTo = tadabikecarDetails[i].timeTo;
        taDaData.movementHour = tadabikecarDetails[i].movementHour.toString();
        taDaData.fromAddress = tadabikecarDetails[i].fromAddress;
        taDaData.toAddress = tadabikecarDetails[i].toAddress;
        taDaData.startMilage = tadabikecarDetails[i].startMilage.toString();
        taDaData.endMilage = tadabikecarDetails[i].endMilage.toString();
        taDaData.consumeMilage = tadabikecarDetails[i].consumeMilage.toString();
        taDaData.daType = tadabikecarDetails[i].daType.toString();
        taDaData.paymentType = tadabikecarDetails[i].paymentType.toString();
        taDaData.remarks = tadabikecarDetails[i].remarks;
        taDaData.amount = tadabikecarDetails[i].amount;
        taDaData.persoanlMilage = tadabikecarDetails[i].persoanlMilage !=""?tadabikecarDetails[i].persoanlMilage:0;
        taDaData.contactPerson = tadabikecarDetails[i].contactPerson;
        taDaData.contactNumber = tadabikecarDetails[i].contactNumber;
        taDaData.fileName = tadabikecarDetails[i].filename;
        newList.push(taDaData);
      }
    }

    console.log('newList :>> ', newList);
    //return false
   

    await Axios.post(postUrl, newList)
      .then(function(response) {
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.TADA_BIKE_CAR_SUBMIT, payload: responseList });
        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.TADA_BIKE_CAR_SUBMIT, payload: responseList });
        }
      })
      .catch(function(error) {
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.TADA_BIKE_CAR_SUBMIT, payload: responseList });
      });
  } catch (error) {
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.TADA_BIKE_CAR_SUBMIT, payload: responseList });
  }
};

export const tadaSubmitForNonBikeCar = (
  enroll,
  Tada_Date,
  tadabikecarDetails
) => async (dispatch) => {
  let newList = [];
  const formData = new FormData();
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  console.log("non tadabikecarDetails actrion:>> ", tadabikecarDetails);
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/InsertTadaNonBiker?enroll=${enroll}&Tada_Date=${Tada_Date}`;
    console.log("non bike-car-postUrl :>> ", postUrl);

    for (let i = 0; i < tadabikecarDetails.length; i++) {
      let taDaData = {
        vehicleType: "",
        cngStation:"",
        fuelType: "",
        timeFrom: "",
        timeTo: "",
        movementHour: "",
        fromAddress: "",
        toAddress: "",
        startMilage: "0",
        endMilage: "0",
        consumeMilage:"0",
        daType: "",
        paymentType: "",
        remarks: "",
        amount: 0,
        fileName:" ",
        contactPerson: "",
        contactNumber: "",
      };

      if (tadabikecarDetails.length > 0) {
        taDaData.vehicleType = tadabikecarDetails[i].vehicleType.toString();
        taDaData.cngStation = tadabikecarDetails[i].cngStation.toString();
        taDaData.fuelType = tadabikecarDetails[i].fuelType.toString();
        taDaData.timeFrom = tadabikecarDetails[i].timeFrom;
        taDaData.timeTo = tadabikecarDetails[i].timeTo;
        taDaData.movementHour = tadabikecarDetails[i].movementHour.toString();
        taDaData.fromAddress = tadabikecarDetails[i].fromAddress;
        taDaData.toAddress = tadabikecarDetails[i].toAddress;
        taDaData.startMilage = tadabikecarDetails[i].startMilage.toString();
        taDaData.endMilage = tadabikecarDetails[i].endMilage.toString();
        taDaData.consumeMilage = tadabikecarDetails[i].consumeMilage.toString();
        taDaData.daType = tadabikecarDetails[i].daType.toString();
        taDaData.paymentType = tadabikecarDetails[i].paymentType.toString();
        taDaData.remarks = tadabikecarDetails[i].remarks;
        taDaData.amount = tadabikecarDetails[i].amount;
        taDaData.fileName = tadabikecarDetails[i].filename;
        taDaData.contactPerson = tadabikecarDetails[i].contactPerson;
        taDaData.contactNumber = tadabikecarDetails[i].contactNumber;
        
        newList.push(taDaData);
      }
     
    }
    console.log('non-newList :>> ', newList);
    //return false;

    await Axios.post(postUrl, newList)
      .then(function(response) {
        console.log("tada response :>> ", response);

        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.TADA_BIKE_CAR_SUBMIT, payload: responseList });
        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.TADA_BIKE_CAR_SUBMIT, payload: responseList });
        }
      })
      .catch(function(error) {
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.TADA_BIKE_CAR_SUBMIT, payload: responseList });
      });
  } catch (error) {
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.TADA_BIKE_CAR_SUBMIT, payload: responseList });
  }
};


export const getTaDAList = (fromDate) => async (dispatch) => {

  console.log('fromDate :>> ', fromDate);
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetTadaList?fromDate=${fromDate}`;

  await Axios.get(url).then((res) => {
    console.log('delete url :>> ', url);
    console.log("delete -res :>> ", res);

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_LIST, payload: responseList });
};

export const getTaDAUnApprovedList = (fromDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetUnapprovedListForSuperviserApproved?`;

  await Axios.get(url).then((res) => {
    console.log("unApproved-res :>> ", res);

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TADA_UNAPPROVED_LIST, payload: responseList });
};

export const tadaDelete = (item, tadaID,startDate) => (dispatch) => {
  console.log('item', item);
  let isLoading = true;
  dispatch({ type: Types.DELETE_TADA_LIST, payload: isLoading })
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/TadaDelete?TadaId=${item.tadaID}&SL=${item.sl}`
  Axios.delete(url)
    .then((res) => {

      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch(getTaDAList(startDate))
      }
    })
};

export const getTaDAUnapprovedApprovedList = (FromDate, To_Date) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetMonthlyTadaAmount?FromDate=${FromDate}&To_Date=${To_Date}`;

  
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_UNAPPROVED_LIST_MONTHLY, payload: responseList });
};
export const getMonthlyTadaAmountForAudit = (FromDate, To_Date) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetMonthlyTadaAmountForAudit?FromDate=${FromDate}&To_Date=${To_Date}`;

  
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_UNAPPROVED_LIST_MONTHLY_FOR_AUDIT, payload: responseList });
};


export const getMonthlyTadaAmountForHR = (Enroll=0,AreaID=0,FromDate, To_Date) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  console.log('Enroll :>> ', Enroll);
  console.log('AreaID :>> ', AreaID);
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetMonthlyTadaAmountForHR?`;
  console.log('hr - url :>> ', url);
  if (Enroll !== null) {
    url += `&Enroll=${Enroll}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (FromDate !== null) {
    url += `&FromDate=${FromDate}`
  }
  if (To_Date !== null) {
    url += `&To_Date=${To_Date}`
  }

  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TADA_UNAPPROVED_LIST_MONTHLY_FOR_HR, payload: responseList });

};

export const getTaDAUnapprovedApprovedListDetails = (enroll, FromDate, To_Date) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetDayWiseTadaAmount?Enroll=${enroll}&FromDate=${FromDate}&To_Date=${To_Date}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_UNAPPROVED_LIST_DAILY, payload: responseList });
};

export const getNameList = (Name) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetEmployee?Name=${Name}`;
  
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_NAME_LIST, payload: responseList });
};

export const getDayWiseTadaAmountForHr = (Enroll, FromDate, To_Date) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetDayWiseTadaAmountForHr?Enroll=${Enroll}&FromDate=${FromDate}&To_Date=${To_Date}`;
  console.log('now -url :>> ', url);
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_UNAPPROVED_LIST_DAILY_FOR_HR, payload: responseList });
};

export const getTaDAUnapprovedApprovedListForSupApproved = (tadaId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetUnapprovedListForSuperviserApproved?tadaId=${tadaId}`;

  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_UNAPPROVED_LIST_FOR_SUP_APPROVED, payload: responseList });
};


export const GetEndMilage = () => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetEndMileage?`;

  await Axios.get(url).then((res) => {
    console.log('res', res);
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_END_MILAGE, payload: responseList });
};

export const getTadaApprovedForSuperviser = (tadaApprovedDetails,startDate,toDate,userEnroll) =>async(dispatch)=> {
  let newList = [];
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
 

  try {
  
    for (let i = 0; i < tadaApprovedDetails.length; i++) {
     
      let taDaData = {
        tadaid: "",
        tadaDate:"",
        enroll:"",
        serialNo: "",
        amount: 0,
      };
      if (tadaApprovedDetails.length > 0) {
        taDaData.tadaid = tadaApprovedDetails[i].tadaId;
        taDaData.tadaDate = tadaApprovedDetails[i].tadaDate;
        taDaData.enroll = tadaApprovedDetails[i].enroll;
        taDaData.serialNo = "";
        taDaData.amount = 0;

        newList.push(taDaData);
      }
    }

    let patchUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/TadaApprovedForSuperviser`;
    Axios
      .patch(patchUrl,newList)
      .then(function(response) {
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);

          dispatch(getTaDAUnapprovedApprovedList(startDate, toDate));
          dispatch(getTaDAUnapprovedApprovedListDetails(userEnroll.enroll,startDate, toDate));

          dispatch({ type: Types.GET_TADA_APPROVED_FOR_SUPERVISER, payload: responseList });
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function(error) {
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {}
};

export const tadaSubmitStausRemove = () => (dispatch) => {
  dispatch({ type: Types.SUBMIT_STATUS_REMOVE, payload: null });
};

export const getTadasingleApproved = (tadaApprovedDetails,DateRange) =>async(dispatch)=> {
  let newList = [];
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  const dateRa = tadaApprovedDetails[0].tadaDate;
  const emEn = tadaApprovedDetails[0].enroll;
  try {
  
    for (let i = 0; i < tadaApprovedDetails.length; i++) {
      let taDaData = {
        tadaid: "",
        tadaDate:"",
        enroll:"",
        serialNo: "",
        amount: 0,
      };
 
      if (tadaApprovedDetails.length > 0) {
        taDaData.tadaid = tadaApprovedDetails[i].tadaId;
        taDaData.tadaDate = tadaApprovedDetails[i].tadaDate;
        taDaData.enroll = tadaApprovedDetails[i].enroll.toString();
        taDaData.serialNo = tadaApprovedDetails[i].slNo.toString();
        taDaData.amount = parseInt(tadaApprovedDetails[i].amount);

        newList.push(taDaData);
        console.log('sup newList :>> ', newList);

      }
    }
    let patchUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/TadaDayWiseApprovedForSuperviser`;
    Axios
      .patch(patchUrl,newList)
      .then(function(response) {
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);
          dispatch(getTaDAUnapprovedApprovedList(DateRange.fromDate, DateRange.toDate));
          dispatch(getTaDAUnapprovedApprovedListDetails(emEn,DateRange.fromDate, DateRange.fromDate, DateRange.toDate));
          dispatch({ type: Types.GET_TADA_APPROVED_FOR_SUPERVISER, payload: responseList });
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function(error) {
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {}
};
export const getTadasingleApprovedByHR = (tadaApprovedDetails,DateRange) =>async(dispatch)=> {
  let newList = [];
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  
  const dateRa = tadaApprovedDetails[0].tadaDate;
  const emEn = tadaApprovedDetails[0].enroll;
  console.log('DateRange :>> ', DateRange);
  console.log('dateRa :>> ', dateRa);
  console.log('emEn :>> ', emEn);

  
  try {
  
    for (let i = 0; i < tadaApprovedDetails.length; i++) {
      let taDaData = {
        tadaid: "",
        tadaDate:"",
        enroll:"",
        serialNo: "",
        amount: 0,
      };
 
      if (tadaApprovedDetails.length > 0) {
        taDaData.tadaid = tadaApprovedDetails[i].tadaId;
        taDaData.tadaDate = tadaApprovedDetails[i].tadaDate;
        taDaData.enroll = tadaApprovedDetails[i].enroll.toString();
        taDaData.serialNo = tadaApprovedDetails[i].slNo.toString();
        taDaData.amount = parseInt(tadaApprovedDetails[i].supervisorAmount);

        newList.push(taDaData);
        console.log('newList :>> ', newList);
        
      }
    
    }
    let patchUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/TadaDayWiseApprovedForHR`;
    Axios
      .patch(patchUrl,newList)
      .then(function(response) {
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);
          dispatch(getMonthlyTadaAmountForHR(emEn,DateRange.fromDate, DateRange.toDate));
          dispatch(getDayWiseTadaAmountForHr(emEn, DateRange.fromDate, DateRange.toDate));
          dispatch({ type: Types.GET_TADA_APPROVED_FOR_HR, payload: responseList });
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function(error) {
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {}
};

export const clearTadaList = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_TADA_LIST, payload: data });
};
export const clearFieldForTaDa = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_FIELD_FOR_TADA, payload: data });
};
export const clearFieldForNonBike = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_FIELD_FOR_NONBIKE, payload: data });
};
export const clearFieldForNonBikePublic = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_FIELD_FOR_NONBIKE_PUBLIC, payload: data });
};
export const clearFieldForTaDaForNonBike = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_FIELD_FOR_TADA_FOR_NON_BIKE, payload: data });
};

export const getTadaApprovedForHR = (tadaApprovedDetails,startDate,toDate,userEnroll) =>async(dispatch)=> {
  let newList = [];
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
  
    for (let i = 0; i < tadaApprovedDetails.length; i++) {
     
      let taDaData = {
        tadaid: "",
        tadaDate:"",
        enroll:"",
        serialNo: "",
        amount: 0,
      };
      if (tadaApprovedDetails.length > 0) {
        taDaData.tadaid = tadaApprovedDetails[i].tadaId;
        taDaData.tadaDate = tadaApprovedDetails[i].tadaDate;
        taDaData.enroll = tadaApprovedDetails[i].enroll;
        taDaData.serialNo = "";
        taDaData.amount = 0;

        newList.push(taDaData);
      }
    }

    let patchUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/TadaApprovedForHR`;
    Axios
      .patch(patchUrl,newList)
      .then(function(response) {
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);
          dispatch(getMonthlyTadaAmountForHR(startDate, toDate));
          dispatch(getDayWiseTadaAmountForHr(userEnroll.enroll,startDate, toDate));

          dispatch({ type: Types.GET_TADA_APPROVED_FOR_HR, payload: responseList });
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function(error) {
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {}
};
export const getDayWiseTadaAmountForAudit = (enroll, FromDate, To_Date) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetDayWiseTadaAmountForAudit?Enroll=${enroll}&FromDate=${FromDate}&To_Date=${To_Date}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_UNAPPROVED_LIST_DAILY_FOR_AUDIT, payload: responseList });
};
export const getUnapprovedListForAuditApproved = (tadaId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetUnapprovedListForAuditApproved?tadaId=${tadaId}`;
  console.log('audit - url :>> ', url);
  await Axios.get(url).then((res) => {
    console.log('audit res :>> ', res);
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_UNAPPROVED_LIST_FOR_AUDIT_APPROVED, payload: responseList });
};
export const getUnapprovedListForHRApproved = (tadaId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetUnapprovedListForHRApproved?tadaId=${tadaId}`;
  console.log('audit - url :>> ', url);
  await Axios.get(url).then((res) => {
    console.log('audit res :>> ', res);
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_UNAPPROVED_LIST_FOR_HR_APPROVED, payload: responseList });
};
export const getTadaApprovedForAudit = (tadaApprovedDetails,startDate,toDate,userEnroll) =>async(dispatch)=> {
  let newList = [];
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
 

  try {
  
    for (let i = 0; i < tadaApprovedDetails.length; i++) {
     
      let taDaData = {
        tadaid: "",
        tadaDate:"",
        enroll:"",
        serialNo: "",
        amount: 0,
      };
      if (tadaApprovedDetails.length > 0) {
        taDaData.tadaid = tadaApprovedDetails[i].tadaId;
        taDaData.tadaDate = tadaApprovedDetails[i].tadaDate;
        taDaData.enroll = tadaApprovedDetails[i].enroll;
        taDaData.serialNo = "";
        taDaData.amount = 0;

        newList.push(taDaData);
      }
    }

    let patchUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/TadaApprovedForAudit`;
    Axios
      .patch(patchUrl,newList)
      .then(function(response) {
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);

          dispatch(getMonthlyTadaAmountForAudit(startDate, toDate));
          dispatch(getDayWiseTadaAmountForAudit(userEnroll.enroll,startDate, toDate));

          dispatch({ type: Types.GET_TADA_APPROVED_FOR_AUDIT, payload: responseList });
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function(error) {
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {}
};

export const getTadaDateWiseApprovedForAudit = (tadaApprovedDetails,DateRange) =>async(dispatch)=> {
  let newList = [];
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
console.log('DateRange :>> ', DateRange);
  const dateRa = tadaApprovedDetails[0].tadaDate;
  const emEn = tadaApprovedDetails[0].enroll;

  console.log('emEn :>> ', emEn);
  try {
  
    for (let i = 0; i < tadaApprovedDetails.length; i++) {
      let taDaData = {
        tadaid: "",
        tadaDate:"",
        enroll:"",
        serialNo: "",
        amount: 0,
      };
 
      if (tadaApprovedDetails.length > 0) {
        taDaData.tadaid = tadaApprovedDetails[i].tadaId;
        taDaData.tadaDate = tadaApprovedDetails[i].tadaDate;
        taDaData.enroll = tadaApprovedDetails[i].enroll.toString();
        taDaData.serialNo = tadaApprovedDetails[i].slNo.toString();
        taDaData.amount = parseInt(tadaApprovedDetails[i].hrAmount);

        newList.push(taDaData);
      }
    }
    let patchUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/TadaDateWiseApprovedForAudit`;
    Axios
      .patch(patchUrl,newList)
      .then(function(response) {
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);
          dispatch(getMonthlyTadaAmountForAudit(DateRange.fromDate, DateRange.toDate));
          dispatch(getDayWiseTadaAmountForAudit(emEn,DateRange.fromDate,DateRange.toDate));
          dispatch({ type: Types.GET_TADA_APPROVED_FOR_AUDIT, payload: responseList });
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function(error) {
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {}
};




