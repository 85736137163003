export default [
    {
      'title': 'Today',
      'allDay': true,
      'start': new Date(2022, 2, 0),
      'end': new Date(2022, 2, 0),
      'hexColor':'b7ac4d'

    },
    {
      'title': 'Movement',
      'allDay': true,
      'start': new Date(2022, 2, 1),
      'end': new Date(2022, 2, 1),
      'hexColor':'f35ca7'
    },
  
    {
      'title': 'Late',
      'start': new Date(2022, 2, 2),
      'end': new Date(2022, 2, 2),
      'hexColor':'dbdb21'
    },
  
    {
      'title': 'Present',
      'start': new Date(2022, 2, 3),
      'end': new Date(2022, 2, 3),
      'hexColor':'047204'
    },
    {
      'title': 'Holiday',
      'start': new Date(2022, 3, 4),
      'end': new Date(2022, 3, 4),
      'hexColor':'aafb2e'
     
    },
    {
      'title': 'Leave',
      'start': new Date(2022, 3, 5),
      'end': new Date(2022, 3, 5),
      'hexColor':'141489'
    },
    {
      'title': 'Absent',
      'start': new Date(2022, 3, 12, 10),
      'end': new Date(2022, 3, 12, 12),
      'hexColor':'f10303'
    },
   
  ]
  