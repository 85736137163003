import React from "react";
import TadaApprovedList from "./TadaApprovedList";

const TadaReportATGlanceContainer = () => {
  return (
    <div className="container-fluid">
       <TadaApprovedList />
    </div>
  );
};
export default TadaReportATGlanceContainer;
