
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import SimpleModal from "../SimpleModal";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { getTaDAUnapprovedApprovedListForSupApproved, tadaDelete } from '../../redux/actions/tada/TadaEntryAction';


import DataTable from "react-data-table-component"

const TADASupervisorUnApprovedListDetails = () => {

  const history = useHistory();
  const [selectedData, setSelectedData] = React.useState([]);

  const dispatch = useDispatch();
  const tadaUnapprovedListDaily = useSelector((state) => state.tada.tadaUnapprovedListDaily);
  const tadaUnapprovedListforsupapproved = useSelector((state) => state.tada.tadaUnapprovedListforsupapproved);
  const isLoading = useSelector((state) => state.tada.tadaListLoading);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectTadaId, setSelectTadaId] = useState(null);

  const routeChange = () => {
    let path = `/tada/TADASupervisorUnApprovedList`;
    history.push(path);
    dispatch(getTaDAUnapprovedApprovedListForSupApproved());
  }


  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };

  const handleDeleteItem = (item) => {
    dispatch(tadaDelete(item, selectTadaId, startDate));
  };

  useEffect(() => {
    dispatch(getTaDAUnapprovedApprovedListForSupApproved());
    

}, [])

  // const columns = [
  //   {
  //     name: "DATE",
  //     selector: (row) => row.tadaDate,
  //     sortable: true,
  //   },
  //   {
  //     name: "ENROLL",
  //     selector: (row) => row.enroll,
  //     sortable: true,
  //   },
  //   {
  //     name: "EMPLOYEE NAME",
  //     selector: (row) => row.employeeName,
  //     sortable: true,
  //   },
  //   {
  //     name: "DESIGNATION",
  //     selector: (row) => row.designation,
  //     sortable: true,
  //   },
  //   {
  //     name: "JOB STATION ",
  //     selector: (row) => row.jobStation,
  //     sortable: true,
  //   },
  //   {
  //     name: "AMOUNT",
  //     selector: (row) => row.amount,
  //     sortable: true,
  //   },

  //   {
  //     name: "Action",
  //     cell: row => <a href title="Details">
  //       <i className="fa fa-th-list editIcon item-list-icon"></i>
  //     </a>
  //   },

  // ];
  const details = [
    {
      name: "TADA ID",
      selector: (row) => row.tadaId,
      sortable: true,
    },
    {
      name: "VEHICLE NAME",
      selector: (row) => row.vehicleName,
      sortable: true,
    },
    {
      name: "FROM ADDRESS",
      selector: (row) => row.fromAddress,
      sortable: true,
    },
    {
      name: "TO ADDRESS",
      selector: (row) => row.toAddress,
      sortable: true,
    },
    {
      name: "CNG STATION",
      selector: (row) => row.cngStationName,
      sortable: true,
    },
    {
      name: "FUEL TYPE",
      selector: (row) => row.fuelType,
      sortable: true,
    },
    {
      name: "PAYMENT",
      selector: (row) => row.payment,
      sortable: true,
    },
    {
      name: "TIME FROM",
      selector: (row) => row.timeFrom,
      sortable: true,
    },
    {
      name: "TIME TO",
      selector: (row) => row.timeTo,
      sortable: true,
    },
    {
      name: "MOVEMENT HOUR",
      selector: (row) => row.movementHour,
      sortable: true,
    },
    {
      name: "START MILAGE",
      selector: (row) => row.startMilage,
      sortable: true,
    },
    {
      name: "END MILAGE",
      selector: (row) => row.movementHour,
      sortable: true,
    },
    {
      name: "CONSUME MILAGE",
      selector: (row) => row.consumeMilage,
      sortable: true,
    },
    {
      name: "CONTACT PERSON",
      selector: (row) => row.contactPerson,
      sortable: true,
    },
    {
      name: "CONTACT NUMBER",
      selector: (row) => row.contactNumber,
      sortable: true,
    },
    {
      name: "REMARKS",
      selector: (row) => row.remarks,
      sortable: true,
    },
    {
      name: "AMOUNT",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Attachment",

      cell: (row) =>
          <img width={50} height={50} 
          //src="https://localhost:44373/User_Files/Articles/Images/User_Files/8cb5d58a-bea5-460b-8b93-59d5595219f4foodPand.PNG" 
          src={`${process.env.REACT_APP_API_PUBLIC_URL}User_Files/Articles/Images/User_Files/${row.image}`} 
          />

   },
    
  ];

  const handleChange1 = (state) => {
    setSelectedData(state.selectedRows);
    console.log('state.selectedRows',state.selectedRows);
  };

  // const showReport = () => {
  //    dispatch(getTaDAUnapprovedApprovedListForSupApproved(startDate, toDate));
  // }
  
  return (
    <>
      <div className="card card-custom card-top-border p-5 mb-3">
        <div className="row">
          <div className="col-md-9">
            <div className="">
              <h3 className="mb-0 pb-0">TA DA Supervisor UnApproved List Details</h3>
            </div>
          </div>

        </div>
        {/* <div className="form-group row">
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="form-control reffcss"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="form-control reffcss"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col mt-6 mb-5">
            <Button size="sm" onClick={() => showReport()}>Show Report</Button>
          </div>
        </div> */}
        
      </div>
      {/* <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
        <DataTable
          columns={columns}
          data={tadaUnapprovedListDaily}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='290px'
          selectableRowsHighlight
          highlightOnHover
        />
      </div> */}
      <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
      <a href onClick={routeChange } title="Details">
      <i class="fa fa-arrow-circle-left mt-3" aria-hidden="true"></i>
      </a>
        <DataTable
          columns={details}
          data={tadaUnapprovedListforsupapproved}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='850px'
          selectableRowsHighlight
          highlightOnHover
          onSelectedRowsChange={handleChange1}
        />
      </div>
      

    </>
  );
};

export default TADASupervisorUnApprovedListDetails;
