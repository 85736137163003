import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { RHFInput } from 'react-hook-form-input';
import Select from 'react-select';
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import {
    getUserInfomation,
    handleClickSearchItem,
    handleEmployeeInput,
    resetForm
} from '../../../redux/actions/Movement/MovementAction';

const MovementList = () => {
    const { handleSubmit, register, setValue, reset } = useForm();
    const [privateChecked, setPrivateChecked] = useState(true);
    const [publicChecked, setPublicChecked] = useState(false);
    const [empDisabled, setEmpDisabled] = useState(true);
    const [employeeDisabled, setEmployeeDisabled] = useState(true);

    const dispatch = useDispatch();

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
    ];
    const userInfo = useSelector((state) => state.movement.userInfo);
    const employeeSearch = useSelector((state) => state.movement.employeeSearch);

    useEffect(() => {
        dispatch(getUserInfomation());
    }, [])

    const handleChange = (name) => {
        if (name == 'private') {
            setPrivateChecked(true);
            setEmpDisabled(true);
            setPublicChecked(false);
            dispatch(getUserInfomation());
        }
        if (name == 'public') {
            setPublicChecked(true);
            setEmpDisabled(false);
            setPrivateChecked(false);
            dispatch(resetForm());
        }
    }

    const handleEmployee = (name, value) => {
        dispatch(handleEmployeeInput(name, value));
    }
    const handleClickSearch = (item) => {
        setEmpDisabled(true);
        dispatch(handleClickSearchItem(item));
    }
    const onSubmit = () => {
        alert();
    }



    return (
        <>
            <div className="card card-custom mb-2 pl-5 pr-5  card-top-border p-3">
                <div className="row">
                    <div className="col-md-9">
                        <div className="p-2">
                            <h3>Movement</h3>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="form-group row rowColor">
                    <div className="col-lg-1 col-md-1">
                        <div className="form-check radio__button  Promotion-radio-button ">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="private"
                                id="exampleRadios1"
                                value="option1"
                                onChange={() => handleChange('private')}
                                checked={privateChecked}
                            />
                            <label className="form-check-label" for="exampleRadios1">
                                Private
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1">
                        <div className="form-check radio__button  Promotion-radio-button ">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="public"
                                id="exampleRadios1"
                                value="option1"
                                onChange={() => handleChange('public')}
                                checked={publicChecked}

                            />
                            <label className="form-check-label" for="exampleRadios1">
                                Public
                            </label>
                        </div>
                    </div>
                </div>
                <form
                    className="form form-label-right"
                    onSubmit={handleSubmit(onSubmit)}
                    method="post"
                    encType="multipart/form-data"
                    autoComplete="off"
                >
                    <div className="form-group row">
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Employee</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Employee"
                                    name="itemName"
                                    onChange={(e) => handleEmployee('employee', e.target.value)}
                                    value={userInfo.employee_Name}
                                    disabled={publicChecked?false:empDisabled}
                                />
                            </Form.Group>
                            {
                                employeeSearch.length > 0 &&
                                <div className='searchEmployee'>
                                    {
                                        employeeSearch && employeeSearch.map((item) => (
                                            <p onClick={() => handleClickSearch(item)}>Hello</p>
                                        ))
                                    }
                                </div>
                            }

                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Job Status</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Job Status"
                                    name="itemName"
                                    value=""
                                    disabled={empDisabled}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Email</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Email"
                                    name="itemName"
                                    value=""
                                    disabled={empDisabled}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Unit</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Unit"
                                    name="itemName"
                                    value={userInfo.unit_Name}
                                    disabled={empDisabled}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Department</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Department"
                                    name="itemName"
                                    value={userInfo.departmentName}
                                    disabled={empDisabled}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Designation</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Designation"
                                    name="itemName"
                                    value=""
                                    disabled={empDisabled}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Movement Type</label>
                                <RHFInput
                                    as={<Select options={options} />}
                                    rules={{ required: false }}
                                    name="reactSelect"
                                    register={register}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">District</label>
                                <RHFInput
                                    as={<Select options={options} />}
                                    rules={{ required: false }}
                                    name="reactSelect"
                                    register={register}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label"> From Date</label>
                                <Form.Control
                                    type="date"
                                    placeholder="From Date"
                                    name="fromDate"
                                    className="fromStyle"

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label"> To Date</label>
                                <Form.Control
                                    type="date"
                                    placeholder="To Date"
                                    name="fromDate"
                                    className="fromStyle"

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Reason</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Reason"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group>
                                <label className="form-label">Address</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Address"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-12 mt-5">
                            <button type="submit" className="btn btn-primary  btn-sm float-right text-center custome-addnew-btn item-add-save mb-5"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>

            </div>
            <div className="card card-custom  p-5">
                <div className="react-bootstrap-table table-responsive">
                    <table className="table table table-head-custom table-vertical-center">
                        <thead>
                            <th>SL</th>
                            <th>Item ID</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default MovementList;
