import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component"


const Demo = () => {

    const [countries, setCountries] = useState([]);
    const [search, setSearch] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    const getCountries = async () => {
        try {
            const response = await Axios.get('https://restcountries.com/v2/all')
            setCountries(response.data);
            setFilteredCountries(response.data);

        } catch (error) {
            console.log(error);

        };
    };

    const columns = [
        {
            name: "Contry Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Contry Native Name",
            selector: (row) => row.nativeName,
        },
        {
            name: "Contry Capital",
            selector: (row) => row.capital,
        },
        {
            name: "Contry Flag",
            selector: (row) => <img width={50} height={50} src={row.flag} />,
        },
        {
            name: "Action",
            cell: row => <button className='btn btn-sm btn-primary' onClick={() => alert(row.id)} >Edit</button>
        },
    ];

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {

        const result = countries.filter((country) => {
            return country.name.toLowerCase().match(search.toLowerCase());
        });
        setFilteredCountries(result);

    }, [search])

    const ExpandedComponent = ({data}) => {
        console.log('data :>> ', data);
        return(
            <p>{data.name}</p>
        )
       
    };
    
    return (
        <div>
            <DataTable title="Country List"
                columns={columns}
                data={filteredCountries}
                pagination
                fixedHeader
                fixedHeaderScrollHeight='450px'
                selectableRows
                selectableRowsHighlight
                highlightOnHover
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                actions={
                    <>
                        <button type="button" class="btn btn-sm btn-secondary">Left</button>
                        <button type="button" class="btn btn-sm btn-secondary">Middle</button>
                        <button type="button" class="btn btn-sm btn-info">Export</button>
                    </>
                }

                subHeader
                subHeaderComponent={
                    <>
                    <input type="text" placeholder='Search Here' className=' w-25 form-control' value={search} onChange={(e) => setSearch(e.target.value)} />
                    </>
                }
                subHeaderAlign="left"

            />

        </div>
    )
}

export default Demo