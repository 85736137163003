import React from "react";
import ListDetails from "./ListDetails";



const ListDetailsContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <ListDetails />
      </div>
    </div>
  );
};
export default ListDetailsContainer;
