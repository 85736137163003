import React from "react";
import TADABikeCarEntryPublic from "./TADABikeCarEntryPublic";


const TADABikeCarEntryPublicContainer = () => {
  return (
    <div className="container-fluid">
        <TADABikeCarEntryPublic />
    </div>
  );
};
export default TADABikeCarEntryPublicContainer;
