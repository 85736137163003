import React from 'react';
import AttendanceCalender from '../../components/remoteAttendance/AttendanceCalender';


const RemoteAttendanceContainer = () => {

    return (
        <div className="container">
            <div className="">
                <AttendanceCalender />
            </div>
        </div>
    )

}
export default RemoteAttendanceContainer;
