import React from "react";
import DemoTable from "./DemoTable";

const DemoTableContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <DemoTable />
      </div>
    </div>
  );
};
export default DemoTableContainer;
