import Axios from "axios";
import * as Types from "../Types";

export const InputHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_VALUE_INPUT, payload: formData });
};

export const GetPoDetails =  (intPoId) => async (dispatch)=> {
  let responseList = {
    isLoading: true,
    data:{
      "poDetailsInfo": [{ }],   
      "ledger":[]     
    },
    status: false,
  };

  dispatch({ type: Types.GET_PO_DETAILS, payload: responseList });
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/AILHr/GetPoDetailsInfo?POId=${intPoId}`;
    console.log('geturl', geturl);
    await Axios.get(geturl)
      .then(function(res) {
        console.log("response", res);
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.isLoading=false;
      })

      .catch(function(error) {});
    dispatch({ type: Types.GET_PO_DETAILS, payload: responseList });
  } catch (error) {
    responseList.isLoading=false;
  }
  return responseList;
};
export const GetUnit =  (name, value) => async (dispatch)=> {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
    await Axios.get(geturl)
      .then(function(response) {
        console.log("response", response);
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
    dispatch({ type: Types.GET_UNIT, payload: responseList });
  } catch (error) {}
  return responseList;
};

export const GetPartyType = (name, value) => async (dispatch)=> {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
    await Axios.get(geturl)
      .then(function(response) {
        console.log("response", response);
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
    dispatch({ type: Types.GET_PARTY_TYPE, payload: responseList });
  } catch (error) {}
  return responseList;
};
export const GetPartyName = (name, value) => async (dispatch)=> {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
    await Axios.get(geturl)
      .then(function(response) {
        console.log("response", response);
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
    dispatch({ type: Types.GET_PARTY_NAME, payload: responseList });
  } catch (error) {}
  return responseList;
};
export const GetPartyLedger = (name, value) => async (dispatch)=> {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
    await Axios.get(geturl)
      .then(function(response) {
        console.log("response", response);
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
    dispatch({ type: Types.GET_PARTY_LEDGER, payload: responseList });
  } catch (error) {}
  return responseList;
};
