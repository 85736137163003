import React from 'react';
import AttendanceSummeryList from '../../../components/remoteAttendance/attendanceSummery/AttendanceSummeryList';



const AttendanceSummeryContainer = () => {

    return (
        <div className="container">
            <div className="">
                <AttendanceSummeryList />
            </div>
        </div>
    )

}
export default AttendanceSummeryContainer;
