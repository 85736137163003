import Axios from "axios";
import * as Types from "../../types/Types";
import { showToast } from "../../../../master/utils/ToastHelper";

export const getTADAAtaGlancee = (FromDate, To_Date) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };


  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetTADAAtaGlancee?FromDate=${FromDate}&To_Date=${To_Date}`;

  await Axios.get(url).then((res) => {
    console.log('tada res', res);
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_REPORT_AT_A_GLANCE, payload: responseList });
};
export const getTadaUserCostDetailsReport = (enroll) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetTadaUserCostDetailsReport?enroll=${enroll}`;

  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_TADA_COST_DETAILS, payload: responseList });
};
export const getDayBasisVehicleReport = (Vehicle, fromDate, to_Date, JobStation) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/DayBasisVehicleReport?Vehicle=${Vehicle}&fromDate=${fromDate}&to_Date=${to_Date}`;

  await Axios.get(url).then((res) => {

    console.log('daily detaiuls url :>> ', url);
    console.log('daily details res', res);
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_DAILY_BASIS_REPORT, payload: responseList });
};
export const getMonthlyBasisVehicleReport = (Vehicle, fromDate, to_Date, JobStation) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/MonthBasisVehicleReport?Vehicle=${Vehicle}&fromDate=${fromDate}&to_Date=${to_Date}`;

  await Axios.get(url).then((res) => {
    console.log('monthly -url :>> ', url);
    console.log('monthly res :>> ', res);
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_MONTHLY_BASIS_REPORT, payload: responseList });
};
export const getFuelStationBillReport = (Vehicle,fromDate, to_Date,JobStation,FStation) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/FuelStationReport?fromDate=${fromDate}&to_Date=${to_Date}`;

  await Axios.get(url).then((res) => {
    console.log('fuel station url :>> ', url);
    console.log('fuel station res', res);
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_FUEL_STATION_BILL_REPORT, payload: responseList });
};