
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import SimpleModal from "./../SimpleModal";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import DataTable from "react-data-table-component"
import { clearTadaList, getTadaApprovedForSuperviser, getTaDAUnapprovedApprovedList, getTaDAUnapprovedApprovedListDetails, getTaDAUnapprovedApprovedListForSupApproved, tadaDelete } from '../../redux/actions/tada/TadaEntryAction';
import { dateTimeConvertLongString } from "./DateHelper";
import TadaDetails from "./TadaDetails";
const TADASupervisorUnApprovedList = () => {

  const history = useHistory();
  const [selectedData, setSelectedData] = React.useState([]);

  const dispatch = useDispatch();
  const tadaUnapprovedListMonthly = useSelector((state) => state.tada.tadaUnapprovedListMonthly);
  console.log('sup img tadaUnapprovedListMonthly :>> ', tadaUnapprovedListMonthly);
  const tadaUnapprovedListDaily = useSelector((state) => state.tada.tadaUnapprovedListDaily);
  console.log('sup img tadaUnapprovedListDaily :>> ', tadaUnapprovedListDaily);
  const tadaApprovedDetails = useSelector((state) => state.tada.tadaApprovedDetails);
  const isLoading = useSelector((state) => state.tada.tadaListLoading);


  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectTadaId, setSelectTadaId] = useState(null);
  const [userEnroll, setuserEnroll] = useState(null);

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);

  const componentRef = useRef();
  let ref = React.createRef();
  const inputField = React.useRef(null);

  // const routeChange = () => {
  //   let path = `/TADASupervisorUnApprovedListDetails`;
  //   history.push(path);
  //   dispatch(getTaDAUnapprovedApprovedListForSupApproved());
  // }


  useEffect(() => {
    dispatch(clearTadaList());

  }, []);

  //startDate
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  //ToDate
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };

  // eye button - Daily Table
  const itemDetail = (item) => {
    setItemifo(item);
    setItemDetailShow(true);
  };
  // details button - Monthly Table
  const handleItemDetails = (item) => {
    setuserEnroll(item);
    dispatch(getTaDAUnapprovedApprovedListDetails(item.enroll, startDate, toDate));
  };
  // Approved button - Daily Table Top Side
  const handleIApproved = () => {
    
    dispatch(getTadaApprovedForSuperviser(selectedData, startDate, toDate, userEnroll));
    
  };

  const showReport = () => {
    dispatch(getTaDAUnapprovedApprovedList(startDate, toDate));
    //dispatch(getTaDAUnapprovedApprovedListDetails(startDate, toDate));
  }

  //monthlydata
  const monthlydata = [
    {
      name: "ENROLL",
      selector: (row) => row.enroll,
      sortable: true,
    },
    {
      name: "EMPLOYEE NAME",
      selector: (row) => row.employeeName,
      sortable: true,
    },
    {
      name: "DESIGNATION",
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: "JOB STATION ",
      selector: (row) => row.jobStation,
      sortable: true,
    },
    {
      name: "AMOUNT",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Action",
      cell: row => <a href onClick={() => handleItemDetails(row)} title="Details">
        <i className="fa fa-th-list editIcon item-list-icon"></i>
      </a>
    },

    // {
    //   name: "Action",
    //   cell: row => <a href onClick={routeChange } title="Details">
    //     <i className="far fa-eye editIcon item-list-icon"></i>
    //   </a>
    // },

  ];

  //dailydata
  const dailydata = [
    {
      name: "DATE",
      selector: (row) => dateTimeConvertLongString(row.tadaDate),
      sortable: true,
    },
    {
      name: "ENROLL",
      selector: (row) => row.enroll,
      sortable: true,
    },
    {
      name: "EMPLOYEE NAME",
      selector: (row) => row.employeeName,
      sortable: true,
    },
    {
      name: "DESIGNATION",
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: "JOB STATION ",
      selector: (row) => row.jobStation,
      sortable: true,
    },
    {
      name: "AMOUNT",
      selector: (row) => row.amount,
      sortable: true,
    },
    //   {
    //     name: "Attachment",

    //     cell: (row) =>
    //         <img width={50} height={50} 
    //         //src="https://localhost:44373/User_Files/Articles/Images/User_Files/8cb5d58a-bea5-460b-8b93-59d5595219f4foodPand.PNG" 
    //         src={`https://localhost:44373/User_Files/Articles/Images/User_Files/${row.image}`} 
    //         />

    //  },

    {
      name: "Action",
      cell: row => <a href onClick={() => itemDetail(row)} title="Details">
        <i className="far fa-eye editIcon item-list-icon"></i>
      </a>
    },

  ];

  const handleChange1 = (state) => {
    setSelectedData(state.selectedRows);

  };

  const dateSet = {
    fromDate: startDate,
    toDate: toDate
  }

  return (
    <>
      <div className="card card-custom card-top-border p-5 mb-3">
        <div className="row">
          <div className="col-md-9">
            <div className="">
              <h3 className="mb-0 pb-0">TA DA Supervisor Unapproved List</h3>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="form-control reffcss"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="form-control reffcss"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col mt-6 mb-5">
            <Button size="sm" onClick={() => showReport()}>Show Report</Button>
          </div>
        </div>
      </div>
      <div className="card text-center bg-secondary "><h5>Monthly TA DA Details</h5></div>
      <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">

        {/* Monthly TA DA Details */}
        <DataTable
          columns={monthlydata}
          data={tadaUnapprovedListMonthly}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='450px'
          selectableRowsHighlight
          highlightOnHover
        />
      </div>

      {/* Daily TA DA Details */}
      <div className="card text-center bg-secondary "><h5>Daily TA DA Details</h5></div>
      <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
        <DataTable
          columns={dailydata}
          data={tadaUnapprovedListDaily}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='300px'
          selectableRowsHighlight
          highlightOnHover
          selectableRows
          onSelectedRowsChange={handleChange1}
          actions={<button className="btn btn-primary btn-sm rdcustom" onClick={handleIApproved}>Approved</button>}
        />
      </div>

      <SimpleModal
        size="xl"
        show={itemDetailShow}
        handleClose={() => setItemDetailShow(false)}
        handleShow={() => setItemDetailShow(true)}
        modalTitle={"Details"}
      >
        <TadaDetails
          handleClose={() => setItemDetailShow(false)}
          itemID={dateSet}
          itemDetailnIfo={itemifo}
          
        />
      </SimpleModal>
    </>
  );
};

export default TADASupervisorUnApprovedList;
