import { GetDdl } from "../../../Global/GlobalDdl";
import * as Types from "../Types";
const initialState = {
    isLoading: false,
    ddlUnit: [],
    ddlPartyType: [],
    ddlPartyName: [],
    ddlPartyLedger: [],
    refLoading:false,
    defaultUnit:{
      value:null,
      label:""
    },
    itemDataInput: {
        referenceId: "",
        referenceNo: "",
        unitId: "",
        partyType: "",
        partyName:"",
        billNo:"",
        billdate:"",
        amount:"",
        remarks:"",
      },
      mrrInfo:[],
      ledger:null,
    poDetails:{
      unit: null,
      monAIT: 0,
      unitName: "",
      supplierName:"",
      supplierID: 0,
      monFreight: 0,
      monPacking: 0,
      monDiscount: 0,
      numQty: 0,
      monRate: 0,
      monVATG: 0,
      monPoAmount:0,
    }
    

  };
  const BillingReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.CHANGE_VALUE_INPUT:
            const itemDataInput = { ...state.itemDataInput };
            itemDataInput[action.payload.name] = action.payload.value;
            return {
              ...state,
              itemDataInput,
            };
        case Types.GET_PO_DETAILS:
            const poInfo = action.payload.data.poDetailsInfo[0]
            const poDetails = {...state.poDetails};
            poDetails.monAIT=poInfo.monAIT;
            poDetails.monDiscount=poInfo.monDiscount;
            poDetails.monFreight=poInfo.monFreight;
            poDetails.monPacking=poInfo.monPacking;
            poDetails.monPoAmount=poInfo.monPoAmount;
            poDetails.monRate=poInfo.monRate;
            poDetails.monVATG=poInfo.monVATG;
            poDetails.numQty=poInfo.numQty;
            poDetails.supplierID=poInfo.supplierID;
            poDetails.supplierName=poInfo.supplierName;
            poDetails.unit=poInfo.unit;
            poDetails.unitName=poInfo.unitName;
            poDetails.monLedgerBalance=poInfo.monLedgerBalance;

            const deUnit = {...state.defaultUnit};
            deUnit.value=poInfo.unit;
            deUnit.label=poInfo.unitName;
            
            return {
              ...state,
              poDetails,
              mrrInfo:action.payload.data.mrr,
              refLoading:action.payload.isLoading,
              ledger:action.payload.data.ledger[0],
              defaultUnit:deUnit,
              //amount:action.payload.data.amount


            };
        case Types.GET_UNIT:
            return {
              ...state,
              ddlUnit: GetDdl(action.payload.data, 'zoneID', 'zoneName'),
            };
        case Types.GET_PARTY_TYPE:
            return {
              ...state,
              ddlPartyType: GetDdl(action.payload.data, 'zoneID', 'zoneName'),
            };
        case Types.GET_PARTY_NAME:
            return {
              ...state,
              ddlPartyName: GetDdl(action.payload.data, 'zoneID', 'zoneName'),
            };
        case Types.GET_PARTY_LEDGER:
            return {
              ...state,
              ddlPartyLedger: GetDdl(action.payload.data, 'zoneID', 'zoneName'),
            };
      default:
        break;
    }
    return newState;
  };
  export default BillingReducer;