import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMovementInputValue,
  GetDa,
  GetEndMilage,
  GetFillingStation,
  GetPaymentType,
  GetTa,
  handleImageDelete,
  handleListDelete,
  handleRowCreateforbikecar,
  inputHandleConsumeMilage,
  tadaSubmitForBikeCar,
  tadaSubmitStausRemove,
  GetBillType,
  GetFuelType,
} from "../../redux/actions/tada/TadaEntryAction";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import SimpleModal from "./../SimpleModal";
import { showToast } from "../../../master/utils/ToastHelper";
import {
  getEmployeData,
  getUserByToken,
} from "../../../../app/modules/Auth/_redux/authCrud";
import {
  clearFieldForTaDaForStandVehicle,
  GetDriverName,
  GetStandVehicle,
  handleRowCreateforstandvehicle,
  hanleChangeInputforStandVehicleEntry,
  imageUpload,
  tadaSubmitForStandVehicle,
} from "../../redux/actions/tada/StandVehiclesAction";

const StandVehicleDetails = () => {
  const { register, handleSubmit, errors, setFocus, setValue } = useForm();
  const history = useHistory();

  const itemDataInputForStandVehicleEntry = useSelector(
    (state) => state.standvehicle.itemDataInputForStandVehicleEntry
  );
  console.log(
    "stand itemDataInputForStandVehicleEntry",
    itemDataInputForStandVehicleEntry
  );
  const ddFuleLogVehicle = useSelector(
    (state) => state.standvehicle.ddFuleLogVehicle
  );
  const fillingStation = useSelector((state) => state.tada.ddFillingStation);
  const ddDriverName = useSelector((state) => state.standvehicle.ddDriverName);

  const tadaEndMilage = useSelector((state) => state.tada.tadaEndMilage);
  const tadabikecarDetails = useSelector(
    (state) => state.tada.tadabikecarDetails
  );
  const tadaRow = useSelector((state) => state.standvehicle.itemRow);
  console.log("stand tadaRow :>> ", tadaRow);
  const daType = useSelector((state) => state.tada.ddda);
  const taType = useSelector((state) => state.tada.ddta);
  const paymentType = useSelector((state) => state.tada.ddPaymentType);
  const input = useSelector((state) => state.billing.itemDataInput);
  const defaultUnit = useSelector((state) => state.billing.defaultUnit);
  const tadaStatus = useSelector((state) => state.tada.tadaSubmitStatus);

  const [disabled, setDisabled] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [enroll, setEnroll] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [imageShow, setImageShow] = useState(null);
  const [image, setImage] = useState(null);
  const [selectCosttype, setSelectCostType] = useState(1);
  const [values, setValues] = useState(initialFieldValues);
  const billType = useSelector((state) => state.tada.ddbillType);
  const [selectExpenseType, setSelectExpenseType] = useState(null);
  const fuelType = useSelector((state) => state.tada.ddfuelType);
  const [selectBilltype, setSelectBillType] = useState(null);
  const [selectVehicle, setSelectVehicle] = useState(null);


  useEffect(() => {
    if (tadaStatus) {
      dispatch(tadaSubmitStausRemove());
      setTimeout(() => {
        history.go(0);
      }, 3000);
    }
  }, [tadaStatus]);

  useEffect(() => {
    dispatch(GetStandVehicle());
    dispatch(GetDriverName());
    dispatch(GetFillingStation());
    dispatch(GetPaymentType());
    dispatch(GetBillType());
    dispatch(GetEndMilage());
    dispatch(GetFuelType());
  }, []);

  const dispatch = useDispatch();
  useEffect(() => { }, []);

  const onSubmitLeave = () => {
    alert();
  };

  const inputChangeHandle = (name, value) => {
    dispatch(
      hanleChangeInputforStandVehicleEntry(
        name,
        value != undefined ? value : ""
      )
    );
  };
  const handleChange = ({ currentTarget: input }) => {
    dispatch(hanleChangeInputforStandVehicleEntry(input.name, input.value));
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const handleModaleItem = (data) => {
    setItemDetailShow(true);
    setImageShow(data);
  };
  const changeMovementInput = (name, value) => {
    dispatch(changeMovementInputValue(name, value));
  };

  const tadaValidation = () => {
    if (startDate == null) {
      alert("Please select Date");
      return false;
    }
    else if (itemDataInputForStandVehicleEntry?.employeeName == "") {
      alert("Please Select Employee ");
      return false;
    }
    if (selectVehicle == null) {
      alert("Please select Vehicle Name");
      return false;
    }
    else if (itemDataInputForStandVehicleEntry.fromAddress == 0) {
      alert("Please Input fromAddress ");
      return false;
    } else if (itemDataInputForStandVehicleEntry.toAddress == 0) {
      alert("Please Input toAddress ");
      return false;
    } else if (itemDataInputForStandVehicleEntry.amount == 0) {
      alert("Please Input amount ");
      return false;
    } else {
      return true;
    }
  };

  const onSubmit = async (e) => {
    //const validation = tadaValidation();
    const userInfo = await getEmployeData();
    // if (validation) {
    //setDisabled(true);
    if (tadaRow.length > 0) {
      dispatch(
        tadaSubmitForStandVehicle(
          itemDataInputForStandVehicleEntry?.employeeName,
          startDate,
          tadaRow
        )
      );
    }
  };

  const handleAddData = () => {
    const validation = tadaValidation();
    if (validation) {
      dispatch(
        handleRowCreateforstandvehicle(itemDataInputForStandVehicleEntry)
      );
      dispatch(hanleChangeInputforStandVehicleEntry("attachment", null));
      dispatch(hanleChangeInputforStandVehicleEntry("fileName", null));
      dispatch(clearFieldForTaDaForStandVehicle());
      setValue("paymentType", "");
      setValue("vehicleType", "");
      setValue("billType", "");
      setValue("cngStation", "");
      setValue("fuelType", "");
      setValue('fuelName', '')
      setValue('cngStationName', '')
      setValue('costName', '')
      setValue('paymentName', '')
      setValue('endMilage', '')
    }
  };

  // const fuelType = [
  //   { value: "1", label: "Diesel" },
  //   { value: "2", label: "Kerosene" },
  //   { value: "3", label: "Octane" },
  //   { value: "4", label: "Petrol" },
  // ];
  // const CostType = [
  //     { value: "1", label: "TA" },
  //     { value: "2", label: "DA" },
  // ];

  const handleDeleteItem = (row) => {
    dispatch(handleImageDelete(row.filename));
    dispatch(handleListDelete(row.rowID));
  };

  //const defaulImageSrc = '/media/users/blank1.png'
  const initialFieldValues = {
    imageName: "",
    //imageSrc: defaulImageSrc,
    imageFile: null,
  };

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log("e.target.files[0])", e.target.files[0]);
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      console.log("reader :>> ", reader);
      reader.onload = (x) => {
        setValues({
          ...values,
          imageFile,
          imageSrc: x.target.result,
        });
        dispatch(
          hanleChangeInputforStandVehicleEntry("attachment", x.target.result)
        );
      };
      reader.readAsDataURL(imageFile);
    } else {
      setValues({
        ...values,
        imageFile: null,
        //imageSrc: defaulImageSrc
      });
    }
    const formData = new FormData();
    formData.append("fileData", e.target.files[0]);
    dispatch(imageUpload(formData));
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.startDate,
      sortable: true,
    },
    {
      name: "Employee Name",
      selector: (row) => row.employeeFullName,
      sortable: true,
    },
    {
      name: "vehicle Name",
      selector: (row) => row.vehicleName,
      sortable: true,
    },
    {
      name: "From Address",
      selector: (row) => row.fromAddress,
      sortable: true,
    },
    {
      name: "Movement Address",
      selector: (row) => row.toAddress,
      sortable: true,
    },
    {
      name: "Start Milage",
      selector: (row) => row.startMilage,
      sortable: true,
    },
    {
      name: "End Milage",
      selector: (row) => row.endMilage,
      sortable: true,
    },
    {
      name: "Consume Milage",
      selector: (row) => row.consumeMilage,
      sortable: true,
    },
    {
      name: "Bill Type",
      selector: (row) => row.billName,
      sortable: true,
    },
    {
      name: "Cost Type",
      selector: (row) => row.costName,
      sortable: true,
    },
    {
      name: "Payment Type",
      selector: (row) => row.paymentName,
      sortable: true,
    },
    {
      name: "CNG Station",
      selector: (row) => row.cngStationName,
      sortable: true,
    },
    {
      name: "Fuel Type",
      selector: (row) => row.fuelName,
      sortable: true,
    },
    {
      name: "Personal Amount in KM",
      selector: (row) => row.persoanlMilage,
      sortable: true,
    },
    // {
    //     name: "FileName",
    //     selector: (row) => row.fileName,
    //     sortable: true,
    // },
    {
      name: "Attachment",

      cell: (row) => (
        <img
          width={50}
          height={50}
          src={row.attachment}
          onClick={() => handleModaleItem(row.attachment)}
        />
      ),
    },

    {
      name: "Action",
      // cell: row => <button className='btn btn-sm btn-primary' onClick={() => alert(row.id)} >Edit</button>
      cell: (row) => (
        <a href onClick={() => handleDeleteItem(row)} title="Delete">
          <i className="fas fa-trash-alt editIcon item-list-icon ml-4 text-danger"></i>{" "}
        </a>
      ),
    },
  ];

  return (
    <>
      <div className="card card-custom mb-2 pl-5 pr-5  card-top-border p-1 ">
        <div className="row">
          <div className="col-lg-12 mt-4">
            <h3>Stand Vehicle Entry</h3>
          </div>
        </div>
        <hr></hr>
        <form
          className="form form-label-right  mb-0 "
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          encType="multipart/form-data"
          autoComplete="off"
        >
          <div className="row">
            <div className="col-lg-12 ">
              <div className="form-group row ">
                <div className="col-lg-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">
                      Date<span className="star">*</span>
                    </label>
                    <Form.Control
                      type="date"
                      placeholder="Enter Deadweight"
                      name="startDate"
                      value={startDate}
                      className="form-control reffcss"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Employee Name<span className="star">*</span></label>
                    <RHFInput
                      className="formSelect pt-0  "
                      as={<Select options={ddDriverName} />}
                      rules={{ required: false }}
                      name="employeeName"
                      register={register}
                      placeholder="Select Employee Name"
                      isClearable
                      onChange={(option) => {
                        inputChangeHandle("employeeName", option?.value);
                        inputChangeHandle("employeeFullName", option?.label);
                      }}
                      setValue={setValue}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Vehicle Name<span className="star">*</span></label>
                    <RHFInput
                      className="formSelect pt-0 "
                      as={<Select options={ddFuleLogVehicle} />}
                      rules={{ required: false }}
                      name="vehicleID"
                      register={register}
                      placeholder="Select Vehicle Name"
                      isClearable
                      onChange={(option) => {
                        inputChangeHandle("vehicleID", option?.value);
                        inputChangeHandle("vehicleName", option?.label);
                        setSelectVehicle(option.value)
                      }}
                      setValue={setValue}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">
                      From Address<span className="star">*</span>
                    </label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Type From Address"
                      name="fromAddress"
                      //value={itemDataInputForStandVehicleEntry?.fromAddress}
                      onChange={(e) => {
                        inputChangeHandle("fromAddress", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">
                      To Address<span className="star">*</span>
                    </label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="To Address"
                      name="toAddress"
                      //value={itemDataInputForStandVehicleEntry?.toAddress}
                      onChange={(e) => {
                        inputChangeHandle("toAddress", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">
                      Start Milage
                    </label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="number"
                      placeholder="Start Milage"
                      name="startMilage"
                      value={itemDataInputForStandVehicleEntry?.startMilage}
                      onChange={(e) => {
                        inputChangeHandle("startMilage", e.target.value);
                        dispatch(
                          inputHandleConsumeMilage(
                            "startMilage",
                            e.target.value
                          )
                        );
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">
                      End Milage
                    </label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="number"
                      placeholder="End Milage"
                      name="endMilage"
                      value={itemDataInputForStandVehicleEntry?.endMilage}
                      // disabled
                      onChange={(e) => {
                        inputChangeHandle("endMilage", e.target.value);
                        dispatch(
                          inputHandleConsumeMilage("endMilage", e.target.value)
                        );
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">
                      Consume Milage
                    </label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="number"
                      disabled
                      placeholder="Consume Milage"
                      name="consumeMilage"
                      value={itemDataInputForStandVehicleEntry?.consumeMilage}
                      onChange={(e) => {
                        inputChangeHandle("consumeMilage", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row bg-secondary mt-5 py-5">
                <div className="col-lg-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Bill Type<span className="star">*</span></label>
                    <RHFInput
                      className="formSelect pt-0 "
                      as={<Select options={billType}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}

                      />}
                      rules={{ required: false }}
                      name="billType"
                      register={register}
                      placeholder="Select billType"
                      onChange={(option) => {
                        setSelectCostType(option.value);
                        dispatch(GetTa(option.value));
                        inputChangeHandle("billName", option?.label);
                        setValue("vehicleType", "");
                        setValue("paymentType", "");
                        setValue("cngStation", "");
                        setValue("fuelType", "");
                        inputChangeHandle('amount', 0);
                      }}
                      setValue={setValue}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Cost type<span className="star">*</span></label>
                    <RHFInput
                      className="formSelect pt-0  "
                      as={<Select options={taType} />}
                      rules={{ required: false }}
                      name="vehicleType"
                      styles={{ menuPortal: base => ({ ...base, zIndex: 1000000000000000 }) }}
                      register={register}
                      placeholder="Select Vehicle type"
                      isClearable
                      onChange={(option) => {
                        
                        inputChangeHandle("vehicleType", option?.value);
                        setSelectExpenseType(option);
                        inputChangeHandle("costName", option?.label);
                      }}
                      setValue={setValue}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Payment Type<span className="star">*</span></label>
                    <RHFInput
                      className="formSelect pt-0 "
                      as={<Select options={paymentType} />}
                      rules={{ required: false }}
                      name="paymentType"
                      register={register}
                      placeholder="Select Payment type"
                      //isClearable
                      value={itemDataInputForStandVehicleEntry?.paymentType}
                      onChange={(option) => {
                        inputChangeHandle("paymentType", option?.value);
                        inputChangeHandle("paymentName", option?.label);
                        setValue("cngStation", "");
                        setValue("fuelType", "");
                      }}
                      setValue={setValue}
                    />
                  </Form.Group>
                </div>

                {itemDataInputForStandVehicleEntry?.paymentType == "1" &&
                  selectExpenseType.label == "Filling Station" && (
                    <>
                      <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">CNG Station<span className="star">*</span></label>
                          <RHFInput
                            className="formSelect pt-0 "
                            as={<Select options={fillingStation} />}
                            rules={{ required: false }}
                            name="cngStation"
                            register={register}
                            placeholder="Select CNG Station"
                            isClearable
                            onChange={(option) => {
                              inputChangeHandle("cngStation", option?.value);
                              inputChangeHandle("cngStationName", option?.label);
                            }}
                            setValue={setValue}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                          <label className="form-label">Fuel Type<span className="star">*</span></label>
                          <RHFInput
                            className="formSelect pt-0 "
                            as={<Select options={fuelType} />}
                            rules={{ required: false }}
                            name="fuelType"
                            register={register}
                            placeholder="Select Fuel type"
                            isClearable
                            onChange={(option) => {
                              inputChangeHandle("fuelType", option?.value);
                              inputChangeHandle("fuelName", option?.label);
                            }}
                            setValue={setValue}
                          />
                        </Form.Group>
                      </div>

                    </>

                  )}

                {itemDataInputForStandVehicleEntry?.paymentType == "2" &&
                  selectExpenseType.label == "Filling Station" && (
                    <div className="col-lg-3">
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label">Fuel Type<span className="star">*</span></label>
                        <RHFInput
                          className="formSelect pt-0 "
                          as={<Select options={fuelType} />}
                          rules={{ required: false }}
                          name="fuelType"
                          register={register}
                          placeholder="Select Fuel type"
                          isClearable
                          onChange={(option) => {
                            inputChangeHandle("fuelType", option?.value);
                            inputChangeHandle("fuelName", option?.label);
                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>
                  )}

                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Personal Use (KM)<span className="star">*</span></label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Personal Use (KM)"
                      name="persoanlMilage"
                      value={itemDataInputForStandVehicleEntry?.persoanlMilage}
                      onChange={(e) => {
                        inputChangeHandle("persoanlMilage", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Personal Use (TK)</label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="text"
                      placeholder="Personal Use TK"
                      name="personaluseamount"
                      value={
                        itemDataInputForStandVehicleEntry?.personaluseamount
                      }
                      onChange={(e) => {
                        inputChangeHandle("personaluseamount", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-3 ">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">
                      Amount<span className="star">*</span>
                    </label>
                    <Form.Control
                      autocomplete="off"
                      className="form-control reffcss"
                      type="number"
                      placeholder="Amount"
                      name="amount"
                      value={itemDataInputForStandVehicleEntry?.amount}
                      onChange={(e) => {
                        inputChangeHandle("amount", e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label">Attachment</label>
                    {
                      <Form.Control
                        type="file"
                        placeholder="Attachment"
                        className="form-control forimageinput"
                        name="attachment"
                        accept="image/*"
                        onChange={(e) => showPreview(e)}
                      />
                    }
                  </Form.Group>
                </div>
              </div>
              <>
                <div className="col-lg-12 mt-5 ml-4">
                  <button
                    type="submit"
                    disabled={disabled}
                    className="btn btn-primary btn-sm float-right text-center custome-addnew-btn item-attach mb-1 mr-4 "
                  >
                    Submit
                  </button>
                </div>
              </>
            </div>
          </div>
        </form>
        <div>
          <button
            onClick={() => handleAddData()}
            className="btn btn-primary  btn-sm float-right text-center custome-addnew-btn item-add mb-5 mr-4"
          >
            Add
          </button>
        </div>
      </div>

      <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
        <DataTable
          columns={columns}
          data={tadaRow}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="450px"
          selectableRowsHighlight
          highlightOnHover
        />
        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Image Preview"}
        >
          <img src={imageShow} width="100%" height="100%" />
        </SimpleModal>
      </div>
    </>
  );
};
export default StandVehicleDetails;
