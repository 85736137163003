import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { RHFInput } from 'react-hook-form-input';
import Select from 'react-select';
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { getAttendanceStatus } from '../../../redux/actions/RemoteAttendanceAction';
import { useSelector, useDispatch } from "react-redux";

const AttendanceStatus = () => {
    const { handleSubmit, register, setValue, reset } = useForm();
    const employeeList = useSelector((state) => state.remoteAttendance.remoteEmployee);
    const attendanceStatus = useSelector((state) => state.remoteAttendance.attendanceStatus);

    const dispatch = useDispatch();
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
    ];
    const [fakeData, setFakeData] = useState([
        {
            id: 1,
            name: "farid uddin",
            designation: 'Officer'
        },
        {
            id: 2,
            name: "farid uddin",
            designation: 'Officer'
        },
        {
            id: 3,
            name: "farid uddin",
            designation: 'Officer'
        },
        {
            id: 4,
            name: "farid uddin",
            designation: 'Officer'
        },
    ]);


    const inputChangeHandle = (name, value) => {
        console.log('name', name);
        console.log('value', value);
    }

    return (
        <>
            <div className="card card-custom mb-2 pl-5 pr-5  card-top-border p-3">
                <div className="row">
                    <div className="col-md-9">
                        <div className="p-2">
                            <h3>Attendance Status</h3>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="form-group row">
                    <div className="col-lg-12">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Section</label>
                            <RHFInput
                                as={<Select options={options} />}
                                rules={{ required: true }}
                                name="reactSelect"
                                register={register}
                                setValue={setValue}
                                onChange={(option) => {
                                    dispatch(getAttendanceStatus(fakeData));
                                }}
                            />
                        </Form.Group>
                    </div>

                </div>
            </div>
            <div className="card card-custom  p-5">
                <div className="react-bootstrap-table table-responsive">
                    {attendanceStatus && attendanceStatus.length === 0 && (
                        <div class="alert alert-danger" role="alert">
                            Sorry! No Data Found.
                        </div>
                    )}
                    {attendanceStatus && attendanceStatus.length > 0 && (
                        <table className="table table table-head-custom table-vertical-center">
                            <thead>

                                <th>Enroll</th>
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Job Type</th>
                                <th>Address</th>
                            </thead>
                            <tbody>
                                {attendanceStatus.map((item, index) => (
                                    <tr>
                                        <td>R658</td>
                                        <td>Md. Khaledur Rahman</td>
                                        <td>Permanent</td>
                                        <td>Accounts And Finance</td>
                                        <td> Finance	Assistant Manager</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    )
}
export default AttendanceStatus;
