
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import SimpleModal from "../SimpleModal";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { getTadaApprovedForSuperviser,tadaDelete } from '../../redux/actions/tada/TadaEntryAction';


import DataTable from "react-data-table-component"

const TadaApprovedForSuperviser = () => {

  const history = useHistory();

  const dispatch = useDispatch();
  const tadaapprovedListforsupervisor = useSelector((state) => state.tada.tadaapprovedListforsupervisor);
  const isLoading = useSelector((state) => state.tada.tadaListLoading);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectTadaId, setSelectTadaId] = useState(null);


  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };

  const handleDeleteItem = (item) => {
    dispatch(tadaDelete(item, selectTadaId, startDate));
  };

  useEffect(() => {
    dispatch(getTadaApprovedForSuperviser());
    

}, [])

  const columns = [
    {
      name: "DATE",
      selector: (row) => row.tadaDate,
      sortable: true,
    },
    {
      name: "ENROLL",
      selector: (row) => row.enroll,
      sortable: true,
    },
    {
      name: "EMPLOYEE NAME",
      selector: (row) => row.employeeName,
      sortable: true,
    },
    {
      name: "DESIGNATION",
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: "JOB STATION ",
      selector: (row) => row.jobStation,
      sortable: true,
    },
    {
      name: "AMOUNT",
      selector: (row) => row.amount,
      sortable: true,
    },

    {
      name: "Action",
      cell: row => <a href title="Details">
        <i className="fa fa-th-list editIcon item-list-icon"></i>
      </a>
    },

  ];
  
  return (
    <>
      <div className="card card-custom card-top-border p-5 mb-3">
        <div className="row">
          <div className="col-md-9">
            <div className="">
              <h3 className="mb-0 pb-0">TA DA Supervisor UnApproved List Details</h3>
            </div>
          </div>

        </div>
        <hr></hr>
        {/* <div className="form-group row">
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="form-control reffcss"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="form-control reffcss"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col mt-6 mb-5">
            <Button size="sm" onClick={() => showReport()}>Show Report</Button>
          </div>
        </div> */}
        
      </div>
      <div className="card card-custom mb-2 pl-5 pr-5 p-1 ">
        <DataTable
          columns={columns}
          data={tadaapprovedListforsupervisor}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='290px'
          selectableRowsHighlight
          highlightOnHover
        />
      </div>

    </>
  );
};

export default TadaApprovedForSuperviser;
