import Axios from "axios";
import * as Types from "../../types/Types";
import { showToast } from "../../../../master/utils/ToastHelper";


export const hanleChangeInputforStandVehicleEntry = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_TADA_STAND_VEHICLE_ENTRY, payload: data });
};
export const handleRowCreateforstandvehicle = (itemDataInputForStandVehicleEntry) => (
  dispatch
) => {
  dispatch({
    type: Types.TADA_ROW_CREATE_FOR_STAND_VEHICLE,
    payload: itemDataInputForStandVehicleEntry,
  });
};

export const GetStandVehicle = (name, value) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/GetFuleLogVehicle`;
    await Axios.get(geturl)

      .then(function (response) {
        responseList.data = response.data.data;
      })

      .catch(function (error) { });
    dispatch({ type: Types.GET_FUEL_LOG_VEHICLE, payload: responseList });
  } catch (error) { }
  return responseList;
};
export const GetDriverName = (name, value) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/Driver`;
    await Axios.get(geturl)

      .then(function (response) {
        responseList.data = response.data.data;
      })

      .catch(function (error) { });
    dispatch({ type: Types.GET_DIVER_NAME, payload: responseList });
  } catch (error) { }
  return responseList;
};
export const tadaSubmitForStandVehicle = (
  enroll,
  Tada_Date,
  tadaStandVehicleDetails
) => async (dispatch) => {
  let newList = [];
  const formData = new FormData();
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/TaDAInfo/InsertTadaForStandVehicle?enroll=${enroll}&Tada_Date=${Tada_Date}`;
     console.log('stand postUrl :>> ', postUrl);
    for (let i = 0; i < tadaStandVehicleDetails.length; i++) {
      let taDaData = {
        vehicleType: "",
        cngStation: "",
        fuelType: "",
        timeFrom: "",
        timeTo: "",
        movementHour: "0",
        fromAddress: "",
        toAddress: "",
        startMilage: "0",
        endMilage: "0",
        consumeMilage: "0",
        daType: "",
        paymentType: "",
        remarks: "",
        amount: 0,
        contactPerson: "",
        contactNumber: "",
        fileName: "",
        vehicleID: "",
        persoanlMilage:0,
      };

      if (tadaStandVehicleDetails.length > 0) {
        taDaData.vehicleType = tadaStandVehicleDetails[i].vehicleType.toString();
        taDaData.cngStation = tadaStandVehicleDetails[i].cngStation !=null?tadaStandVehicleDetails[i].cngStation.toString():null;
        taDaData.fuelType = tadaStandVehicleDetails[i].fuelType !=null?tadaStandVehicleDetails[i].fuelType.toString():null;
        taDaData.timeFrom = tadaStandVehicleDetails[i].timeFrom;
        taDaData.timeTo = tadaStandVehicleDetails[i].timeTo;
        taDaData.movementHour = tadaStandVehicleDetails[i].movementHour.toString();
        taDaData.fromAddress = tadaStandVehicleDetails[i].fromAddress;
        taDaData.toAddress = tadaStandVehicleDetails[i].toAddress;
        taDaData.startMilage = tadaStandVehicleDetails[i].startMilage.toString();
        taDaData.endMilage = tadaStandVehicleDetails[i].endMilage.toString();
        taDaData.consumeMilage = tadaStandVehicleDetails[i].consumeMilage.toString();
        taDaData.daType = tadaStandVehicleDetails[i].daType.toString();
        taDaData.paymentType = tadaStandVehicleDetails[i].paymentType.toString();
        taDaData.remarks = tadaStandVehicleDetails[i].remarks;
        taDaData.amount = tadaStandVehicleDetails[i].amount;
        taDaData.contactPerson = tadaStandVehicleDetails[i].contactPerson;
        taDaData.contactNumber = tadaStandVehicleDetails[i].contactNumber;
        taDaData.fileName = tadaStandVehicleDetails[i].fileName;
        taDaData.vehicleID = tadaStandVehicleDetails[i].vehicleID.toString();
        taDaData.persoanlMilage = tadaStandVehicleDetails[i].persoanlMilage !=""?tadaStandVehicleDetails[i].persoanlMilage:0;

        newList.push(taDaData);
      }
     
    }
    console.log('stand newList', newList);
    //return false;
    console.log('stand postUrl', postUrl);
    await Axios.post(postUrl, newList)
   
      .then(function (response) {
        console.log('stand response :>> ', response);
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.TADA_STAND_VEHICLE_SUBMIT, payload: responseList });
        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.TADA_STAND_VEHICLE_SUBMIT, payload: responseList });
        }
      })
      .catch(function (error) {
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.TADA_STAND_VEHICLE_SUBMIT, payload: responseList });
      });
  } catch (error) {
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.TADA_STAND_VEHICLE_SUBMIT, payload: responseList });
  }
};
export const imageUpload = (imageData) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/ImageUpload/Post`;
    await Axios.post(geturl, imageData)

      .then(function(response) {
        dispatch(hanleChangeInputforStandVehicleEntry("fileName", response.data));
        responseList.data = response.data.data;
      })

      .catch(function(error) {});
  } catch (error) {}
  return responseList;
};
export const clearFieldForTaDaForStandVehicle = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_FIELD_FOR_STAND_VEHICLE, payload: data });
};